import Button from "components/common/ui/Button/Button";
import TextButton from "components/common/ui/Button/TextButton";
import Typography from "components/common/ui/Typography/Typography";
import useGeolocation from "hooks/useGeolocation";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocationWrapper, StyledSearchLocation } from "./Location.Styled";

export type Cordinates = {
	lat: string | number;
	lng: string | number;
};

interface Props {
	onSearch: (cords: Cordinates | undefined) => void;
}

const Location: React.FC<Props> = ({ onSearch }) => {
	const { t } = useTranslation();
	const geolocation = useGeolocation();
	// searched location
	const [place, setPlace] = useState<google.maps.places.PlaceResult>();

	const [cords, setCords] = useState<Cordinates | undefined>();

	const [address, setAddress] = useState<string | undefined>();

	useEffect(() => {
		let newCords;
		let address;

		if (place && place.geometry && place.geometry.location) {
			newCords = {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			};
		} else if (geolocation.position) {
			newCords = {
				lat: geolocation.position.coords.latitude,
				lng: geolocation.position.coords.longitude,
			};

			address = geolocation.address?.formatted_address;
		}

		setCords(newCords);
		setAddress(address);
	}, [geolocation.position, place]);

	useEffect(() => {
		if (!place) {
			onSearch(cords);
		}
	}, [cords]);

	const handleSearchClick = () => {
		if (place && cords) {
			setAddress(place.formatted_address);
			onSearch(cords);
		}
	};

	const handleChangeClick = () => {
		setPlace(undefined);
		setAddress(undefined);
		onSearch(undefined);
	};

	return (
		<LocationWrapper>
			{!address && (
				<Fragment>
					<StyledSearchLocation
						onChange={(place: google.maps.places.PlaceResult | undefined) => {
							setPlace(place);
						}}
						googleApi={{
							map: document.createElement("div"),
							api: (window as any).google?.maps,
						}}
					/>
					<Button variant="primary_01" onClick={handleSearchClick}>
						{t('search')}
					</Button>
				</Fragment>
			)}
			{address && (
				<div className="location">
					<Typography variant="heading" size="small" className="address">
						{t("findInStore.myLocation")} - {address}
					</Typography>

					<TextButton
						className="btn_location-change"
						onClick={handleChangeClick}
					>
						{t("text.change")}
					</TextButton>
				</div>
			)}
		</LocationWrapper>
	);
};

export default Location;
