import { MenuItemProps } from "components/common/ui/Menu/MenuItem/MenuItem";
import { HeadingMediumStyles } from "components/common/ui/Typography/Typography.Styled";
import styled from "styled-components";
import { SideMenuItemButton } from "../SideMenu.Styled";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";

const Wrapper = styled.div`
	${HeadingMediumStyles}
	height: 72px;
	width: 100%;
	background-color: #f8f9fa;
	padding: 20px;
	margin-top: 24px;
`;

const SideMenuHeader: React.FC<MenuItemProps> = ({
	onClick = () => {},
	children,
}) => {
	return (
		<Wrapper onClick={() => onClick()}>
			<SideMenuItemButton header>
				<IconChevron direction="left" className="back"/> {children}
			</SideMenuItemButton>
		</Wrapper>
	);
};

export default SideMenuHeader;
