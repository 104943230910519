import { ReactElement, SVGProps } from "react";
import styled from "styled-components";
import { CustomiseComponent } from "ts/interfaces";

interface IconProps {
  icon: ReactElement<SVGProps<SVGAElement>> | any;
}

const StyledIcon = styled.i<CustomiseComponent>`
  ${(props) => props.styles}

  display: flex;

  svg {
    fill: #212529
  }
`;

const Icon: React.FC<IconProps & CustomiseComponent> = ({
  icon,
  className = "",
  styles = "",
}) => {
  return (
    <StyledIcon
      className={`icon ${className}`}
      styles={styles}
    >
      {icon}
    </StyledIcon>
  );
};

export default Icon;