export type Field = {
	id: number;
	type: string;
};

export type FieldError = {
	id: number;
	message: string;
};

export type Confirmation = {
	isDefault: boolean;
	message: string;
};

export enum FormFieldTypeEnum {
	EMAIL = 'EMAIL',
	NAME = 'NAME',
	ADDRESS = 'ADDRESS',
	PHONE = 'PHONE',
	TEXTAREA = 'TEXTAREA',
	TEXT = 'TEXT',
	CONSENT = 'CONSENT',
	FILEUPLOAD = 'FILEUPLOAD',
	SELECT = 'SELECT',
	CAPTCHA = 'CAPTCHA'
}

export enum FormFieldVisibilityEnum {
	ADMINISTRATIVE = 'ADMINISTRATIVE',
	HIDDEN = 'HIDDEN',
	VISIBLE = 'VISIBLE',
}

export type FormField = {
	id: number;
	type: FormFieldTypeEnum;
	layoutGridColumnSpan: number;
	visibility: FormFieldVisibilityEnum;
};

export type NameInput = {
	first?: string;
	last?: string;
};

export type AddressInput = {
	street?: string;
	lineTwo?: string;
	city?: string;
	state?: string;
	zip?: string;
	country?: string;
};

export type ConsentInput = {
	value?: string;
};

export type SelectInput = {
	value?: string;
};

export type EmailInput = {
	value: string;
};

export type FileUploadInput = {
	name: string;
	type: string,
	size: number,
	tmp_name: string
};

export type NameInputProperty = {
	id: number;
	isHidden: boolean;
	key: string;
	label: string;
	customLabel: string;
	placeholder: string | null;
};

export type AddressInputProperty = {
	id: number;
	isHidden: boolean;
	key: string;
	label: string;
	customLabel: string;
	placeholder: string | null;
};

export type FileUploadInputProperty = {
	id: number;
	isHidden: boolean;
	label: string;
};

export type NameField = FormField & {
	inputs: NameInputProperty[];
	isRequired: boolean;
	label: string;
};

export type EmailField = FormField & {
	isRequired: boolean;
	label: string;
	placeholder: string | null;
};

export type PhoneField = FormField & {
	isRequired: boolean;
	label: string;
	placeholder: string | null;
};

export type AddressField = FormField & {
	isRequired: boolean;
	inputs: AddressInputProperty[];
	label: string;
};

export type ConsentField = FormField & {
	isRequired: boolean;
	label?: string;
	checkboxLabel?: string;
};

export type SelectField = FormField & {
	choices: {
		isSelected: boolean;
		text: string;
		value: string;
	}[];
	isRequired: boolean;
	label: string;
};

export enum FormFieldSizeEnum {
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
	SMALL = 'SMALL',
}

export type TextAreaField = FormField & {
	isRequired: boolean;
	label: string;
	placeholder: string | null;
	size: FormFieldSizeEnum;
};

export type TextField = FormField & {
	isRequired: boolean;
	label: string;
	placeholder: string | null;
};

export type FileUploadField = FormField & {
	isRequired: boolean;
	label: string;
	allowedExtensions: string[];
	maxFileSize: number;
	maxFiles: number;
};

export type GravityForm = {
	confirmations: Confirmation[];
	databaseId: number;
	title: string;
	description: string;
	submitButton: {
		text: string;
	};
	formFields: {
		nodes: FormField[];
	};
};
