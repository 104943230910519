import { wordPressApi } from "app/api/wordpress/wordPressApi";
import Button from "components/common/ui/Button/Button";
import IconSupport from "components/common/ui/Icons/IconSupport";
import Link from "components/common/ui/Link/Link";
import Menu from "components/common/ui/Menu/Menu";
import useLogout from "hooks/auth/useLogout";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import PathTranslationKey from "utilities/paths";
import { SideMenuFooterWrapper } from "./SideMenuFooter.Styled";
import SidenavFooterMenuItem from "./SideMenuFooterItem";
import { useTranslation } from "react-i18next";

const SidenavFooter: React.FC = () => {

  const { auth, ui } = useSelector((state) => state);
  const logout = useLogout();
  const { t } = useTranslation();
  const phone = (
    <a href={`tel:${ui.phoneNumber}`}>
      <IconSupport /> {ui.phoneNumber}
    </a>
  );

  if (auth.identifier) {
    const signoutHandler = () => {
      logout();
    };

    return (
      <SideMenuFooterWrapper>
        <Menu>
          <SidenavFooterMenuItem>{phone}</SidenavFooterMenuItem>
          <SidenavFooterMenuItem>
            <Link to={t(PathTranslationKey.ACCOUNT_OVERVIEW).toString()}>{t("account")}</Link>
          </SidenavFooterMenuItem>
          <SidenavFooterMenuItem>
            <Button onClick={signoutHandler}>{t("signOut")}</Button>
          </SidenavFooterMenuItem>
        </Menu>
      </SideMenuFooterWrapper>
    );
  }

  return (
    <SideMenuFooterWrapper>
      <Menu>
        <SidenavFooterMenuItem>{phone}</SidenavFooterMenuItem>
        <SidenavFooterMenuItem>
          <Link to={t(PathTranslationKey.SIGN_IN).toString()}>{t("signIn")}</Link>
        </SidenavFooterMenuItem>
        <SidenavFooterMenuItem>
          <Link to={t(PathTranslationKey.REGISTRATION).toString()}>{t("register")}</Link>
        </SidenavFooterMenuItem>
      </Menu>
    </SideMenuFooterWrapper>
  );
};

export default SidenavFooter;
