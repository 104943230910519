import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";
import { useEffect } from "react";
import { useSwiper } from "swiper/react";
import { StyledTimelineNavigationWrapper } from "./timeline.Styled";

type Props = {
	timelineYears: string[];
	activeIndex: number;
};

const TimelineNavigation = ({ timelineYears, activeIndex }: Props) => {
	const swiper = useSwiper();

	const transformWrapper =
		(1082 / (timelineYears.length + 3)) * swiper.activeIndex;

	const handleNextSlide = () => {
		if (swiper.activeIndex === timelineYears.length - 1) return;

		swiper.slideNext();
	};

	useEffect(() => {
		if (!timelineYears.length) {
			return;
		}

		if (swiper.activeIndex < timelineYears.length - 1) return;

		swiper.slideTo(timelineYears.length - 1);
	}, [swiper.activeIndex]);

	return (
		<StyledTimelineNavigationWrapper
			className="TimelineNavigationWrapper"
			transformWrapper={transformWrapper}
		>
			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="left" />}
				onClick={() => swiper.slidePrev()}
				className={`${swiper.activeIndex === 0 ? "disable-button" : ""}`}
			/>
			<ul>
				{timelineYears.map((year, index) => (
					<li key={`${year}-${index}`}>
						{year}
						<span
							className={swiper.activeIndex === index ? "current" : ""}
							onClick={() => swiper.slideTo(index)}
						></span>
					</li>
				))}
			</ul>
			<ButtonWithIcon
				variant="basic"
				icon={<IconChevron direction="right" />}
				onClick={() => handleNextSlide()}
				className={`${
					swiper.activeIndex === timelineYears.length - 1
						? "disable-button"
						: ""
				}`}
			/>
		</StyledTimelineNavigationWrapper>
	);
};

export default TimelineNavigation;
