import styled, { css } from "styled-components";
import { dark, darkGrey, grey, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import Typography from "components/common/ui/Typography/Typography";
import Link, { LinkProps } from "../Link/Link";
import { remCalc } from "utilities/styles";

interface StyledProps {
	basic: boolean;
	alternative?: boolean;
	outlined?: boolean;
	fullWidth?: boolean;
	textLight?: boolean;
	spaced?: boolean;
	margin?: {
		mt?: string;
		mb?: string;
		ml?: string;
		mr?: string;
	};
}

const marginMixin = css<{
	margin?: {
		mt?: string;
		mb?: string;
		ml?: string;
		mr?: string;
	};
}>`
	margin-top: ${({ margin }) => margin && margin.mt && margin.mt};
	margin-bottom: ${({ margin }) => margin && margin.mb && margin.mb};
	margin-left: ${({ margin }) => margin && margin.ml && margin.ml};
	margin-right: ${({ margin }) => margin && margin.mr && margin.mr};
`;

const buttonStyles = css<{
	alternative?: any;
	outlined?: any;
	textLight?: any;
	fullWidth?: any;
}>`
	background-color: ${({ alternative }) => (alternative ? white : dark)};

	border: solid ${remCalc(1)}
		${({ alternative, outlined }) =>
			alternative ? (!outlined ? white : dark) : dark};

	color: ${(props) => (props.alternative ? dark : white)};

	width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
	min-height: ${remCalc(40)};

	padding: ${remCalc(10)} ${remCalc(20)};

	font-size: ${remCalc(16)};
	font-family: ${Roboto};
	font-weight: ${(props) => (props.textLight ? 500 : 700)};
	text-decoration: none;
	text-align: center;

	&:hover {
		background-color: ${({ alternative }) => (alternative ? dark : darkGrey)};
		border: solid ${remCalc(1)}
			${({ alternative }) => (alternative ? dark : darkGrey)};
		color: ${({ alternative }) => (alternative ? white : white)};
	}

	&:active,
	&.active {
		background-color: ${({ alternative }) => (alternative ? darkGrey : grey)};
		border: solid ${remCalc(1)}
			${({ alternative }) => (alternative ? darkGrey : grey)};
		color: ${({ alternative }) => (alternative ? white : white)};
	}
`;

export const StyledButton = styled.button<StyledProps>`
	${marginMixin}
	background-color: ${({ alternative }) => (alternative ? white : dark)};
	border: solid ${remCalc(1)}
		${({ alternative, outlined }) =>
			alternative ? (!outlined ? white : dark) : dark};
	color: ${(props) => (props.alternative ? dark : white)};

	width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
	height: ${remCalc(40)};

	padding: ${remCalc(10)} ${remCalc(20)};

	font-size: ${remCalc(16)};
	font-family: ${Roboto};
	font-weight: ${(props) => (props.textLight ? 500 : 700)};
	position: relative;

	&:hover {
		background-color: ${({ alternative }) => (alternative ? dark : darkGrey)};
		border: solid ${remCalc(1)}
			${({ alternative }) => (alternative ? dark : darkGrey)};
		color: ${({ alternative }) => (alternative ? white : white)};
	}

	&:active,
	&.active {
		background-color: ${({ alternative }) => (alternative ? darkGrey : grey)};
		border: solid ${remCalc(1)}
			${({ alternative }) => (alternative ? darkGrey : grey)};
		color: ${({ alternative }) => (alternative ? white : white)};
	}

	ion-spinner {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: ${(props) => (props.alternative ? dark : white)};
	}

	&.loading {
		ion-spinner {
			display: block;
		}

		color: transparent;
	}
`;

export const StyledButtonLink = styled(Link)<LinkProps & StyledProps>`
	${marginMixin}
	${buttonStyles}
`;

export const StyledExternalLink = styled.a`
	${marginMixin}
	${buttonStyles}
`;

export const IconOnlyButton = styled.button`
	/* ${marginMixin} */

	> * {
		pointer-events: none;
	}

	display: flex;
	background-color: unset;
	padding: 0;
	line-height: unset;
	border: none;

	i {
		margin: auto;
	}

	&:hover,
	&:active,
	&.active {
		color: ${darkGrey};

		i {
			color: ${darkGrey};
		}

		i svg {
			fill: ${darkGrey};
		}
	}
`;

export const StyledTextButton = styled.button`

	font-family: ${Roboto};
	font-size: 14px;
	> * {
		pointer-events: none;
	}

	background: none;

	&:hover {
		text-decoration: underline;
	}
`;

export const StyledTextButtonContent = styled(Typography)`
	text-decoration: none;
	font: normal ${remCalc(14)} / ${remCalc(17)} ${Roboto};

	&:hover,
	&:active {
		color: ${darkGrey};
		text-decoration: underline;
		text-underline-offset: ${remCalc(2)};
	}
`;

export const BasicButton = styled.button<StyledProps>`
	> * {
		pointer-events: none;
	}

	display: flex;
	align-items: center;

	font-size: ${remCalc(16)};
	font-family: ${Roboto};
	font-weight: ${(props) => (props.textLight ? 500 : 700)};
	text-transform: uppercase;

	background-color: unset;
	padding: 0;
	line-height: unset;
	border: none;

	letter-spacing: ${(props) => (props.spaced ? remCalc(0.8) : "")};

	i {
		margin-right: ${remCalc(8)};
	}

	&:hover,
	&:active,
	&.active {
		color: ${darkGrey};

		i {
			color: ${darkGrey};
		}

		i svg {
			fill: ${darkGrey};
		}
	}
`;

export const BasicButtonLink = styled(Link)<StyledProps>`
	> * {
		pointer-events: none;
	}

	color: ${dark};

	display: flex;
	align-items: center;

	font-size: ${remCalc(16)};
	font-family: ${Roboto};
	font-weight: ${(props) => (props.textLight ? 500 : 700)};
	text-transform: uppercase;

	background-color: unset;
	padding: 0;
	line-height: unset;
	border: none;

	letter-spacing: ${(props) => (props.spaced ? remCalc(0.8) : "")};

	i {
		margin-right: ${remCalc(8)};
	}

	&:hover,
	&:active,
	&.active {
		color: ${darkGrey};

		i {
			color: ${darkGrey};
		}

		i svg {
			fill: ${darkGrey};
		}
	}
`;

export const StyledIconButton = styled.button`
	> * {
		pointer-events: none;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${remCalc(8)};
	padding: ${remCalc(12)} ${remCalc(14)};
	background-color: #fff;
	border: ${remCalc(1)} solid ${grey};
	color: ${dark};
	font: normal ${remCalc(14)} / ${remCalc(20)} ${Roboto};

	.icon.dark {
		display: none;
	}

	&:hover,
	&:active,
	&.active {
		background-color: ${dark};
		color: #fff;

		.icon.dark {
			display: flex;
		}

		.icon.light {
			display: none;
		}
	}
`;

export const StyledIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${remCalc(20)};
	height: ${remCalc(20)};
`;
