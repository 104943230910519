import { getGender } from "app/utils/bigcommerceProductUtil";
import useSegmentify from "hooks/segmentify/useSegmetify";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import store from "redux/store";
import { PageType } from "ts/enums";
import { Product } from "ts/types";
import { Currency } from "types/BigCommerce";
import { Cart, PhysicalItem } from "types/CartTypes";

export enum DatalayerEvent {
	PAGE_VIEW = "page_view",
	VIEW_ITEM_LIST = "view_item_list",
	SELECT_ITEM = "select_item",
	VIEW_ITEM = "view_item",
	ADD_TO_CART = "add_to_cart",
	ADD_TO_WISHLIST = "add_to_wishlist",
	REMOVE_FROM_WISHLIST = "remove_from_wishlist",
	VIEW_CART = "view_cart",
	REMOVE_FROM_CART = "remove_from_cart",
	BEGIN_CHECKOUT = "begin_checkout",
	ADD_SHIPPING_INFO = "add_shipping_info",
	ADD_PAYMENT_INFO = "add_payment_info",
	PURCHASE = "purchase",
	FORM_SUBMISSION = "form_submission",
}

export type ItemList = {
	id: string;
	name: string;
};

/**
 * Convert a Product to a data layer item
 * @param product to covert to data layer item
 * @param index index value
 * @param itemListId
 * @param itemListName
 * @returns
 */
const convertProduct = (
	product: Product,
	index: number,
	itemListId: string | number,
	itemListName: string,
) => {
	return {
		item_id: product.entityId,
		item_name: product.name,
		currency: product.price.currencyCode,
		discount: 0,
		index: index,
		item_brand: "MONA",
		item_list_id: itemListId,
		item_list_name: itemListName,
		item_variant: "",
		price: product?.salePrice?.value || product?.basePrice?.value,
		quantity: 1,
	};
};

const convertCartItems = (cart: Cart | undefined) => {
	const items = [] as any[];

	if (cart) {
		cart.line_items.physical_items.forEach((item, index) => {
			items.push({
				item_id: item.sku,
				item_name: item.name,
				currency: cart.currency.code,
				discount: item.discount_amount,
				index: index,
				item_brand: "",
				item_variant: item.variant_id,
				price: String(item.list_price),
				quantity: String(item.quantity),
			});
		});
	}

	return items;
};

const convertIndividualCartItem = (
	cartItem: PhysicalItem,
	currency: string,
	quantity: number = 1,
	isRemove: boolean = false,
	itemList: ItemList,
) => {
	let item: any = {
		item_id: cartItem.sku,
		item_name: cartItem.name,
		currency,
		discount: cartItem.discounts.reduce(
			(accumulator: any, discount: any) =>
				accumulator + discount.discounted_amount,
			0,
		),
		index: 0,
		item_brand: "",
		item_variant: cartItem.variant_id,
		price: String(cartItem.extended_list_price && cartItem.extended_list_price !== cartItem.extended_sale_price ? cartItem.extended_list_price : cartItem.extended_sale_price),
		...cartItem.extended_list_price && cartItem.extended_list_price !== cartItem.extended_sale_price && {
			oldPrice: String(cartItem.extended_sale_price),
		},
		quantity: quantity,
	};

	if (!isRemove) {
		item.item_list_id = itemList.id;
		item.item_list_name = itemList.name;
	}

	return [item];
};

const convertWishlistItem = (
	wishlistItem: Product,
	currency: string | undefined,
	quantity: number = 1,
) => {
	let item: any = {
		item_id: wishlistItem.sku,
		item_name: wishlistItem.name,
		currency,
		index: 0,
		price: wishlistItem.price,
		quantity,
	};

	return item;
};

const convertProductToSegmentifyProduct = (
	product: Product,
	eventType: string,
	bcStore: any
) => {
	const {
		display: {
			symbol = ''
		} = {}
	} = bcStore.currencies.find(
		(currency: Currency) => currency.isActive
	)

	const image =
		product.images.find((image) => image.isDefault)?.url ||
		product.images.length
			? product.images[0].url
			: "";

	const sizes = product.variants?.map((variant) => variant.option.label);

	const gender = getGender(product);

	const hasSalePrice = (product.salePrice?.value && product.salePrice?.value !== product?.price?.value) || false;

	const basePrice = String(product.price?.formatted).replace(symbol, '')
	const salePrice = String(product.salePrice?.formatted).replace(symbol, '')

	const segmentifyEvent = {
		name: eventType,
		productId: product.sku,
		title: product.name,
		inStock: product.availability === "Available",
		url: window.location.href,
		image: image,
		category: product?.categories?.breadcrumbs.map(
			(breadcrumb) => breadcrumb.name
		).join(' > '),
		brand: "Mona",
		price: salePrice ? salePrice : basePrice ,
		...hasSalePrice && {
			oldPrice: basePrice
		},
		gender,
		sizes,
	};

	return segmentifyEvent;
};

const convertCartItemToSegmentifyProduct = (
	product: PhysicalItem & {
		parent_sku: string;
	},
	eventType: string,
	step: "add" | "remove" | "" = "",
) => {
	const sizes = product.options?.map((variant) => variant.value);

	const segmentifyEvent: any = {
		name: eventType,
		productId: product.parent_sku,
		title: product.name,
		inStock: true,
		stockCount: "",
		url: product.url,
		image: product.image_url,
		brand: "Mona",
		price: String(product.extended_list_price && product.extended_list_price !== product.extended_sale_price ? product.extended_list_price : product.extended_sale_price),
		...product.extended_list_price && product.extended_list_price !== product.extended_sale_price && {
			oldPrice: String(product.extended_sale_price),
		},
		gender: "female",
		sizes,
	};

	if (step) {
		segmentifyEvent.step = step;
	}

	return segmentifyEvent;
};

const setupGTM = () => {
	const gtmScriptTag = document.querySelector("[data-gtm-script]");

	if (gtmScriptTag === null) {
		const scriptTag = document.createElement("script");
		scriptTag.dataset.gtmScript = "gtm-script";

		scriptTag.innerHTML = `(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.async = true;
			j.src = "https://tag.monaonline.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, "script", "dataLayer", "GTM-WVK5N2T");`;

		document.head.appendChild(scriptTag);
	}
};

const useDataLayer = () => {
	const reduxStore = store;
	const { auth, bcStore, location } = useSelector((state) => state);
	const segmentify = useSegmentify();

	const dataLayer = (window as any).dataLayer;
	const GTM = (window as any).google_tag_manager;

	const [isReady, setIsReady] = useState(GTM?.dataLayer?.gtmLoad !== undefined);
	const [queuedEvents, setQueuedEvents] = useState<any[] | undefined>([]);

	useEffect(() => {
		setupGTM();
	}, []);

	useEffect(() => {
		if (isReady && queuedEvents !== undefined) {
			queuedEvents.forEach((event) => dataLayer.push(event));
			setQueuedEvents(undefined);
		}
	}, [isReady]);

	const checkReady = () => {
		const ready = GTM?.dataLayer?.gtmLoad !== undefined;
		if (!isReady && ready) {
			setIsReady(true);
		}
	};

	/**
	 * Check to see if datalayer is ready and either queue the event or push to datalayer
	 * @param event to push
	 */
	const push = (event: any) => {
		if (!isReady) {
			const events = queuedEvents !== undefined ? [...queuedEvents] : [];

			setQueuedEvents([...events, event]);
			checkReady();
		} else {
			dataLayer.push(event);
		}
	};

	const dataLayerEcommerce = (
		event: DatalayerEvent,
		items: any[] | string,
		currency: string | undefined,
		value: number | undefined,
	) => {
		push({
			ecommerce: null,
		}); // clear previous object

		push({
			event,
			ecommerce: {
				...(currency && { currency }),
				...(value && { value }),
				items,
			},
		});
	};

	const dataLayerEvent = (event: DatalayerEvent, formType: string) => {
		push({
			event,
			formType: formType,
		});
	};

	const pageView = async (pageType: PageType, breadcrumbs?: string) => {
		if (reduxStore) {
			const userId = auth.customer ? (auth.customer as any).id : null;
			const activeCurrency = bcStore.currencies.find(
				(currency) => currency.isActive,
			);

			let data: {
				event: string;
				currencyCode: string;
				language: string;
				userId: any;
				pageType: PageType;
				subCategory?: string;
			} = {
				event: "page_view",
				currencyCode: activeCurrency?.code || "RSD",
				language: location?.preferredLocation || "RS",
				userId,
				pageType: pageType,
			};

			push(data);

			segmentify.pageViewOnly(pageType, breadcrumbs);
		}
	};

	const viewItemList = (
		products: Product[],
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = products.map((product, index) =>
			convertProduct(product, index, itemListId, itemListName),
		);

		dataLayerEcommerce(
			DatalayerEvent.VIEW_ITEM_LIST,
			items,
			undefined,
			undefined,
		);
	};

	const viewItem = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const item = [convertProduct(product, 0, itemListId, itemListName)];

		dataLayerEcommerce(DatalayerEvent.VIEW_ITEM, item, undefined, undefined);

		const segmentifyEvent = convertProductToSegmentifyProduct(
			product,
			"PRODUCT_VIEW",
			bcStore
		);

		segmentify.pageViewWithEvent(segmentifyEvent, PageType.PRODUCT);
	};

	const selectItem = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = [convertProduct(product, 0, itemListId, itemListName)];
		dataLayerEcommerce(DatalayerEvent.SELECT_ITEM, items, undefined, undefined);
	};

	const addToWishList = (
		product: Product,
		itemListId: string | number,
		itemListName: string,
	) => {
		const items = [convertProduct(product, 0, itemListId, itemListName)];
		dataLayerEcommerce(
			DatalayerEvent.ADD_TO_WISHLIST,
			items,
			undefined,
			undefined,
		);
	};

	// remove from wishlist
	const removeWishListItem = (
		products: Product[],
		currency: string | undefined,
	) => {
		const items = products.map((product) =>
			convertWishlistItem(product, currency, 1),
		);

		dataLayerEcommerce(
			DatalayerEvent.REMOVE_FROM_WISHLIST,
			items,
			undefined,
			undefined,
		);
	};

	const viewCart = (cart: Cart | undefined) => {
		const items = convertCartItems(cart);

		dataLayerEcommerce(
			DatalayerEvent.VIEW_CART,
			items,
			cart?.currency.code || undefined,
			cart?.cart_amount || undefined,
		);

		const productList = items.map((item) => {
			return {
				productId: /[0-9](.+)/.exec(item.item_name)?.[0] ?? item.item_id,
				price: item.price,
				currency: item.currency,
				quantity: String(item.quantity),
			};
		});

		const segmentifyEvent = {
			name: "CHECKOUT",
			step: "view-basket",
			totalPrice: cart?.cart_amount,
			cartUrl: cart?.redirect_urls.cart_url,
			productList: productList,
		};

		segmentify.pageViewWithEvent(segmentifyEvent,PageType.CART);
	};

	const addToCart = (
		triggeredFrom: string,
		product: PhysicalItem & {
			parent_sku: string;
		},
		quantity: number,
		currency: string,
		itemList: ItemList,
	) => {
		const items = convertIndividualCartItem(
			product,
			currency,
			quantity,
			false,
			itemList,
		);

		dataLayerEcommerce(
			DatalayerEvent.ADD_TO_CART,
			items,
			currency,
			product.list_price,
		);

		const segmentifyEvent = convertCartItemToSegmentifyProduct(
			product,
			"BASKET_OPERATIONS",
			"add",
		);

		segmentify.pageViewWithEvent(segmentifyEvent, triggeredFrom);
	};

	const removeFromCart = (
		triggeredFrom: string,
		product: PhysicalItem & {
			parent_sku: string;
		},
		quantity: number,
		currency: string,
		itemList: ItemList,
	) => {
		const items = convertIndividualCartItem(
			product,
			currency,
			quantity,
			true,
			itemList,
		);
		dataLayerEcommerce(
			DatalayerEvent.REMOVE_FROM_CART,
			items,
			undefined,
			undefined,
		);

		const segmentifyEvent = convertCartItemToSegmentifyProduct(
			product,
			"BASKET_OPERATIONS",
			"remove",
		);

		segmentify.pageViewWithEvent(segmentifyEvent, triggeredFrom);
	};

	const formSubmission = (event: string) => {
		const formType = event;
		dataLayerEvent(DatalayerEvent.FORM_SUBMISSION, formType);
	};

	const updateConsent = (
		marketing: boolean,
		stats: boolean,
		permanent: boolean,
	) => {
		const getValue = (value: boolean) => {
			return value ? "granted" : "denied";
		};

		push({
			event: "consent",
			update: {
				ad_storage: getValue(marketing),
				analytics_storage: getValue(stats),
				personalization_storage: getValue(permanent),
			},
		});
	};

	return {
		pageView,
		viewItemList,
		selectItem,
		viewCart,
		addToCart,
		removeFromCart,
		viewItem,
		addToWishList,
		removeWishListItem,
		formSubmission,
		updateConsent,
	};
};

export default useDataLayer;
