import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import { embedCheckout } from "@bigcommerce/checkout-sdk";
import { useEffect } from "react";
import useCart from "hooks/cart/useCart";

const Checkout: React.FC = () => {
	const cart = useCart();

	useEffect(() => {
		// if (cartData) {
			embedCheckout({
				containerId: "foo-bar-checkout",
				url: "https://en-checkout.dev-vercel.monaonline.com/",
			});
		// }
	}, []);

	return (
		<Page pageType={PageType.DEFAULT}>
			<div id="foo-bar-checkout"></div>
		</Page>
	);
};

export default Checkout;
