import { useEffect, useMemo, useState } from "react";
import { useLocation, useMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { remove as removeAccents } from "remove-accents";
import { useDispatch, useSelector } from "redux/hooks";
import { setBrand } from "redux/UI/uiSlice";

export enum Brand {
	Global = "global",
	Women = "women",
	Men = "men",
}

type FunctionReturnType<S, A> = {
	active: S;
	param: A;
};

const useBrand = (): FunctionReturnType<string, any> => {
	const dispatch = useDispatch();
	const brand = useSelector((state) => state.ui.brand);
	const { pathname, search } = useLocation();
	const normalizeUrl = removeAccents(
		decodeURI(pathname + search),
	).toLowerCase();

	const { t } = useTranslation();

	const translations = useMemo(() => {
		return {
			women: removeAccents(t("women")).toLowerCase(),
			men: removeAccents(t("men")).toLowerCase(),
		};
	}, [t]);

	useEffect(() => {
		let active: string = Brand.Global as string;

		if (
			String(normalizeUrl) &&
			normalizeUrl !== undefined &&
			normalizeUrl !== null
		) {
			if (normalizeUrl.includes(translations.women)) {
				active = Brand.Women as string;
			} else if (normalizeUrl.includes(translations.men)) {
				active = Brand.Men as string;
			}
		}

		if (brand !== active) {
			dispatch(setBrand(active));
		}
	}, [normalizeUrl, t]);

	return {
		active: brand as string,
		param: brand === Brand.Global ? "/" : translations[brand],
	};
};

export default useBrand;
