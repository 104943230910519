import { createSlice } from "@reduxjs/toolkit";
import { getStoreCodeFromCountryCode } from "app/bigcommerce/helpers/BcConfigHelper";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    defaultLocation: window.localStorage.getItem("defaultLocation"),
    preferredLocation: window.localStorage.getItem("preferredLocation"),
    selectedLocale: window.localStorage.getItem("selectedLocale"),
  },
  reducers: {
    setDefaultLocation: (state, action) => {
      let defaultLocation = (state.defaultLocation = (
        action.payload ? action.payload : "en"
      ).toLowerCase());

      defaultLocation = getStoreCodeFromCountryCode(defaultLocation);

      window.localStorage.setItem("defaultLocation", defaultLocation);
      state.defaultLocation = defaultLocation;

      if (!state.preferredLocation) {
        window.localStorage.setItem("preferredLocation", defaultLocation);
        state.preferredLocation = defaultLocation;
      }
    },
    setPreferredLocation: (state, action) => {
      let preferredLocation = (
        action.payload ? action.payload : ""
      ).toLowerCase();

      preferredLocation = getStoreCodeFromCountryCode(preferredLocation);

      window.localStorage.setItem("preferredLocation", preferredLocation);
      state.preferredLocation = preferredLocation;
    },
    setSelectedLocale: (state, action) => {
      window.localStorage.setItem("selectedLocale", action.payload);
      state.selectedLocale = action.payload;
    },
  },
});

export const { setDefaultLocation, setPreferredLocation, setSelectedLocale } =
  locationSlice.actions;

export default locationSlice.reducer;
