import BaseForm from "components/common/form/Form/Form";
import styled from "styled-components";
import { pageContentContainer } from "theme/StyledMixins";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

type Props = {
	progress: number | undefined;
};

export const StyledLoyaltyWrapper = styled.div`
	color: ${dark};

	.current-tier {
		margin-bottom: 2rem;
		font-size: ${remCalc(20)};

		@media ${aboveMedium} {
			text-align: center;
			margin-bottom: ${remCalc(24)};
			br {
				display: none;
			}
		}

		& > span {
			font-weight: 700;
		}
	}
`;

export const StyledBarcodeWrapper = styled.div`
	position: relative;
	text-align: center;
	padding-top: 3rem;
	margin-top: -2rem; // fixes the padding on the parent container for this page only
	margin-bottom: 4rem;

	@media ${aboveMedium} {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		gap: 1rem;
	}

	.barcode-wrapper {
		min-width: fit-content;
		grid-column: 4 / span 3;
	}

	.content-wrapper {
		grid-column: span 5;

		@media ${aboveMedium} {
			text-align: left;
			margin-left: ${remCalc(6)};
		}

		h3 {
			position: absolute;
			top: 0;
			width: 100%;
			font-size: 1.25rem;
			font-weight: 700;

			@media ${aboveMedium} {
				position: relative;
				inset: unset;
			}
		}

		p {
			&.card-number {
				margin-top: 0.5rem;
				margin-bottom: 1rem;
			}
		}
	}

	.card-number {
		font-size: 1rem;
		font-weight: 700;

		& + p {
			font-size: 1.25rem;
		}
	}
`;

export const StyledLoyaltyProgressWrapper = styled.section<Props>`
	width: 100vw;
	position: relative;
	padding-block: 1.5rem;
	left: 50%;
	transform: translateX(-50%);
	color: ${dark};
	background-color: #f8f9fa;

	h3 {
		font-weight: 700;
		font-size: 1rem;
		margin-bottom: 2rem;

		br {
			@media ${aboveMedium} {
				display: none;
			}
		}

		span {
			padding-top: 0.25rem;
			display: inline-block;
			font-weight: 400;

			@media ${aboveMedium} {
				padding: 0;
				margin-left: 1.5rem;
			}
		}
	}

	.container {
		${pageContentContainer}
		position: relative;
		padding: 0 ${remCalc(7.5)};

		@media ${aboveMedium} {
			padding: 0;
		}
	}

	.progress-bar-outer {
		width: 100%;
		height: 2rem;
		background-color: white;
		position: relative;
		border-radius: 1rem;
		border: #e3e3e3 1px solid;
		overflow: hidden;
		margin-bottom: 0.5rem;
	}

	.progress-carat {
		${({ progress }) => `
            left: calc(${progress}% - 5px);
        `}
		width: 10px;
		height: 10px;
		display: block;
		position: absolute;
		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		background-color: ${dark};
		top: 35%;
		transform: rotate(180deg);
		transition: left 0.7s cubic-bezier(0, -0.03, 0.15, 0.98);

		@media ${aboveMedium} {
			top: 27%;
		}
	}

	.progress-bar-inner {
		width: 100%;
		height: 2.2rem;
		background-color: ${dark};

		position: absolute;
		left: -100%;
		${({ progress }) => `
            transform: translateX(${progress}%);
        `}
		transition: transform .7s cubic-bezier(0,-0.03, 0.15, 0.98);
	}

	.progress-bar-labels {
		display: flex;
		justify-content: space-between;

		& > span {
			font-weight: 700;
			font-size: 1rem;
		}
	}
`;

export const StyledLoyaltyInformationWrapper = styled.section`
	margin-top: -2rem; // fixes the padding on the parent container for this page only

	h3 {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 1.5rem;
	}

	h4 {
		font-weight: 700;
		font-size: 1.25rem;
	}

	.wordpress-content-wrapper {
		color: ${accountDarkGrey};

		strong {
			color: ${dark};
		}

		a {
			color: ${darkGrey};

			&:hover {
				color: ${dark};
			}
		}
	}

	.join-button-wrapper {
		background-color: #f8f9fa;
		width: 100vw;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		padding-block: 2rem;
		margin-block: 2rem;

		.btn {
			width: calc(100% - 2rem);
			max-width: 64.3rem;
			margin-inline: auto;
			display: block;

			@media ${aboveMedium} {
				width: fit-content;
			}
		}

		span {
			margin-top: 1rem;
			color: red;
			text-align: center;
			display: block;
			width: 100%;
		}

		.success-message {
			text-align: center;
		}
	}

	input {
		background-color: white;
	}
`;

export const StyledLoyaltyFormWrapper = styled(BaseForm)`
	width: 100%;

	@media ${aboveMedium} {
		width: 50%;
	}

	.error-message {
		color: red;
	}

	.btn {
		margin-top: ${remCalc(24)};
		width: 100%;

		@media ${aboveMedium} {
			width: fit-content;
		}
	}
`;
