import styled from "styled-components";
import { accountDarkGrey, grey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledInput = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 2px 0px 0px 2px;
  padding: 0px 11px;
  height: 40px;
  color: ${accountDarkGrey};
  font: normal 16px/20px ${Roboto};
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid ${grey};
  }
`;
