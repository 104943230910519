import { useLazyGetProductsBySkusQuery } from "app/api/product/productApi";
import ProductCard from "components/ProductCard/ProductCard";
import { InputText } from "components/common/form/Input/Input";
import useBreakpoints from "hooks/useBreakpoints";
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useLocation } from "react-router";
import { useSelector } from "redux/hooks";
import { Product } from "ts/types";
import { useDebounce, useOnClickOutside } from "usehooks-ts";
import Button from "../Button/Button";
import IconClose from "../Icons/IconClose/IconClose";
import IconSearch from "../Icons/IconSearch/IconSearch";

import {
	Overlay,
	SearchModalContainer,
	StyledLink,
	StyledSuggestion,
} from "./SearchModal.Styled";

import { useLazyGetQueryFromPromptQuery } from "app/api/segmentify/segmentifyApi";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

const SearchModal = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	const onClose = () => {
		setIsOpen(false);
	};

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<Button icon={<IconSearch />} onClick={() => setIsOpen(true)} />
			{isOpen && (
				<Portal node={document && document.getElementById("potal__modal")}>
					<Search isOpen={isOpen} onClose={onClose} />
				</Portal>
			)}
		</>
	);
};

interface SearchInterface {
	isOpen: boolean;
	onClose: () => void;
}

const Search = ({ isOpen, onClose: onCloseProp }: SearchInterface) => {
	const {
		location: {
			selectedLocale,
		},
		bcStore: {
			currencies
		}
	} = useSelector(state => state);

	const { large } = useBreakpoints();
	const [searchQuery, setSearchQuery] = React.useState("");
	const [searchLoading, setSearchLoading] = React.useState(false);
	const [keyWords, setKeyWords] = React.useState<string[]>([]);
	const { t } = useTranslation();
	const navigate = useLocaleNavigate();

	const searchQueryHandler = (searchText: string) => {
		setSearchLoading(true);
		setSearchQuery(searchText);
	};

	const customer: any = useSelector((state) => state.auth.customer);

	const searchQueryDebounce = useDebounce(searchQuery, 300);

	const [getProductSkus, getProductSkusResult] =
		useLazyGetQueryFromPromptQuery();

	const [getProductsBySkus, getProductsBySkusResult] =
		useLazyGetProductsBySkusQuery();

	// request segmentify data based on search string
	React.useMemo(() => {
		if (searchQuery.length < 2) {
			return;
		}

		getProductSkus({
			search: searchQuery,
			customer: customer,
			sessionId: sessionStorage.getItem("sessionId"),
			lang: selectedLocale ?? 'EN',
			currencyCode: currencies[0]?.code ?? 'EUR'
		});
	}, [searchQueryDebounce]);

	// get keywords from result of segmentify (doesnt seem to return if search string > 3 characters)
	React.useMemo(() => {
		if(getProductSkusResult.data?.keywords && Object.keys(getProductSkusResult.data?.keywords).length>0){
			setKeyWords(Object.keys(getProductSkusResult.data?.keywords))
		}
	}, [getProductSkusResult.data]);

	//compare if segmentify gets the same result twice
	const [currentIDs, setCurrentIDs] = useState([]);
	const newIDs = getProductSkusResult.data
		? getProductSkusResult.data?.products.map((item: any) => {
				return item.productId;
		  })
		: [];

	const productIDsUnchanged = () => {
		return (
			currentIDs.length == newIDs.length &&
			currentIDs.every(function (element, index) {
				return element === newIDs[index];
			})
		);
	};
	// set product IDs and stop loading if no data is to be changed
	const searchProductResults = React.useMemo(() => {
		if (getProductSkusResult.data?.products) {
			if (productIDsUnchanged()) {
				setSearchLoading(false);
			}
			setCurrentIDs(newIDs);
			return getProductSkusResult.data?.products.map((item: any) => {
				return item.productId;
			});
		} else {
			setSearchLoading(false);
			return [];
		}
	}, [getProductSkusResult.data?.products]);

	// if new product IDs, get products from big commerce
	useEffect(() => {
		const skus = searchProductResults.slice(0, 8);
		getProductsBySkus(skus);
	}, [searchProductResults]);

	const products: Product[] = getProductsBySkusResult.data
		? getProductsBySkusResult.data
		: [];

	// find out what result screen to show
	const validSearch = Boolean(searchQuery.length > 2);
	const isNowLoading = Boolean(searchLoading && searchQuery.length > 2);
	const hasResults = Boolean(products.length && searchQuery.length > 2);

	// if new products were requested, stop loading on completion
	useEffect(() => {
		setSearchLoading(false);
	}, [products]);

	const onClose = () => {
		setSearchQuery("");
		onCloseProp();
	};

	React.useEffect(() => {
		if (inputRef && inputRef.current) inputRef.current.focus();
	});


	const searchOnEnter = (event:any)=>{
		if(event.key === 'Enter') {
			navigate(`${t(PathTranslationKey.SEARCH)}?q=${searchQuery}`)
		}
	}
	const inputRef = React.useRef<HTMLInputElement>(null);
	const ref = React.useRef(null);
	useOnClickOutside(ref, () => large && onClose());
	return (
		<>
			<Overlay isOpen={isOpen} />
			<SearchModalContainer ref={ref} isOpen={isOpen} hasResults={hasResults}>
				<div className="search__header">
					<div className="search__input">
						<InputText
							onKeyDown={searchOnEnter}
							ref={inputRef}
							value={searchQuery}
							onChange={(e) => searchQueryHandler(e.target.value)}
							placeholder={t("search.placeholder") as string}
						/>
						<Button
							className={searchQuery.length ? "" : "hidden"}
							onClick={() => setSearchQuery("")}
						>
							{t("filterAsideClear")}
						</Button>
					</div>
					<div className="search__close">
						<Button icon={<IconClose />} onClick={onClose} />
					</div>
				</div>
				{validSearch && (
					<div className="search__body">
						{keyWords.length > 0 && (
							<div className="search__suggestions">
								<p className="heading">{t("search.suggestions")}</p>
								{keyWords.slice(0, 3).map((result: string) => (
									<StyledSuggestion
										onClick={setSearchQuery}
										key={result}
										search={searchQuery}
										result={result}
									/>
								))}
							</div>
						)}
						<div className="search__products">
							<div className="products__header">
								<p className="heading">{t("search.products")}</p>
								<StyledLink to={`${t(PathTranslationKey.SEARCH)}?q=${searchQuery}`}>
									{t("search.see_all")}
								</StyledLink>
							</div>
							{isNowLoading ? (
								<div className="products">
									<ProductCard
										triggeredFrom="SEARCH"
										onAddToCart={onClose}
										key={nanoid()}
										layout={1}
										loading={true}
										product={undefined}
									/>
									<ProductCard
										triggeredFrom="SEARCH"
										onAddToCart={onClose}
										key={nanoid()}
										layout={1}
										loading={true}
										product={undefined}
									/>
								</div>
							) : hasResults ? (
								<div className="products">
									{products.map((product) => (
										<ProductCard
											triggeredFrom="SEARCH"
											onAddToCart={onClose}
											key={nanoid()}
											layout={1}
											product={product}
										/>
									))}
								</div>
							) : (
								<div className="empty-search">
									<h4>{t("search.no_products_found.header")}</h4>
									<p>{t("search.no_products_found.body")}</p>
								</div>
							)}
						</div>
					</div>
				)}
			</SearchModalContainer>
		</>
	);
};

export default SearchModal;
