import styled from "styled-components";
import { overlayZIndex } from "utilities/variables/zIndex";


export const StyledOverlay = styled.div<{
  show: boolean;
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.show ? "rgb(33, 37, 41, 0.9)" : "transparent"};
  z-index: ${overlayZIndex};
`;

interface OverlayProps {
  show?: boolean;
  onClick?: () => void;
}

const Overlay: React.FC<OverlayProps> = ({
  show = false,
  onClick = () => {},
}) => {

  if(!show) {
    return null
  }

  return <StyledOverlay className="Overlay" onClick={() => onClick()} show={show} />;
};

export default Overlay;
