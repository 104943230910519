import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountLightGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveLarge, aboveMedium } from "utilities/variables/media";

export const StyledAboutUsPageWrapper = styled.main`
	.StyledFiguresSection {
		margin-bottom: 4rem;

		@media ${aboveMedium} {
			margin-bottom: 6rem;
		}
	}

	.Timeline {
		margin-bottom: ${remCalc(50)};
	}

	.image-and-text-layout {
		.container {
			display: flex;
			flex-direction: column;

			@media ${aboveMedium} {
				margin-bottom: ${remCalc(96)};
			}

			@media ${aboveLarge} {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}

			.image {
				@media ${aboveMedium} {
					min-height: ${remCalc(450)};
				}
			}
		}
	}

	.image-banner {
		margin-bottom: ${remCalc(64)};

		@media ${aboveMedium} {
			margin-bottom: ${remCalc(96)};
		}
	}

	.vision-section {
		.image-and-text-layout {
			margin-bottom: ${remCalc(64)};
		}

		.image-container {
			order: 1;

			@media ${aboveMedium} {
				order: 2;
			}

			picture {
				height: 100%;
			}
		}

		.container {
			min-height: 100%;
			margin-bottom: 0;

			.text-split-container {
				padding: ${remCalc(64)} ${remCalc(24)};
				background-color: ${accountLightGrey};
			}

			@media ${aboveMedium} {
				min-height: ${remCalc(385)};

				.text-split-container {
					padding: ${remCalc(64)};
				}
			}

			@media ${aboveLarge} {
				min-height: ${remCalc(608)};
			}
		}
	}

	.swiper-wrapper .swiper-slide:first-of-type.swiper-slide-active {
		@media ${aboveMedium} {
			margin-left: ${remCalc(20)};
		}
	}

	.skeleton-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		flex-wrap: wrap;
		margin-top: ${remCalc(64)};
		margin-bottom: ${remCalc(48)};

		@media ${aboveMedium} {
			margin-top: ${remCalc(96)};
			margin-bottom: ${remCalc(96)};
		}
	}

	.skeleton-text {
		padding: 0 ${remCalc(30)};
		width: 100%;
		margin-top: ${remCalc(48)};

		@media ${aboveMedium} {
			padding: 0 ${remCalc(64)};
			width: 50%;
			margin-top: ${remCalc(48)};
		}
	}
`;

export const SkeletonWrapper = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	@media ${aboveMedium} {
		width: 50%;
	}

	.skeleton-text {
		width: 50%;
	}

	.skeleton-container {
		width: 100%;
	}

	&.skeleton-timeline {
		display: flex;
		height: ${remCalc(480)};
		width: ${remCalc(320)};
		margin-bottom: ${remCalc(214)};

		@media ${aboveMedium} {
			height: ${remCalc(609)};
			width: 100%;
		}
	}

	// Style image
	.react-loading-skeleton {
		width: 100%;
		height: ${remCalc(232)};

		@media ${aboveMedium} {
			height: ${remCalc(609)};
		}
	}
`;

export const StyledCompanyValuesSection = styled.section`
	margin-bottom: 0;

	@media ${aboveMedium} {
		margin-bottom: 6rem;
	}

	h2 {
		font-family: ${Roboto};
		font-size: ${remCalc(24)};
		color: ${dark};
		text-transform: uppercase;
		text-align: center;
		font-weight: 700;
		letter-spacing: 1.2px;
		margin-bottom: ${remCalc(10)};

		@media ${aboveMedium} {
			margin-bottom: ${remCalc(33)};
		}
	}
`;
