import React, { useEffect, useMemo } from "react";

import { HTMLReactParserOptions } from "html-react-parser";
import { useLocation, useMatch } from "react-router";

import { wordPressApi } from "app/api/wordpress/wordPressApi";

import {
	domNodeIsElement,
	domNodeIsText,
} from "components/common/ui/Markdown/Markdown";
import SpContent from "components/supportingPages/SpContent/SpContent";

import { useSelector } from "redux/hooks";

import { MenuItemData } from "ts/types";

import { gravityFormApi } from "app/api/wordpress/gravityFormApi";
import SupportingPageLayout from "components/common/layouts/SupportingPageLayout/SupportingPageLayout";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import SpNav from "components/supportingPages/SpNav/SpNav";
import usePageContent from "hooks/usePageContent";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import { StyledGravityForm, StyledMap } from "./ContactUsPage.Styled";
import { FormFieldTypeEnum } from "types/GravityForm";

const ContactUsPage = () => {
	const match = useMatch("/:locale/*");
	const uri = match?.params["*"] || "";

	const { data, isLoading } = wordPressApi.useGetPageContentByUriQuery(uri);
	const menuItems = wordPressApi.useGetMenuItemsBySlugQuery("footer");
	const location = useLocation();
	const pageContent = usePageContent();

	useEffect(() => {
		pageContent.scrollToTop();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const [formId, setFormId] = React.useState<string>();
	const gravityFormQuery = gravityFormApi.useGetGravityFormByIdQuery(
		formId ? formId : "",
	);

	const parentNavOption = useMemo(() => {
		const parentItem = menuItems.data?.find((menuItem: MenuItemData) => {
			if (menuItem.children.find((child) => child.path.includes(uri))) {
				return menuItem;
			}
		}) as MenuItemData;

		return parentItem;
	}, [menuItems.data]);

	const navOptions = useMemo(() => {
		return (
			parentNavOption?.children.map((child) => {
				return {
					label: child.label,
					value: child.path,
				};
			}) || []
		);
	}, [parentNavOption, menuItems.data]);

	const getGoogleMapsBlocks = (str: string) => {
		let regex =
			/\[googlemaps latitude=[0-9]*\.[0-9]+ longitude=[0-9]*\.[0-9]+\]/g;
		return [...str.matchAll(regex)];
	};

	const getLat = (str: string) => {
		let regex = /latitude=([0-9]*\.[0-9]+)/g;
		return [...str.matchAll(regex)][0][1];
	};

	const getLng = (str: string) => {
		let regex = /longitude=([0-9]*\.[0-9]+)/g;
		return [...str.matchAll(regex)][0][1];
	};

	const getGoogleMapBlocksFromChildren = React.useCallback((arr: any) => {
		return arr.filter((childNode: any) => {
			if (domNodeIsText(childNode)) {
				const blocks = getGoogleMapsBlocks(childNode.data);
				const hasGoogleMapBlock = Boolean(blocks[0]?.length);
				return hasGoogleMapBlock;
			}
			return false;
		});
	}, []);

	const customer: any = useSelector((state) => state.auth.customer);

	const options: HTMLReactParserOptions = React.useMemo(() => {
		return {
			replace: (domNode) => {
				// Remove script tags
				if (domNode.type === "script") return <></>;

				if (domNodeIsElement(domNode)) {
					// Check for Gravity Forms block
					if (domNode.attribs.class?.includes("gform_wrapper")) {
						// Get form ID from element attribute
						const formId = [
							...domNode.attribs.id.matchAll(/gform_wrapper_(\d*)/g),
						][0][1];

						setFormId(formId);

						if (!gravityFormQuery.data) {
							return null;
						}

						return (
							<StyledGravityForm
								form={gravityFormQuery.data}
								isLoading={gravityFormQuery.isLoading}
								showLegend={false}
								defaultValue={{
									[FormFieldTypeEnum.NAME]: {
										first: customer?.first_name || "",
										last: customer?.first_name || "",
									},
									[FormFieldTypeEnum.EMAIL]: customer?.email || "",
									[FormFieldTypeEnum.PHONE]: customer?.phone || "",
								}}
							/>
						);
					}

					// Check for Google Maps block
					if (
						Boolean(getGoogleMapBlocksFromChildren(domNode.children).length)
					) {
						const googleMapBlocks = getGoogleMapBlocksFromChildren(
							domNode.children,
						);

						if (!googleMapBlocks.length) return domNode;

						const googleMap = googleMapBlocks[0].data;
						const coords = {
							id: 'marker',
							lat: Number(getLat(googleMap)),
							lng: Number(getLng(googleMap)),
						};

						return <StyledMap center={coords} markers={[coords]} />;
					}

					return domNode;
				}

				return domNode;
			},
		};
	}, [
		customer?.email,
		customer?.first_name,
		customer?.last_name,
		customer?.phone,
		getGoogleMapBlocksFromChildren,
		gravityFormQuery.data,
	]);

	if (!document) {
		return <Page pageType={PageType.PAGE} />;
	}

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={data?.seo.title}
				description={data?.seo.metaDesc}
				keywords={data?.seo.metaKeywords}
				robots={getRobotsContent([
					data?.seo.metaRobotsNofollow,
					data?.seo.metaRobotsNoindex,
				])}
			/>
			<SupportingPageLayout data={data} loading={isLoading}>
				<SpNav
					className="nav"
					isLoading={menuItems.isLoading}
					title={parentNavOption?.label || ""}
					options={navOptions}
				/>
				<SpContent
					content={data?.content}
					isLoading={isLoading || gravityFormQuery.isLoading}
					options={options}
					safeRender
				/>
			</SupportingPageLayout>
		</Page>
	);
};

export default ContactUsPage;
