import { useSelector } from "redux/hooks";

import MiniCart from "components/cart/MiniCart/MiniCart";
import Button from "components/common/ui/Button/Button";
import IconWishlist from "components/common/ui/Icons/IconWishlist/IconWishlist";
import Link from "components/common/ui/Link/Link";
import SearchModal from "components/common/ui/SearchModal/SearchModal";
import UserModal from "components/UserModal/UserModal";
import styled from "styled-components";

import PathTranslationKey from "utilities/paths";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";
import Price, { FormatPriceToCurrency } from "components/common/ui/Price/Price";
import { useTranslation } from "react-i18next";
import { Breakpoint } from "ts/enums";

const StyledDiv = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;

	.material-symbols-outlined {
		font-size: 32px;
	}

	.exchange-rate {
		position: absolute;
		right: ${remCalc(28)};
		bottom: ${remCalc(-14)};
		color: ${dark};
		font-family: ${Roboto};
		font-size: ${remCalc(12)};
		letter-spacing: ${remCalc(0.5)};

		@media screen and (min-width: 400px) {
			position: unset;
			margin: auto 0;
		}

		span {
			font-weight: 700;
		}
	}

	ul {
		display: inline;
		padding: 0;
		margin: auto 0;

		@media ${aboveMedium} {
			display: flex;
		}

		li {
			margin: 0 4px;
			display: inline-flex;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: ${Breakpoint.LARGE}px) {
				margin: 0;
			}

			button.btn-icon-only {
				svg {
					scale: 0.75;

					@media ${aboveMedium} {
						scale: 0.9;
					}
				}
			}
		}
	}
`;

const StyledLink = styled(Link)`
	display: flex;
`;

const WishlistButton = () => {
	const { t } = useTranslation();

	return (
		<StyledLink to={`${t(PathTranslationKey.ACCOUNT_WISHLIST)}`}>
			<Button icon={<IconWishlist />} />
		</StyledLink>
	);
};

const NavUser = () => {
	// Check if alternative currency is Kuna (Croatia store only)
	const secondCurrency = useSelector((state) =>
		state.bcStore.currencies.find((currency) => currency.name === "Kuna"),
	);

	return (
		<StyledDiv>
			{secondCurrency && (
				<p className="exchange-rate">
					<Price price={1} />
					{" = "}
					<span>
						{FormatPriceToCurrency(secondCurrency.exchangeRate, secondCurrency)}
					</span>
				</p>
			)}

			<ul className="nav-buttons">
				<li>
					<SearchModal />
				</li>
				<li>
					<WishlistButton />
				</li>
				<li>
					<UserModal />
				</li>
				<li>
					<MiniCart />
				</li>
			</ul>
		</StyledDiv>
	);
};

export default NavUser;
