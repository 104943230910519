import Aside from "components/common/ui/Aside/Aside";
import TextButton from "components/common/ui/Button/TextButton";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, white } from "utilities/variables/colors";

export const StyledTextButton = styled(TextButton)`
	text-transform: capitalize;
`;

export const StyledAside = styled(Aside)`
	max-width: ${remCalc(408)};
	footer {
		background-color: unset;
		border: unset;
	}
`;

export const FooterContent = styled.div`
	position: relative;
	padding: ${remCalc(16)};
`;

export const OverlayHelperText = styled.div`
	position: absolute;
	top: 0;
	transform: translateY(-100%);
	width: 100%;
	padding: 0 ${remCalc(20)};

	.inner-content {
		padding: ${remCalc(12)} ${remCalc(16)};
		background-color: ${dark};
		opacity: 0.8;
		color: ${white};
	}
`;

export const Content = styled.div`
	position: relative;
	padding: ${remCalc(24)} ${remCalc(20)};
	display: flex;
	flex-direction: column;
	height: 100%;

	.product-name {
		margin-bottom: ${remCalc(8)};
	}

	.helper-text {
		margin-bottom: ${remCalc(16)};
	}

	.disclaimer {
		display: flex;
		margin-bottom: ${remCalc(8)};
		i {
			margin-right: ${remCalc(8)};
		}
	}
`;

export const ImageWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	margin: auto 0;

	img {
		width: 70%;
	}
`;
