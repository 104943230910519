import { Geolocation, Position } from "@capacitor/geolocation";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";

const useGeolocation = () => {
	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

	Geocode.setApiKey(apiKey);

	const [position, setPosition] = useState<Position>();

	const [posibleAdressses, setPosibleAddresses] = useState<any[] | undefined>();

	useEffect(() => {
		Geolocation.getCurrentPosition().then(async (position: Position) => {
			await Geocode.fromLatLng(
				position.coords.latitude.toString(),
				position.coords.longitude.toString(),
			).then((res) => {
				setPosibleAddresses(res.results);
			});

			setPosition(position);
		});
	}, []);

	return {
		position,
		posibleAdressses,
		address: posibleAdressses?.length ? posibleAdressses[0] : undefined,
	};
};

export default useGeolocation;
