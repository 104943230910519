import React, { Fragment } from "react";


import customerApi from "app/api/customer/customerApi";

import { useDispatch, useSelector } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";


import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import useKlaviyo from "hooks/useKlaviyo";
import ProfileForm from "./ProfileForm/ProfileForm";

const AccountProfile = () => {
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.auth.customer);

	const { klaviyoProfile } = useKlaviyo();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Profile"));
	}, [dispatch]);

	const [getMeTrigger] = customerApi.useLazyGetMeQuery();


	return (
		<Fragment>
			{!customer && <LoadingSpinner />}

			{customer && (
				<ProfileForm
					profile={customer as any}
					klaviyoProfile={klaviyoProfile}
					onSubmit={() => getMeTrigger()}
				/>
			)}
		</Fragment>
	);
};

export default AccountProfile;
