import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	carousels: [
		{
			title: "You Might Also Like",
			recommendedProducts: [
				"5953001-2",
				"4225201-2",
				"5953031-2-36",
				"12321435435-2",
				"1234566-2",
				"1257863-2",
				"6635413-2",
				"6635412-2",
			],
		},
	],
};

export const productRecSlice = createSlice({
	name: "productRec",
	initialState,
	reducers: {
		setCarousels: (state, action) => {
			state.carousels = action.payload;
		},
		resetCarousels: (state) => {
			state = initialState;
		},
	},
});

export const { setCarousels, resetCarousels } = productRecSlice.actions;

export default productRecSlice.reducer;
