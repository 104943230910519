import { createSlice } from "@reduxjs/toolkit";
import { CustomerIdentifierType } from "hooks/auth/useLogin";
import Cookies from "js-cookie";
import { CustomerData } from "types/Customer";
import { COOKIE_CUSTOMER_IDENTIFIER } from "utilities/consts";

const getIdentifierFromCookie = () => {
	const cookie = Cookies.get(COOKIE_CUSTOMER_IDENTIFIER);

	if (cookie) {
		return JSON.parse(cookie) as CustomerIdentifierType;
	}

	return undefined;
};

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		loginRedirect: undefined,
		identifier: getIdentifierFromCookie(),
		customer: undefined as undefined | CustomerData,
		state: undefined,
	},
	reducers: {
		setLoginRedirect: (state, action) => {
			state.loginRedirect = action.payload;
		},
		setCustomer: (state, action) => {
			let customer = action.payload;
			let loyaltyCardDetails = {
				hasLoyaltyCard: false,
				loyaltyCardBarcode: 0,
			};
			customer?.attributes.forEach((attribute: any) => {
				if (
					attribute.attribute_name === "Loyalty Card Barcode" &&
					attribute.attribute_value
				) {
					loyaltyCardDetails.hasLoyaltyCard = true;
					loyaltyCardDetails.loyaltyCardBarcode = parseInt(
						attribute.attribute_value,
					);
				}
			});

			customer = {
				...customer,
				...loyaltyCardDetails,
			};

			state.customer = customer;
		},
		updateCustomer: (state, action) => {
			state.customer = {
				...state.customer,
				...action.payload,
			};
		},
		setIdentifier: (state, action) => {
			state.identifier = action.payload;
		},
		logout: (state) => {
			state.identifier = undefined;
			state.customer = undefined;

			Cookies.remove(COOKIE_CUSTOMER_IDENTIFIER);
		},
		setState: (state, action) => {
			state.state = action.payload;
		},
	},
});

export const {
	setLoginRedirect,
	setCustomer,
	updateCustomer,
	setIdentifier,
	logout,
	setState,
} = authSlice.actions;

export default authSlice.reducer;
