import { useLazyGetCategoryProductsQuery } from "app/api/category/categoryRestApi";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Category, Product } from "ts/types";

export interface PaginationProps {
	count: number;
	current_page: number;
	links: {
		next: string;
		previous: string;
		current: string;
	}
	per_page: number;
	too_many: boolean;
	total: number;
	total_pages: number;
}

const useCategoryPagination = (
	category: Category | undefined,
	pageSize?: number,
) => {
	const [searchParams] = useSearchParams();

	const sort = searchParams.get('sort')?.split('|').shift() || 'date_modified'

	const hasDirection =  (searchParams.get('sort') ?? '')?.split('|').length > 1 ?? false;
	const direction = hasDirection ? (
		searchParams.get('sort')?.split('|')[(searchParams.get('sort') ?? '')?.split('|').length - 1] || 'desc'
	) : 'desc'

	const [trigger] = useLazyGetCategoryProductsQuery();
	const [activePage, setActivePage] = useState<string | number>(searchParams.get('page') || 1);

	const [pagination, setPagination] = useState<{
		pagination: PaginationProps,
	}>();
	const [products, setProducts] = useState<Product[]>([]);

	const [loading, setLoading] = useState(false);
	const [uninitialized, setUninitialized] = useState(true);

	useEffect(() => {
		setUninitialized(false);
		setLoading(true);

		if (category?.entityId) {
			trigger({id: category.entityId, limit: pageSize, activePage, sort, direction })
			.unwrap()
			.then((res) => {
				setPagination({ ...res?.pagination })
				setProducts(res?.products);
			})
			.catch(() => setLoading(false))
			.finally(() => setLoading(false));
		}
	}, [category]);

	useEffect(() => {
		if (activePage && category?.entityId) {
			setLoading(true);

			trigger({id: category.entityId, limit: pageSize, activePage, sort, direction})
			.unwrap()
			.then((res) => {
				setPagination({ ...res?.pagination })
				setProducts(res?.products);
			})
			.catch(() => {
				setUninitialized(false);
				setLoading(false);
			})
			.finally(() => {
				setUninitialized(false);
				setLoading(false);
			});
		}
	}, [activePage, searchParams]);

	const getPage = (page?: string | number) => {
		if (page) {
			setActivePage(page);
		}
	};

	const uniqueFilters: {
		label: string;
		options: {
			label: string;
			count: number;
		}[];
	}[] = [];

	products.forEach(
		(product) => {
			const {
				variants
			} = product

			variants?.forEach(
				(variant) => {
					const doesUniqueFilterExist = uniqueFilters.find(
						(filter) => filter.label === variant.option.displayName
					)

					if (!doesUniqueFilterExist) {
						return uniqueFilters.push({
							label: variant.option.displayName,
							options: [
								{
									label: variant.option.label,
									count: 1
								}
							],
						})
					}

					const uniqueFilterIndex = uniqueFilters.findIndex(
						(filter) => filter.label === variant.option.displayName
					)

					const uniqueFilterOptionsIndex = uniqueFilters[uniqueFilterIndex].options.findIndex(
						(option) => option.label === variant.option.label
					)

					if (uniqueFilterOptionsIndex !== -1) {
						return uniqueFilters[uniqueFilterIndex].options[uniqueFilterOptionsIndex].count += 1
					}

					uniqueFilters[uniqueFilterIndex].options.push({
						label: variant.option.label,
						count: 1,
					})
				}
			)
		}
	)

	return {
		activePage,
		uninitialized,
		loading,
		meta: pagination,
		products,
		getPage,
	};
};

export default useCategoryPagination;
