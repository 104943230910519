import TextButton from "components/common/ui/Button/TextButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "ts/types";
import PdpFindInStoreModal from "./Modal/PdpdFindInStoreModal";

interface Props {
	product: Product;
}

const PdpFindInStore: React.FC<Props> = ({ product }) => {
	const { t, ready } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<TextButton onClick={() => setIsOpen(true)}>
				{t("findInStore.title")}
			</TextButton>
			{ready && (
				<PdpFindInStoreModal
					isOpen={isOpen}
					handleClose={handleClose}
					product={product}
				/>
			)}
		</>
	);
};

export default PdpFindInStore;
