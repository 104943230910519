import Scrollable from "components/common/ui/Scrollable/Scrollable";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { useEffect, useState } from "react";
import { ProductVariant } from "ts/types";
import Variant from "./ProductVariant/ProductVariant";
import { VariantsList, VariantsWrapper } from "./ProductVariants.Styled";

interface ProductVariantsProps {
	variants: ProductVariant[];
	onChange?: (variant: ProductVariant) => void;
	defaultValue?: ProductVariant;
	className?: string;
	outOfStockSelectable?: boolean;
	loading?: boolean;
	controlled?: boolean;
}

const ProductVariants: React.FC<ProductVariantsProps> = ({
	variants = [],
	onChange,
	defaultValue = undefined,
	className = "",
	outOfStockSelectable = false,
	loading = false,
	controlled = false,
}) => {
	const [selectedVariant, setSelectedVariant] = useState<ProductVariant>(
		defaultValue as ProductVariant,
	);

	useEffect(() => {
		if (controlled && selectedVariant !== defaultValue) {
			setSelectedVariant(defaultValue as ProductVariant);
		}
	}, [defaultValue]);

	const onVariantClick = (variant: ProductVariant) => {
		!controlled && setSelectedVariant(variant);

		if (onChange) {
			onChange(variant);
		}
	};

	if (loading) {
		return (
			<VariantsWrapper className={`ProductVariants loading ${className}`}>
				<Scrollable>
					<VariantsList className="VariantsList">
						{[...Array(5)].map((_, index) => {
							return (
								<li key={`variant-skeleton-${index}`}>
									<Skeleton width={40} height={40} />
								</li>
							);
						})}
					</VariantsList>
				</Scrollable>
			</VariantsWrapper>
		);
	}

	return (
		<VariantsWrapper className={`ProductVariants ${className}`}>
			<Scrollable>
				<VariantsList className="VariantsList">
					{variants
						.filter((variant) => variant.option !== undefined)
						.map((variant) => {
							let value = variant.option.label;

							if (
								variant.option.displayName === "771" ||
								variant.option.displayName === "641"
							) {
								value = String(Number(variant.option.label) / 10);
							}

							return (
								<Variant
									key={variant.sku}
									value={value}
									active={selectedVariant === variant}
									onClick={() => onVariantClick(variant)}
									available={
										outOfStockSelectable
											? true
											: variant.isPurchasable && variant.inventory.isInStock
									}
								/>
							);
						})}
				</VariantsList>
			</Scrollable>
		</VariantsWrapper>
	);
};

export default ProductVariants;
