import React from "react";

import { Controller, UseFormReturn } from "react-hook-form";
import countryList from "react-select-country-list";

import { InputText } from "components/common/form/Input/Input";
import InputSelect from "components/common/form/InputSelect/InputSelect";

import { SelectOption } from "ts/types";

import {
	AddressFormSectionContainer,
	AddressWrapper,
} from "./AddressSection.Styled";
import { useTranslation } from "react-i18next";
import { normalizePlace } from "utilities/autocompleteUtil";
import GoogleAutocomplete from "components/common/Google/GoogleAutocomplete";
import { useSelector } from "redux/hooks";
import Label from "../Label/Label";
import ErrorMessage from "../Input/InputError/InputError";

type AddressSchema = {
	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	state_or_province: string;
	country_code: string;
};

interface Props {
	form: UseFormReturn<AddressSchema & any>;
}

const AddressSection = ({ form }: Props) => {
	const {
		register,
		setValue,
		formState: { errors },
		control,
	} = form;

	const { t } = useTranslation();

	const locale = useSelector((state) => state.location.selectedLocale) || "en";
	const storeCode = useSelector((state) => state.bcStore.store?.code) || "en";

	const countryOptions = React.useMemo(() => {
		let countries = countryList().getData();

		if (storeCode !== "en") {
			countries = countries.filter(
				(option) => option.value.toLowerCase() === storeCode.toLowerCase(),
			);
		}

		return countries.map((op) => {
			return {
				label: op.label,
				value: op.value,
			} as SelectOption;
		});
	}, [storeCode]);

	const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
		const address1 = form.getValues().address1;

		// clear the previous address data in form.
		// this prevents a bug with the address input not clearing after the first use
		const emptyData = normalizePlace();
		Object.keys(emptyData).forEach((key) => {
			if (key in emptyData) {
				form.resetField(key);
			}
		});

		const data = normalizePlace(place);
		Object.entries(data).forEach(([key, value]) => {
			if (key in data) {
				if (key === "address1" && !value) {
					setValue(key, address1);
				} else {
					setValue(key, value);
				}
			}
		});
	};

	return (
		<AddressFormSectionContainer className="address-section form__section">
			<h4 className="form__heading">{t("address_details")}</h4>
			<div className="form__fields">
				<div className="form__row">
					<Controller
						control={control}
						name="address1"
						render={({ field, fieldState: { error } }) => (
							<AddressWrapper>
								<Label required htmlFor={field.name}>
									{t("form.registerAddressLine1")}
								</Label>
								<GoogleAutocomplete
									apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
									googleApi={{
										map: document.createElement("div"),
										api: (window as any).google?.maps,
									}}
									isAutocompleteEnabled
									onSelect={handlePlaceSelected}
									language={locale}
								/>
								{error?.message && <ErrorMessage message={error.message} />}
							</AddressWrapper>
						)}
					/>
					<InputText
						label={
							<p className="form__field-label">
								{t("form.registerAddressLine2")}
								<span className="hint">{`(${t("common.optional_text")})`}</span>
							</p>
						}
						{...register("address2")}
						errorMsg={errors.address2?.message}
					/>
				</div>
				<div className="form__row">
					<InputText
						label={t("form.registerTown") as string}
						required
						{...register("city")}
						errorMsg={
							(errors.city?.message && t(errors.city?.message.toString())) || ""
						}
					/>
					<Controller
						control={control}
						name="country_code"
						render={({ field }) => (
							<InputSelect
								{...field}
								value={
									countryOptions.length === 1
										? countryOptions[0].value
										: field.value
								}
								label={t("form.registerCountry") as string}
								required
								options={countryOptions}
								isSearchable
								errorMsg={
									(errors.country_code?.message &&
										t(errors.country_code?.message.toString())) ||
									""
								}
							/>
						)}
					/>
				</div>
				<div className="form__row">
					<InputText
						label={t("form.registerZipCode") as string}
						required
						{...register("postal_code")}
						errorMsg={
							(errors.postal_code?.message &&
								t(errors.postal_code?.message.toString())) ||
							""
						}
					/>
				</div>
			</div>
		</AddressFormSectionContainer>
	);
};

export default AddressSection;
