import { useLoginMutation } from "app/api/authApi";
import BaseForm from "components/common/form/Form/Form";
import { InputEmail, InputPassword } from "components/common/form/Input/Input";
import Alert, { AlertType } from "components/common/ui/Alert/Alert";
import Button from "components/common/ui/Button/Button";
import useLogin from "hooks/auth/useLogin";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "redux/hooks";
import { SigninFormResolver, SigninFormScheme } from "./SigninForm.Schema";
import { FormStyle } from "./SigninForm.Styled";
import { useTranslation } from "react-i18next";

const SigninForm: React.FC<{
	large?: boolean;
}> = ({ large = false }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SigninFormScheme>({
		resolver: SigninFormResolver,
	});
	const { t } = useTranslation();
	const [loginApi, loginResult] = useLoginMutation();
	const login = useLogin();

	const [error, setError] = useState<string[]>([]);

	const onSubmit: SubmitHandler<SigninFormScheme> = (formData) => {
		setError([]);

		loginApi(formData)
			.unwrap()
			.then(({ data }) => {
				login(data);
			})
			.catch(({ data }) => {
				setError([t(data.error.message)]);
			});
	};

	useEffect(() => {}, [loginResult]);

	return (
		<Fragment>
			{error && (
				<Alert type={AlertType.ERROR} title={t("error").toString()} messages={error} />
			)}
			<BaseForm styles={FormStyle} onSubmit={handleSubmit(onSubmit)}>
				<InputEmail
					label={t("form.signInEmail") as string}
					required
					{...register("email")}
					errorMsg={
						errors.email?.message && (t(errors.email?.message) as string)
					}
				/>
				<InputPassword
					label={t("form.signInPassword") as string}
					required
					{...register("password")}
					errorMsg={
						errors.password?.message && (t(errors.password?.message) as string)
					}
				/>
				<Button
					variant="primary_01"
					type="submit"
					fullWidth={!large}
					loading={loginResult.isLoading}
					disabled={loginResult.isLoading}
				>
					{t("form.signInSignIn")}
				</Button>
			</BaseForm>
		</Fragment>
	);
};

export default SigninForm;
