import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAttachCardNumberToCustomerMutation } from "app/api/customer/loyaltyApi";
import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { InputText } from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import useCalculateLoyaltyTier from "hooks/loyalty/useCalculateLoyaltyTier";
import jsbarcode from "jsbarcode";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
	StyledBarcodeWrapper,
	StyledLoyaltyFormWrapper,
	StyledLoyaltyInformationWrapper,
	StyledLoyaltyProgressWrapper,
	StyledLoyaltyWrapper,
} from "./loyalty.Styled";
import {
	loyaltyFormDefaultData,
	LoyaltyFormSchema,
	LoyaltyFormType,
} from "./LoyaltyForm.Schema";

import Price from "components/common/ui/Price/Price";
import * as DOMPurify from "dompurify";
import useJoinLoyaltyForm from "hooks/useJoinLoyaltyForm";

const AccountLoyalty = () => {
	const dispatch = useDispatch();
	const barcodeRef = useRef<any>();

	const [joinApplicationSate, setJoinApplicationSate] = useState<
		"unsent" | "success" | "fail"
	>("unsent");

	const [attachCardNumber, res] = useAttachCardNumberToCustomerMutation<any>();
	const submitJoinLoyaltyForm = useJoinLoyaltyForm();

	const { customer } = useSelector((state) => state.auth);

	const form = useForm({
		defaultValues: loyaltyFormDefaultData,
		resolver: yupResolver(LoyaltyFormSchema),
	});

	const handleSubmitJoinForm = async () => {
		const payload: any = {
			email: customer?.email,
			name: {
				first: customer?.first_name,
				last: customer?.last_name,
			},
		};

		const res = await submitJoinLoyaltyForm(payload);
		setJoinApplicationSate(res.data.submitGfForm.errors ? "fail" : "success");
	};

	const {
		formState: { errors },
		handleSubmit,
		register,
	} = form;

	const {
		loyalty_card_barcode,
		category,
		tier,
		progress,
		isLoading,
	} = useCalculateLoyaltyTier();

	const [getApplicationData, { data }] =
		wordPressApi.useLazyGetLoyaltyCardApplicationDataQuery();

	const { t } = useTranslation();

	const onSubmit = (data: LoyaltyFormType) => {
		attachCardNumber(data.loyaltyCardNumber) as any;
	};

	useEffect(() => {
		if (isLoading || !customer?.hasLoyaltyCard) return;

		const barcode = jsbarcode(barcodeRef?.current);
		barcode.init();
	}, [barcodeRef, isLoading]);

	useEffect(() => {
		if (customer?.hasLoyaltyCard) return;

		getApplicationData();
	}, [customer]);

	useEffect(() => {
		dispatch(setAccountActiveLink("Loyalty Program"));
	}, []);

	const { sanitize }: any = DOMPurify;

	return (
		<StyledLoyaltyWrapper>
			{customer?.hasLoyaltyCard ? (
				<>
					<StyledBarcodeWrapper className="StyledBarcodeWrapper">
						{isLoading ? (
							<div className="barcode-wrapper">
								<Skeleton height={140} />
							</div>
						) : (
							<div className="barcode-wrapper">
								<svg
									className="barcode"
									ref={barcodeRef}
									jsbarcode-displayvalue="false"
									jsbarcode-format="CODE128"
									jsbarcode-value={loyalty_card_barcode}
									jsbarcode-textmargin="0"
									jsbarcode-fontoptions="bold"
								></svg>
							</div>
						)}
						<div className="content-wrapper">
							<h3>{t("account.yourLoyaltyCard")}</h3>
							<p className="card-number">
								{t("no.")} {loyalty_card_barcode|| <Skeleton width={"15ch"} />}
							</p>
							<p>{t("scanInStore")}</p>
						</div>
					</StyledBarcodeWrapper>

					<p className="current-tier">
						<span>{t("yourCurrentTier")}:</span> <br />
						{isLoading ? (
							<Skeleton width={"40ch"} />
						) : (
							<>
								{category} - {tier?.current?.discount}
								{t("%offall")}
							</>
						)}
					</p>

					<StyledLoyaltyProgressWrapper progress={progress || 0}>
						<div className="container">
							<h3>
								{t("nextPurchaseTier")}:
								<br />
								{isLoading ? (
									<Skeleton width={"40ch"} />
								) : (
									<span>
										{tier?.next.name === "top tier"
											? t("account.loyaltyTopTier")
											: `${tier?.next.name} - ${tier?.next.discount}${t(
													"%offall",
											  )}`}
									</span>
								)}
							</h3>

							<div className="progressBarWrapper">
								<span className="progress-carat"></span>
								<div className="progress-bar-outer">
									<div className="progress-bar-inner"></div>
								</div>
								<div className="progress-bar-labels">
									<span>
										{tier?.current.thresholds.start >= 0 ? (
											<Price price={tier?.current.thresholds.start} />
										) : (
											<Skeleton width={"6ch"} />
										)}
									</span>
									<span>
										{tier?.current.thresholds.finish ? (
											<Price price={tier?.current.thresholds.finish} />
										) : (
											<Skeleton width={"6ch"} />
										)}
									</span>
								</div>
							</div>
						</div>
					</StyledLoyaltyProgressWrapper>
				</>
			) : (
				<StyledLoyaltyInformationWrapper>
					<h3>{data?.applyForCardTitle || <Skeleton width={"25ch"} />}</h3>

					{data ? (
						<div
							className="wordpress-content-wrapper"
							dangerouslySetInnerHTML={{
								__html: sanitize(data?.applyForCardText),
							}}
						/>
					) : (
						<div style={{ marginBottom: "1rem" }}>
							<Skeleton count={8} style={{ marginBottom: ".25rem" }} />
						</div>
					)}

					<div className="join-button-wrapper">
						{data ? (
							<>
								{joinApplicationSate === "success" ? (
									<div className="success-message">
										<h3>{t("account.loyaltyThankYouHeading")}</h3>
										<p>{t("account.loyaltyThankYouBody")}</p>
									</div>
								) : (
									<>
										<Button onClick={handleSubmitJoinForm} variant="primary_01">
											{data?.buttonText}
										</Button>
										{joinApplicationSate === "fail" && (
											<span>{t("account.loyaltyError")}</span>
										)}
									</>
								)}
							</>
						) : (
							<Skeleton
								width={130}
								height={40}
								style={{ marginInline: "auto", display: "block" }}
							/>
						)}
					</div>

					<h3>{data?.alreadyHaveACardTitle || <Skeleton width={"25ch"} />}</h3>

					{data ? (
						<div
							className="wordpress-content-wrapper"
							dangerouslySetInnerHTML={{
								__html: sanitize(data?.alreadyHaveACardText),
							}}
						/>
					) : (
						<Skeleton count={10} />
					)}

					{data && (
						<StyledLoyaltyFormWrapper
							onSubmit={handleSubmit(onSubmit)}
							showLegend={false}
						>
							{res.isError ? (
								<p className="error-message">{t(res?.error?.statusText)}</p>
							) : (
								""
							)}
							<InputText
								{...register("loyaltyCardNumber")}
								errorMsg={
									errors.loyaltyCardNumber?.message &&
									(t(errors.loyaltyCardNumber.message) as string)
								}
								label={t("account.loyaltyCard") as string}
								required
							/>

							<Button variant="primary_01" type="submit">
								{t("account.loyaltySubmitButton")}
							</Button>
						</StyledLoyaltyFormWrapper>
					)}
				</StyledLoyaltyInformationWrapper>
			)}
		</StyledLoyaltyWrapper>
	);
};

export default AccountLoyalty;
