import React from "react";

import { Portal } from "react-portal";
import { useLocation } from "react-router";

import useOutsideClick from "hooks/useOutsideClick";
import { useSelector } from "redux/hooks";

import Button from "components/common/ui/Button/Button";
import IconProfile from "components/common/ui/Icons/IconProfile/IconProfile";
import Signin from "components/customer/Signin/Signin";
import { StyledWrapper } from "./UserModal.Styled";
import UserContent from "./UserModalContent/UserContent/UserContent";

const UserModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const location = useLocation();

  React.useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);
  };

  const dataTarget = "modal_user";

  return (
    <>
      <Button icon={<IconProfile />} data-target={dataTarget} onClick={handleButtonClick} />
      {isOpen && (
        <Portal node={document && document.getElementById("portal__modal")}>
        <UserModalOverlay
          id={dataTarget}
          isOpen={isOpen}
          handleClose={handleClose}
        />
        </Portal>
      )}
    </>
  );
};

const UserModalOverlay = ({
  id,
  isOpen,
  handleClose,
}: {
  id: string,
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { identifier } = useSelector((state) => state.auth);
  const ref = useOutsideClick(handleClose);

  return (
    <StyledWrapper id={id} ref={ref} isOpen={isOpen}>
      {identifier ? <UserContent /> : <Signin />}
    </StyledWrapper>
  );
};

export default UserModal;
