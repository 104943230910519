import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey } from "utilities/variables/colors";

export const StyledPaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0;

  ul {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 18px;
    list-style: none;

    li {
      position: relative;
      font-size: ${remCalc(14)};
      text-align: center;
      font-weight: 700;
      margin: 0 ${remCalc(6)};

      a {
        color: ${dark};
        text-decoration: none;

        &:hover {
          color: ${darkGrey};

          &::after {
            border-color: ${darkGrey};
          }
        }
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .active {
    &:hover {
      a {
        color: ${darkGrey};
      }

      &::after {
        border-color: ${darkGrey};
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: -25%;
      content: "";
      border-bottom: solid 2px black;
      width: 150%;
      height: 100%;
    }
  }

  .Pagination__button-wrapper {
    width: 16px;
    height: 100%;

    &:hover {
    }
  }
`;
