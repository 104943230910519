import { IonAccordionGroup, IonItem } from "@ionic/react";
import {
	filterWashingInstructions,
	washingInstructionsValid,
} from "app/utils/bigcommerceProductUtil";
import WashingInstructions from "components/WashingInstructions/WashingInstructions";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import { useTranslation } from "react-i18next";
import { ProductCustomField } from "ts/types";
import {
	StyledAccordion,
	StyledContent,
	StyledLabel,
	StyledLi,
	StyledUl,
} from "./PdpDescription.Styled";
import PdpMarkdown from "./PdpMarkdown/PdpMarkdown";

type Props = {
	loading: boolean;
	category?: string;
	description: string;
	customFields: ProductCustomField[];
	sku: string;
};

const PdpDescription = ({
	loading,
	category,
	description,
	customFields,
	sku,
	...props
}: Props) => {
	const { t } = useTranslation();

	const washingInstructionsField = filterWashingInstructions(customFields);

	if (washingInstructionsField) {
		//remove washing instructions from custom fields
		customFields = customFields.filter(
			(field) => field.name !== washingInstructionsField.name,
		);
	}

	if (loading) {
		return (
			<Skeleton width={"100%"} height={30} style={{ marginTop: "40px" }} />
		);
	}

	return (
		<IonAccordionGroup value={["details"]} {...props}>
			<StyledAccordion value="details">
				<IonItem slot="header">
					<StyledLabel>{t("details")}</StyledLabel>
				</IonItem>
				<StyledContent slot="content">
					<PdpMarkdown>{description}</PdpMarkdown>
					<StyledUl>
						{sku && (
							<StyledLi>
								<Typography>
									<span className="label">{`${t("pdp.sku")}`}:</span> {sku}
								</Typography>
							</StyledLi>
						)}
						{category && (
							<StyledLi>
								<Typography>
									<span className="label">{`${t("pdp.category")}`}:</span>{" "}
									{category}
								</Typography>
							</StyledLi>
						)}
						{customFields.map((field) => {
							return (
								<StyledLi key={field.name}>
									<Typography>
										<span className="label">
											{`${t(`pdp.${field.name}`)}`}:
										</span>{" "}
										{field.value}
									</Typography>
								</StyledLi>
							);
						})}
						{washingInstructionsField &&
							washingInstructionsValid(washingInstructionsField) && (
								<StyledLi>
									<Typography>
										<span className="label">
											{`${t(`pdp.${washingInstructionsField.name}`)}`}:
										</span>
									</Typography>
									<WashingInstructions field={washingInstructionsField} />
								</StyledLi>
							)}
					</StyledUl>
				</StyledContent>
			</StyledAccordion>
		</IonAccordionGroup>
	);
};

export default PdpDescription;
