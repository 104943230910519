import uuid from 'react-uuid';
import { PageType } from 'ts/enums';

import { useLazyGetCompanyPageQuery } from 'app/api/company/CompanyPageApi';
import Page from 'components/common/ui/Page/Page';
import getRobotsContent from 'utilities/getRobotsContent';

import ImageTextGrid from 'components/common/layouts/imageTextGrid/ImageTextGrid';
import ImageBanner from 'components/common/ui/ImageBanner/ImageBanner';
import SEO from 'components/common/ui/SEO/SEO';
import Skeleton from 'components/common/ui/Skeleton/Skeleton';

import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useSelector } from 'redux/hooks';
import { StyledAwardsPageWrapper } from './Awards.Styled';
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from './CompanyPages.Styled';
import { WordpressTemplateEnum } from 'app/wordpress/components/WordpressTemplates';
import * as DOMPurify from "dompurify";

const Awards = () => {

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
			const template = wordpressTemplates.find((template) =>
				template.template === WordpressTemplateEnum.AwardsAndPrizes,
			);
			if (template) {
				trigger({
					pageId: template.id,
					pageType: "awards",
				});
			}
	}, [ wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const imageData = [
		{
			image: {
				desktopImage: pageData?.pageData?.featuredImage?.node?.sourceUrl,
				mobileImage: pageData?.pageData?.featuredImage?.node?.sourceUrl,
				alt: pageData?.pageData?.featuredImage?.node?.altText,
			},
			ctaLink: '',
			ctaText: '',
		},
	];

	const { sanitize }: any = DOMPurify;

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					pageData?.pageData?.seo?.metaRobotsNoindex,
				])}
			/>
			<StyledAwardsPageWrapper className='StyledAwardsPageWrapper'>
				<StyledMainImageBanner className='main-image-banner'>
					{loading ? (
						<Skeleton width={'100%'} />
					) : (
						<ImageBanner
							orientation='landscape'
							images={imageData}
							showButton={false}
							showText={true}
							imageText={pageData?.pageData?.title}
							isLoading={loading}
						/>
					)}
				</StyledMainImageBanner>

				{loading ? (
					<Skeleton className='skeleton-content' />
				) : (
					<div
						className='awards-content'
						dangerouslySetInnerHTML={{ __html: sanitize(pageData?.pageData?.content) }}
					></div>
				)}

				<div className='text-image-grid'>
					{loading ? (
						<>
							<Skeleton className='skeleton-grid' />
							<Skeleton className='skeleton-grid' />
							<Skeleton className='skeleton-grid' />
							<Skeleton className='skeleton-grid' />
						</>
					) : (
						pageData?.pageData?.awards?.awardprize.map((content: any) => (
							<ImageTextGrid
								isLoading={false}
								image={content.images}
								text={content.shortDescription}
								title={content.title}
								urlLink={content?.link?.url}
								key={uuid()}
							/>
						))
					)}
				</div>

				{pageData?.pageData?.awards.additionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation='landscape'
						images={[
							{
								image: {
									desktopImage: link?.images.desktopImage.sourceUrl,
									mobileImage:
										link?.images.mobileImage?.sourceUrl ||
										link?.images.desktopImage.sourceUrl,
									alt: link?.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.link?.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledAwardsPageWrapper>
		</Page>
	);
};

export default Awards;
