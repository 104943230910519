import uuid from "react-uuid";

const segmentifyGetQueryFromPrompt = (args:any) => {
    return JSON.stringify([
        {
            name: "SEARCH",
            userId: `USER_${sessionStorage.getItem("userId") || uuid()}`,
            sessionId: `SESSION_${args.sessionId || "unknown"}`,
            lang: String(args.lang).toUpperCase(),
            currency: String(args.currencyCode).toUpperCase(),
            testMode: false,
            query: args.search,
            type: "instant",
        },
    ]);
};

export default segmentifyGetQueryFromPrompt;
