import { config } from "app/bigcommerce/bcStoreConfig";

export const getLanguageSelectOption = () => {
	const storeCodes = Object.keys(config.stores);
	return Object.values(config.stores).map((store, index) => {
		return {
			value: storeCodes[index],
			label: store.name === config.stores.en.name ? "Europe" : store.name,
		};
	});
};

export const getStoreLangCodes = () => {
	return Object.keys(config.stores);
};

export const getGlobalStore = () => {
	return config.stores.en;
};

export const getStoreCodeFromCountryCode = (countryCode) => {
	const storeCodes = Object.keys(config.stores);
	let storeCode = "en";

	if (countryCode) {
		storeCode = storeCodes.find((code) => code === countryCode) || "en";
	}

	return storeCode;
};

export const isValidCountyCodeForStore = (countryCode) => {
	const storeCodes = Object.keys(config.stores);
	if (countryCode) {
		return storeCodes.find((code) => code === countryCode) != null;
	}
};

export const getStoreForCode = (countryCode) => {
	const storeCodes = Object.keys(config.stores);
	const storeCode = getStoreCodeFromCountryCode(countryCode);

	const store = Object.values(config.stores)[storeCodes.indexOf(storeCode)];

	return {
		code: storeCode,
		...store,
	};
};
