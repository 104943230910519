import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { aboveMedium } from "utilities/variables/media";

interface StyledProps {
	gridGap: string;
	gridColumns: string;
}

export const StyledImageBanner = styled.section<StyledProps>`
	& > div {
		@media ${aboveMedium} {
			padding: ${(props) =>
				props.gridColumns === "repeat(3, 1fr)" ? `0 ${remCalc(20)}` : ""};
			${({ gridGap, gridColumns }) => `
                display: grid;
                grid-template-columns: ${gridColumns || "repeat(3, 1fr)"};
                gap: ${gridGap};
                ${
									gridGap === "2rem" &&
									gridColumns === "repeat(2, 1fr)" &&
									"width: 90%; margin-inline: auto;"
								}
            `}
		}
	}

	.styled-image-cta-container {
		margin-bottom: ${remCalc(8)};

		@media ${aboveMedium} {
			margin-bottom: ${remCalc(16 * 2)};
		}
	}

	@media ${aboveMedium} {
		width: 100%;
		overflow: hidden;
	}
`;
