import React from "react";

import {
  StyledWrapper,
  StyledButton,
  StyledButtonsContainer,
  StyledTypography,
} from "./CountrySelection.Styled";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";

type Props = {
  options: string[];
  onChange: (countries: string[]) => void;
};

const CountrySelection = ({ options, onChange, ...props }: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<string[]>([]);

  React.useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  const handleClick = (option: string) => {
    if (selected.includes(option)) {
      return setSelected((prev) => prev.filter((i) => i !== option));
    }
    return setSelected((prev) => [...prev, option]);
  };

  return (
    <StyledWrapper {...props}>
      <StyledTypography>{t("countrySelection")}</StyledTypography>
      <StyledButtonsContainer>
        {options.map((option) => (
          <StyledButton
            key={option}
            className={selected.includes(option) ? "selected" : ""}
            onClick={() => handleClick(option)}
          >
            {t(camelCase(option))}
          </StyledButton>
        ))}
      </StyledButtonsContainer>
    </StyledWrapper>
  );
};

export default CountrySelection;
