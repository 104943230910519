import Link from 'components/common/ui/Link/Link';
import { StyledCompanyValuesItemCard } from './companyValues.Styled';
import { companyValues } from './CompanyValuesGrid';
import * as DOMPurify from "dompurify";

type Props = {
	valueData?: companyValues;
};

const CompanyValuesItem = ({ valueData }: Props) => {

	const { sanitize }: any = DOMPurify;

	return (
		<StyledCompanyValuesItemCard className='ItemCard' to={valueData?.link}>
			<img src={valueData?.icon?.sourceUrl} alt={valueData?.icon?.altText} />
			<h3>{valueData?.title}</h3>
			{valueData?.text && (
				<p dangerouslySetInnerHTML={{ __html: sanitize(valueData?.text) }} />
			)}

			{valueData?.link && valueData?.linkText && (
				<Link to={valueData?.link}>{valueData?.linkText}</Link>
			)}
		</StyledCompanyValuesItemCard>
	);
};

export default CompanyValuesItem;
