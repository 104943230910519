import Scrollbars from "react-custom-scrollbars-2";
import styled from "styled-components";

interface Props {
  flexDirection?: "row" | "column";
  autoHide?: boolean
}

const View = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
`;

const Scrollable: React.FC<{ children: any } & Props> = ({ children, ...props }) => {
  return (
    <Scrollbars autoHeight renderView={scrollProps => <View className="ScrollableView" {...scrollProps} {...props} />}>
      {children}
    </Scrollbars>
  );
};

export default Scrollable;
