import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiKey = process.env.REACT_APP_SEGMENTIFY_API_KEY || "";

const baseQuery = fetchBaseQuery({
	baseUrl: `https://gandalf-eu.segmentify.com//add/events/v1.json?apiKey=${apiKey}`,
	prepareHeaders: (headers, { getState }) => {
		headers.set("Accept", "application/json");
		headers.set("Content-Type", "application/json");
	},
});

export const segmentify = createApi({
	reducerPath: "segmentify",
	baseQuery,
	endpoints: (builder) => ({}),
});
