import Divider from "components/common/ui/Divider/Divider";
import Modal from "components/common/ui/Modal/Modal";
import Typography from "components/common/ui/Typography/Typography";
import styled, { css } from "styled-components/macro";
import { remCalc } from "utilities/styles";
import {
	accountDarkGrey,
	accountLightGrey,
	white,
} from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div<{ isOpen: boolean }>`
	display: ${(props) => !props.isOpen && "none"};
	position: absolute;
	width: 100%;
	height: 100%;
	`;

export const StyledModal = styled(Modal)`
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding: 0;
	padding-top: env(safe-area-inset-top, 0);

	.btn_modal-close {
		top: calc(${remCalc(14)} + env(safe-area-inset-top, ${remCalc(14)}));
		right: ${remCalc(14)};
	}

	.ModalBody {
		height: 100%;
		width: 100%;
	}

	@media ${aboveMedium} {
		width: 100%;
		height: 100%;
		max-width: ${remCalc(976)};
		max-height: ${remCalc(608)};

		padding: 0;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.ModalBody {
			display: flex;
		}
	}
`;

export const Content = styled.div`
	@media ${aboveMedium} {
		display: flex;
	}
	height: 100%;
	width: 100%;
`;

const sharedWrapperStyles = css`
	flex: 1;
	@media ${aboveMedium} {
		max-width: 50%;
	}

	h3 {
		font-size: ${remCalc(16)};
	}
`;

export const Title = styled(Typography)`
	font-size: ${remCalc(16)};

	&.input_title {
		padding: ${remCalc(18)} ${remCalc(16)} 0;

		@media ${aboveMedium} {
			padding: 0;
		}
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	${sharedWrapperStyles}

	@media ${aboveMedium} {
		padding-top: ${remCalc(64)};
		padding-left: ${remCalc(64)};
		padding-right: ${remCalc(32)};
	}
`;

export const BelowVariantWrapper = styled.div`
	margin: 0 ${remCalc(16)};

	.below-variants-text {
		color: ${accountDarkGrey};
		text-transform: lowercase;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	.btn_check-availabilty {
		margin-top: ${remCalc(24)};
	}

	@media ${aboveMedium} {
		margin: 0;
	}
`;

export const ResultWrapper = styled.div`
	${sharedWrapperStyles}

	background-color: ${white};
	height: 100%;
	display: flex;
	flex-direction: column;

	ion-spinner {
		margin-top: ${remCalc(16)};
	}

	button.btn_start-again {
		margin-left: auto;
	}

	@media ${aboveMedium} {
		background-color: ${accountLightGrey};
		padding-top: ${remCalc(64)};
		padding-right: ${remCalc(64)};
		padding-left: ${remCalc(32)};
	}
`;

export const StyledDivider = styled(Divider)<{
	margin?: number;
	marginTop?: number;
	marginBottom?: number;
}>`
	${({ margin }) =>
		margin &&
		css`
			margin: ${remCalc(margin)} 0;
		`}

	${({ marginTop }) =>
		marginTop &&
		css`
			margin-top: ${remCalc(marginTop)};
		`}

		${({ marginBottom }) =>
		marginBottom &&
		css`
			margin-bottom: ${remCalc(marginBottom)};
		`}
`;

export const ProductCard = styled.div`
	margin: 0 ${remCalc(16)};
	display: flex;
	gap: 16px;

	.thumbnail {
		width: ${remCalc(96)};
	}

	.details {
		display: flex;
		flex-direction: column;

		.sku {
			color: ${accountDarkGrey};
			margin-top: ${remCalc(12)};
		}

		.price {
			font-size: ${remCalc(14)};
			margin-top: auto;
		}
	}

	@media ${aboveMedium} {
		margin: 0;
	}
`;

export const VariantWrapper = styled.div`
	margin: 0 ${remCalc(16)};

	.variant-helper-text {
		margin-bottom: ${remCalc(12)};
		text-transform: lowercase;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	.VariantsList {
		margin: unset;

		li {
			margin-bottom: 2px;
		}
	}

	@media ${aboveMedium} {
		margin: 0;
	}
`;

export const ScrollContainer = styled.div`
	flex: 1;

	.select-variant-prompt {
		margin-top: ${remCalc(16)};
		text-transform: lowercase;

		&::first-letter {
			text-transform: uppercase;
		}
	}
`;

export const ResultHeadingWrapper = styled.div`
	display: flex;
	margin: 0 ${remCalc(16)};

	@media ${aboveMedium} {
		margin: 0;
	}
`;
