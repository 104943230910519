import Price from "components/common/ui/Price/Price";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import { Fragment } from "react";
import { useSelector } from "redux/hooks";
import { Price as PriceType } from "ts/types";
import {
	StyledTypographyPrice,
	StyledTypographySku,
} from "./PdpBasicInfo.Styled";

type Props = {
	loading?: boolean;
	name: string;
	price?: PriceType;
};

const PdpBasicInfo = ({ loading, name, price }: Props) => {
	// Get second currency for Croatia store
	const secondCurrency = useSelector((state) =>
		state.bcStore.currencies.find((currency) => currency.name === "Kuna"),
	);

	return (
		<Fragment>
			<Typography variant="heading" size="small">
				{loading ? <Skeleton width={250} /> : name}
			</Typography>
			<StyledTypographyPrice variant="heading">
				{loading ? (
					<Skeleton width={65} />
				) : (
					<Price
						price={price?.value || 0}
						secondCurrency={secondCurrency}
					/>
				)}
			</StyledTypographyPrice>
		</Fragment>
	);
};

export default PdpBasicInfo;
