import Skeleton from "react-loading-skeleton";
import uuid from "react-uuid";

import ImageTextSplit from "components/common/layouts/ImageTextSplit/ImageTextSplit";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";

import { useLazyGetCompanyPageQuery } from "app/api/company/CompanyPageApi";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";
import { useEffect, useState } from "react";
import { useSelector } from "redux/hooks";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "../CompanyPages.Styled";
import { SkeletonWrapper, StyledHRPageWrapper } from "./HrAdvice.Styled";

const HrAdvice = () => {
	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [loading, setLoading] = useState(true);

	const [trigger, { data: pageData }] = useLazyGetCompanyPageQuery();

	useEffect(() => {
		const template = wordpressTemplates.find(
			(template) => template.template === WordpressTemplateEnum.HrAdvice,
		);
		if (template) {
			trigger({
				pageId: template.id,
				pageType: "hr",
			});
		}
	}, [wordpressTemplates]);

	useEffect(() => {
		if (pageData) {
			setLoading(false);
		}
	}, [pageData]);

	const imageData = [
		{
			image: {
				desktopImage: pageData?.pageData.featuredImage.node.sourceUrl,
				mobileImage: pageData?.pageData.featuredImage.node.sourceUrl,
				alt: pageData?.pageData.featuredImage.node.altText,
			},
			ctaLink: "",
			ctaText: "",
		},
	];

	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					pageData?.pageData?.seo?.metaRobotsNoindex,
				])}
			/>
			<StyledHRPageWrapper className="StyledHRPageWrapper">
				<StyledMainImageBanner className="main-image-banner">
					{loading ? (
						<Skeleton width={"100%"} />
					) : (
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={true}
							imageText={pageData?.pageData.title}
							isLoading={loading}
						/>
					)}
				</StyledMainImageBanner>

				{loading ? (
					<div className="skeleton-wrapper">
						<SkeletonWrapper>
							<Skeleton containerClassName="skeleton-container" />
						</SkeletonWrapper>
						<div className="skeleton-text">
							<Skeleton height="40px" style={{ marginBottom: "16px" }} />
							<Skeleton count={13} />
						</div>
					</div>
				) : (
					pageData?.pageData.hrAdvice?.contentBlock.map((content: any) => (
						<ImageTextSplit
							isFlipped={
								content?.fieldGroupName.includes("ImageRight") ? true : false
							}
							image={content?.image}
							text={content?.text}
							title={content?.heading}
							key={uuid()}
							showButton={content?.link ? true : false}
							buttonLink={content?.link?.url}
							buttonText={content?.link?.title}
							isLoading={false}
						/>
					))
				)}

				{pageData?.pageData.hrAdvice.additionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage: link?.images.desktopImage.sourceUrl,
									mobileImage: link?.images.desktopImage.sourceUrl,
									alt: link?.images.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledHRPageWrapper>
		</Page>
	);
};

export default HrAdvice;
