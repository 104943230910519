import React from "react";
import { SearchLocationContainer } from "./SearchLocation.Styled";
import { useSelector } from "redux/hooks";
import GoogleAutocomplete from "components/common/Google/GoogleAutocomplete";
import { GoogleMapsSdk } from "components/common/Google/GoogleAutocompleteTypes";
import { useTranslation } from "react-i18next";

type Props = {
	googleApi: {
		map: any;
		api: GoogleMapsSdk
	};
	className?: string;
	onChange?: (value?: google.maps.places.PlaceResult) => void;
};

const SearchLocation = ({ googleApi, className, onChange: onChangeProp }: Props) => {
	const locale = useSelector((state) => state.location.selectedLocale) || "en";
	const { t } = useTranslation();

	const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
	const ref = React.useRef<HTMLInputElement>(null);
	const [value, setValue] = React.useState<string>();

	const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
		setValue(place.formatted_address ?? place.name);
		onChangeProp?.(place);
	};

	const onClear = () => {
		if (ref.current) {
			setValue("");
			ref.current.value = "";
		}
	};

	return (
		<SearchLocationContainer className={className} showButton={Boolean(value)}>
			<div className="input__container">
				<GoogleAutocomplete
					apiKey={apiKey}
					googleApi={googleApi}
					isAutocompleteEnabled
					onSelect={onPlaceSelected}
					language={locale}
					inputProps={{
						placeholder: t("searchYourLocation").toString(),
					}}
				/>
			</div>
		</SearchLocationContainer>
	);
};

export default SearchLocation;
