import { ReactElement } from "react";
import Button from "../Button/Button";
import styled from "styled-components";
import { CustomiseComponent } from "ts/interfaces";

interface Props {
  direction?: "row" | "column";
  children?: ReactElement<typeof Button>[];
}

const ButtonGroup: React.FC<Props & CustomiseComponent> = ({
  direction = "row",
  children,
  className,
  styles,
}) => {
  return (
    <ButtonGroupWrapper className={className} styles={styles} direction={direction}>
      {children}
    </ButtonGroupWrapper>
  );
};

export default ButtonGroup;

const ButtonGroupWrapper = styled.div<
  CustomiseComponent & {
    direction?: "row" | "column";
  }
>`
  display: flex;

  flex-direction: ${(props) => props.direction};

  button {
    flex-grow: 1;

    margin: ${(props) => props.direction === "row" ? "0px 8px" : "8px 0px"};

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  ${(props) => props.styles}
`;
