import Label from "components/common/form/Label/Label";
import styled from "styled-components";
import { dark, lightGrey } from "utilities/variables/colors";
import { SortOption } from "./SortOptions";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  padding: 8px 0px;
`;

interface Props {
  option: SortOption;
  isSelected: boolean;
  onChange: () => void;
}

const RadioButton = styled.input.attrs((props) => ({
  type: "radio",
}))`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  appearance: none;

  border: 1px solid ${lightGrey};
  border-radius: 50%;

  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: none;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${dark};
  }

  &:checked::before {
    display: block;
  }
`;

const PlpSortInput: React.FC<Props> = ({ option, isSelected, onChange }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <RadioButton
        id={option.id}
        value={option.id}
        checked={isSelected}
        onChange={onChange}
      />
      <Label label={t(`sortBy.${option.id}`)|| option.label} htmlFor={option.id} />
    </Wrapper>
  );
};

export default PlpSortInput;
