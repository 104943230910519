import { useLogoutMutation } from "app/api/authApi";
import useCart from "hooks/cart/useCart";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { logout as AuthSliceLogout } from "redux/auth/authSlice";
import { useDispatch, useSelector } from "redux/hooks";
import {
	COOKIE_CUSTOMER_IDENTIFIER,
	COOKIE_SHOP_TOKEN,
} from "utilities/consts";
import PathTranslationKey from "utilities/paths";

const useLogout = (replace?: boolean, to?: string) => {
	const { t } = useTranslation();
	const { identifier } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	const localeNavigate = useLocaleNavigate();

	const cart = useCart();

	const [logout] = useLogoutMutation();

	const doLogout = async (storeHash?: string, deleteCart = true) => {
		deleteCart && (await cart.deleteCart());

		if (identifier) {
			await logout(storeHash)
				.unwrap()
				.catch((error) => {
					return;
				});
		}

		Cookies.remove(COOKIE_CUSTOMER_IDENTIFIER);
		Cookies.remove(COOKIE_SHOP_TOKEN);

		dispatch(AuthSliceLogout());

		const accountsUrl = t(PathTranslationKey.ACCOUNT_OVERVIEW);
		const signOutUrl = t(PathTranslationKey.SIGN_OUT);

		const path = window.location.pathname;

		if (path.includes(accountsUrl) || path.includes(signOutUrl)) {
			localeNavigate(to ? to : t(PathTranslationKey.SIGN_IN), {
				replace: replace,
			});
		}
	};

	return (storeHash?: string, deleteCart = true) => {
		doLogout(storeHash, deleteCart);
	};
};

export default useLogout;
