import styled, { css } from "styled-components";
import { aboveMedium } from "utilities/variables/media";
import { overlayZIndex, productCardVariants } from "utilities/variables/zIndex";

export const VariantsContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 4px;
`;

export const ModalContainerStyles = css`
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #F8F9FA;
  bottom: 0;
  padding: 16px 14px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom, 0)) !important;

  display: flex;
  flex-direction: column;
  z-index: ${overlayZIndex+ productCardVariants};
  box-shadow: none;
`;