import {  useLazyGetProductRecommendationsQuery } from "app/api/segmentify/segmentifyApi";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import uuid from "react-uuid";
import { useSelector } from "redux/hooks";
import { BcStore } from "ts/types";

export interface SegmentifyEventData {
	name?: string;
	userId?: number;
	sessionId?: string;
	pageUrl?: string;
	referrer?: string;
	device?: string;
	lang?: string;
	currency?: string;
	testMode?: boolean;
	productId?: string;
	title?: string;
	inStock?: boolean;
	stockCount?: string;
	url?: string;
	image?: string;
	category?: string;
	brand?: string;
	price?: string | number;
	oldPrice?: string | number;
	gender?: string;
	sizes?: string[];
}

interface SegmentifyPageView {
	name?: string;
	userId?: string | number;
	sessionId?: string;
	pageUrl?: string;
	referrer?: string;
	lang?: string;
	currency?: string;
	testMode?: boolean;
	category?: string;
	device?: string;
	region?: string;
}

interface SegmentifyCartEventData {
	name: string;
	step: string;
	totalPrice: number;
	cartUrl: string;
	productList: ProductList[];
}

interface ProductList {
	productId: number;
	price: number;
	currency: string;
	quantity: number;
}

const useSegmentify = () => {
	const [getProductRecs] =
		useLazyGetProductRecommendationsQuery();

	const { bcStore: { currencies = [], store = {} as BcStore } = {}, location } = useSelector((state) => state);

	let deviceMode = 'PC'

	if (isMobile) {
		deviceMode = 'MOBILE'
	}

	if (isTablet) {
		deviceMode = 'TABLET'
	}

	if (isAndroid) {
		deviceMode = 'ANDROID'
	}

	if (isIOS) {
		deviceMode = 'IOS'
	}

	const language = (location.selectedLocale ?? '').split('-')  ?? 'EN'
	let segmentifyLanguage = String(language.length ? language[language.length - 1] : language).toUpperCase() ?? 'EN';

	if (segmentifyLanguage === 'RS') {
		segmentifyLanguage = 'SR'
	}

	let pageView: SegmentifyPageView = {
		name: "PAGE_VIEW",
		userId: `USER_${sessionStorage.getItem("userId") || uuid()}`,
		sessionId: `SESSION_${sessionStorage.getItem("sessionId") || "unknown"}`,
		pageUrl: window.location.href,
		referrer: "",
		lang: segmentifyLanguage,
		currency: String(currencies[0]?.code ?? 'EUR').toUpperCase() ?? 'EUR',
		testMode: true,
		device: deviceMode,
		region: String(store?.code || 'en').toUpperCase()
	};

	const pageViewOnly = async (type: string = 'page', breadcrumbs?: string) => {
		const body = JSON.stringify([{
			...pageView,
			category: String(type),
			...breadcrumbs && {
				subCategory: breadcrumbs,
			}
		}]);
		return await getProductRecs({ body })
			.unwrap()
			.then((res) => res)
			.catch((err:any) => err);
	};

	const pageViewWithEvent = (
		productData: SegmentifyEventData | SegmentifyCartEventData,
		type: string = 'page'
	) => {
		const completeEventData = {
			...{
				...pageView,
				category: String(type),
			},
			...productData,
		};

		const body = JSON.stringify([{...pageView, category: String(type)}, completeEventData]);

		getProductRecs({ body });
	};

	return {
		pageViewOnly,
		pageViewWithEvent,
	};
};

export default useSegmentify;
