import wordPressGetBlogPage from 'app/gql/wordPressGetBlogPage';
import wordPressGetBlogPostData from 'app/gql/wordPressGetBlogPosts';
import wordPressGetListOfCurrentOpportunities from 'app/gql/wordPressGetListOfCurrentOpportunities';
import { wordPressGQL } from 'app/services/wordPressGqlService';

export const blogsApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getBlogData: builder.query({
			query: (args: { blogPageTitle: string, pageSize: number; cursor?: string; tag?: string}) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetBlogPostData(args.blogPageTitle, args.pageSize, args.cursor, args.tag),
				},
			}),
			transformResponse: (res: any) => {
				// return(res)
				if (!res.data) return(res);
				const { pages, posts } = res.data
				const pageInfo = { ...res.data.posts.pageInfo };

				const postData = posts.nodes.map((post: any) => {
					const postData = {
						postID: post.postId,
						uri: post.uri.replace(/^\/|\/$/g, ''),
						title: post.title,
						featuredImage: post.featuredImage?.node.sourceUrl,
						categories: post.categories?.nodes.map((category: any) => { return (category.name) }),
						tags: post.tags?.nodes.map((tag: any) => { return (tag.slug) }),

					}
					return (postData)
				})
				const { contentBreak1, contentBreak2 } = pages.edges[0]?.node?.blogTopPage
				const pageData = {
					imageUrl: pages.edges[0]?.node?.featuredImage?.node?.sourceUrl,
					title: pages.edges[0]?.node?.title,
					contentBreak1: contentBreak1,
					contentBreak2: contentBreak2
				}

				return {
					loading: false,
					posts: postData,
					pages: pageData,
				  pageInfo:pageInfo
				};
			}
		}),
		getBlogPage: builder.query({
			query: (title: string) => ({
				url: '',
				method: 'POST',
				body: {
					...wordPressGetBlogPage(title),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) return(res);

				const { pages } = res.data
				const { contentBreak1, contentBreak2 } = pages.edges[0]?.node?.blogTopPage
				const {seo} = pages.edges[0]?.node
				const pageData = {
					imageUrl: pages.edges[0]?.node?.featuredImage?.node?.sourceUrl,
					title: pages.edges[0]?.node?.title,
					contentBreak1: contentBreak1,
					contentBreak2: contentBreak2,
					seo:seo
				}

				return {
					loading: false,
					pages: pageData,
				};
			}
		}),
	}),
});

export const {
	useGetBlogPageQuery,
	useGetBlogDataQuery,
	useLazyGetBlogDataQuery,
} = blogsApi;
