import useBreakpoints from "hooks/useBreakpoints";

import { ReactComponent as Previous } from "assets/images/svg/PaginationPrevious.svg";
import { ReactComponent as Next } from "assets/images/svg/PaginationNext.svg";
import Icon from "./Icon";
import styled, { css } from "styled-components";

interface PaginationArrowProps {
  variant: "prev" | "next";
}

const StyledIcon = styled(Icon)`
  margin-right: 0;
`;

const PaginationArrow: React.FC<PaginationArrowProps> = ({ variant }) => {
  return <StyledIcon icon={variant === "prev" ? <Previous /> : <Next />} />;
};

export default PaginationArrow;
