import styled from "styled-components";

interface ListProps {
	children: any;
}

const StyledUl = styled.ul`
	display: inline-flex;
	padding: 0;
	margin: 0;
	li {
		display: inline;
	}
`;

const InlineList: React.FC<ListProps> = ({ children }) => {
	return <StyledUl>{children}</StyledUl>;
};

export default InlineList;
