import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useOpportunitiesPagination from "hooks/useOpportunitiesPagination";
import uuid from "react-uuid";

import { PageType } from "ts/enums";
import { useGetCurrentOpportunitiesPageQuery } from "app/api/opportunities/currentOpportunitiesApi";
import getRobotsContent from "utilities/getRobotsContent";

import Button from "components/common/ui/Button/Button";
import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import Page from "components/common/ui/Page/Page";
import Pagination from "components/common/ui/Pagination/Pagination";
import SEO from "components/common/ui/SEO/SEO";

import { StyledMainImageBanner } from "../CompanyPages.Styled";
import {
	SkeletonJobsWrapper,
	StyledErrorMessage,
	StyledOpportunitiesPageWrapper,
} from "./styled";
import PathTranslationKey from "utilities/paths";
import { useMatch } from "react-router";
import { validateUrlSlashes } from "app/utils/urlUtils";
import { useTranslation } from "react-i18next";
import { useSelector } from "redux/hooks";
import { WordpressTemplateEnum } from "app/wordpress/components/WordpressTemplates";

const Opportunities = () => {
	let pageSize = Number(process.env.REACT_APP_OPPORTUNITIES_PER_PAGE || 10);

	const path = useMatch("/:locale/*")?.params["*"] || "";

	const pagination = useOpportunitiesPagination(pageSize);
	const { data: pageData, isLoading: isPageLoading } =
		useGetCurrentOpportunitiesPageQuery(validateUrlSlashes(path));


		// copy this
	const [page, setPage] = useState<any[]>();

	const imageData = [
		{
			image: {
				desktopImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				mobileImage: pageData?.pageData?.featuredImage.node.sourceUrl,
				alt: pageData?.pageData?.featuredImage.node.altText,
			},
			ctaLink: "",
			ctaText: "",
		},
	];

	// copy this
	useEffect(() => {
		if (!pagination.uninitialized && !pagination.loading) {
			setPage(pagination.getPage());
		}
	}, [pagination.loading]);

	// copy this
	const handlePageChange = (page: number) => {
		if (!pagination.uninitialized && !pagination.loading) {
			setPage(pagination.getPage(page));
		} else {
			pagination.setActivePage(page);
		}
	};

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const currentOpportunities = useMemo(() => {
		const template = wordpressTemplates.find(
			(template) =>
				template.template === WordpressTemplateEnum.CurrentOpportunities,
		);

		return template;
	}, [wordpressTemplates, useSelector]);

	let title = "";
	const { t } = useTranslation();

	const showNoOpportunitiesError =
		!pagination.uninitialized &&
		!pagination.loading &&
		pagination.totalPages === 0;

	const showNoPageError =
		(pagination.totalPages !== 0 && !page) || page?.length === 0;

	if (showNoOpportunitiesError) {
		return (
			<Page pageType={PageType.CATEGORY} title={title}>
				<ErrorContent heading="No Opportunities Found">
					{t("opportunities.noOpportunitiesFound")}
				</ErrorContent>
			</Page>
		);
	}

	return (
		<Page pageType={PageType.DEFAULT}>
			<SEO
				title={pageData?.pageData?.seo?.title}
				description={pageData?.pageData?.seo?.metaDesc}
				keywords={pageData?.pageData?.seo?.metaKeywords}
				robots={getRobotsContent([
					pageData?.pageData?.seo?.metaRobotsNofollow,
					pageData?.pageData?.seo?.metaRobotsNoindex,
				])}
			/>
			<StyledOpportunitiesPageWrapper className="StyledOpportunitiesPageWrapper">
				<StyledMainImageBanner className="main-image-banner">
					{isPageLoading ? (
						<Skeleton width={"100%"} />
					) : (
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={true}
							imageText={pageData?.pageData?.title}
							isLoading={isPageLoading}
						/>
					)}
				</StyledMainImageBanner>

				{!pagination.loading && showNoPageError && (
					<StyledErrorMessage>
						<ErrorContent heading="Invalid Page">
							{t("opportunities.invalidPage")}
						</ErrorContent>
					</StyledErrorMessage>
				)}

				{pagination.loading && !page ? (
					<SkeletonJobsWrapper>
						<Skeleton count={4} />
					</SkeletonJobsWrapper>
				) : (
					page?.map((opportunity: any) => (
						<ul className="opportunities-container" key={uuid()}>
							<li className="opportunities-row">
								<div>
									<h3>{t("opportunities.position")}</h3>
									<p>{opportunity.title}</p>
								</div>
								<div>
									<h3>{t("opportunities.location")}</h3>
									<p>{opportunity.jobs.location}</p>
								</div>
								<div>
									<h3>{t("opportunities.deadline")}</h3>
									<p>{opportunity.jobs.deadline}</p>
								</div>
								<div className="opportunity-button">
									<Button
										variant="primary_01"
										link={{ to: `${currentOpportunities?.uri}${opportunity.id}` }}
									>
										{t("opportunities.findMore")}
									</Button>
								</div>
							</li>
						</ul>
					))
				)}

				<Pagination
					loading={pagination.loading}
					totalPages={pagination.totalPages}
					onChange={handlePageChange}
				/>

				<div className="opportunities-contact">
					{!isPageLoading ? (
						<>
							<h2>{pageData?.pageData?.currentOpportunities?.title}</h2>
							<p>{pageData?.pageData?.currentOpportunities?.text}</p>
							<Button
								variant="primary_01"
								link={{ to: `${PathTranslationKey.APPLICATION_FORM}` }}
							>
								{t("opportunities.findMore")}
							</Button>
						</>
					) : (
						<Skeleton count={4} />
					)}
				</div>
			</StyledOpportunitiesPageWrapper>
		</Page>
	);
};

export default Opportunities;
