import { useEffect, useState } from "react";
import uuid from "react-uuid";
import useBreakpoints from "hooks/useBreakpoints";

import Page from "components/common/ui/Page/Page";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import SEO from "components/common/ui/SEO/SEO";

import {
	useGetAboutUsPageQuery,
	useLazyGetAboutUsPageQuery,
} from "app/api/company/basicPagesApi";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";

import FiguresSection from "components/common/layouts/figuresSection/FiguresSection";
import ImageTextSplit from "components/common/layouts/ImageTextSplit/ImageTextSplit";
import Timeline from "components/common/layouts/timeline/Timeline";
import ImageBanner from "components/common/ui/ImageBanner/ImageBanner";
import CompanyValuesCarousel from "../../components/common/layouts/companyValues/CompanyValuesCarousel";
import CompanyValuesGrid from "../../components/common/layouts/companyValues/CompanyValuesGrid";

import {
	SkeletonWrapper,
	StyledAboutUsPageWrapper,
	StyledCompanyValuesSection,
} from "./AboutUs.Styled";
import {
	StyledImageBanner,
	StyledMainImageBanner,
} from "./CompanyPages.Styled";
import { useSelector } from "redux/hooks";
import { useMatch } from "react-router";

const AboutUs = () => {
	const path = useMatch("/:locale/:path")?.params.path;

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [trigger, { data, isSuccess }] = useLazyGetAboutUsPageQuery();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (path) {
			const template = wordpressTemplates.find((template) =>
				template.uri.includes(path),
			);
			if (template) {
				trigger(template.id);
			}
		}
	}, [path, wordpressTemplates]);

	useEffect(() => {
		if (data) {
			setLoading(false);
		}
	}, [data]);

	const size = useBreakpoints();
	const [displayType, setDisplayType] = useState<"grid" | "carousel">("grid");

	const imageData = [
		{
			image: {
				desktopImage: data?.mainBanner?.sourceUrl,
				mobileImage: data?.mainBanner?.sourceUrl,
				alt: data?.mainBanner.altText,
			},
			ctaLink: "",
			ctaText: data?.pageTitle,
		},
	];

	useEffect(() => {
		if (size.large) {
			if (displayType === "grid") return;

			setDisplayType("grid");
		} else {
			if (displayType === "carousel") return;

			setDisplayType("carousel");
		}
	}, [size]);
	return (
		<Page pageType={PageType.PAGE}>
			<SEO
				title={data?.seo?.title}
				description={data?.seo?.metaDesc}
				keywords={data?.seo?.metaKeywords}
				robots={getRobotsContent([
					data?.seo?.metaRobotsNofollow,
					data?.seo?.metaRobotsNoindex,
				])}
			/>
			<StyledAboutUsPageWrapper className="StyledAboutUsPageWrapper">
				<StyledMainImageBanner className="main-image-banner">
					{loading ? (
						<Skeleton width={"100%"} />
					) : (
						<ImageBanner
							orientation="landscape"
							images={imageData}
							showButton={false}
							showText={true}
							imageText={data?.pageTitle}
							isLoading={loading}
						/>
					)}
				</StyledMainImageBanner>

				{loading ? (
					<div className="skeleton-wrapper">
						<SkeletonWrapper>
							<Skeleton containerClassName="skeleton-container" />
						</SkeletonWrapper>
						<div className="skeleton-text">
							<Skeleton height="40px" />
							<Skeleton count={13} />
						</div>
					</div>
				) : (
					<ImageTextSplit
						isFlipped={false}
						isLoading={false}
						image={data?.introduction?.sectionImage}
						text={data?.introduction?.text}
						title={data?.introduction?.heading}
						key={uuid()}
					/>
				)}

				{isSuccess ? (
					<FiguresSection figures={data?.figures} />
				) : (
					<Skeleton width={"100%"} height={256} />
				)}

				{/* timeline */}
				<section className="flex-carousel-fix">
					{loading ? (
						<SkeletonWrapper className="skeleton-timeline">
							<Skeleton height="100%" />
						</SkeletonWrapper>
					) : (
						<Timeline timelineData={data?.timeline} isSuccess={isSuccess} />
					)}
				</section>

				{/* Our Vision */}
				<section className="vision-section">
					{loading ? (
						<div className="skeleton-wrapper">
							<SkeletonWrapper>
								<Skeleton containerClassName="skeleton-container" />
							</SkeletonWrapper>
							<div className="skeleton-text">
								<Skeleton height="40px" />
								<Skeleton count={13} />
							</div>
						</div>
					) : (
						<ImageTextSplit
							isFlipped={true}
							isLoading={false}
							image={data?.missionsStatement?.image}
							text={data?.missionsStatement?.text}
							title={data?.missionsStatement?.header}
							key={uuid()}
						/>
					)}
				</section>

				{/* company values */}
				<StyledCompanyValuesSection className="flex-carousel-fix">
					<h2>{data?.companyvalues.title}</h2>
					{displayType === "carousel" ? (
						<CompanyValuesCarousel companyValues={data?.companyvalues && {companyvalues: {...data[`companyvalues`]}}} />
					) : (
						<CompanyValuesGrid companyValues={data?.companyvalues.values} />
					)}
				</StyledCompanyValuesSection>

				{data?.additionalLinks?.map((link: any) => (
					<StyledImageBanner
						orientation="landscape"
						images={[
							{
								image: {
									desktopImage: link?.image.desktopImage.sourceUrl,
									mobileImage:
										link?.image.mobileImage?.sourceUrl ||
										link?.image.desktopImage.sourceUrl,
									alt: link?.image.desktopImage.altText,
								},
								ctaLink: link?.link?.url,
								ctaText: link?.link?.title,
							},
						]}
						showButton={link?.link?.title ? true : false}
						showText={true}
						imageText={link?.title}
						isLoading={loading}
						key={uuid()}
						isBottomBanner={true}
					/>
				))}
			</StyledAboutUsPageWrapper>
		</Page>
	);
};

export default AboutUs;
