import Typography from "components/common/ui/Typography/Typography";
import ProductCard from "components/ProductCard/ProductCard";
import styled from "styled-components";
import { Swiper } from "swiper/react";
import { remCalc } from "utilities/styles";
import { aboveMedium, aboveSmall } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f8f9fa;
	padding: ${remCalc(48)} ${remCalc(16)};

	@media ${aboveMedium} {
		padding: ${remCalc(64)} ${remCalc(20)};
	}
`;

export const StyledHeading = styled(Typography)`
	margin-bottom: ${remCalc(16)};
`;

export const ProductCardWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
	overflow-x: auto;
	justify-content: center;
`;

export const CarouselWrapper = styled.div`
	// CSS Grid/Flexbox bug size workaround
	// @see https://github.com/kenwheeler/slick/issues/982
	width: 100%;
	max-width: 100%;
	max-height: 100vh;
	min-height: 0;
	min-width: 0;
`;

export const StyledSwiper = styled(Swiper)`
	max-width: calc(${remCalc(1280)} - 24px);

	.pdp-ctl_swiper-slide {
		width: fit-content !important;
		height: auto !important;
		margin: 0 ${remCalc(2)};

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		.ProductCard {
			width: ${remCalc(128)};
		}
	}

	@media ${aboveSmall} {
		.pdp-ctl_swiper-slide {
			.ProductCard {
				width: ${remCalc(188)};
			}
		}
	}

	@media ${aboveMedium} {
		.pdp-ctl_swiper-slide {
			width: 100% !important;

			.ProductCard {
				width: 25%;
			}
		}
	}
`;
