import { IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { accountDarkGrey } from "utilities/variables/colors";

const FlexContainer = styled.div`
	display: flex;
	justify-content: center;

	ion-spinner {
		color: ${accountDarkGrey};
	}
`;

const LoadingSpinner: React.FC = () => {
	return (
		<FlexContainer>
			<IonSpinner />
		</FlexContainer>
	);
};

export default LoadingSpinner;
