import React, { useMemo, useState } from "react";

import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";

import {
	EmailFieldValue,
	FieldValueUnion,
	NameFieldValue,
	resetFieldValues,
	updateEmailFieldValue,
	updateNameFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
	FieldError,
	FormField,
	FormFieldTypeEnum,
	GravityForm as GravityFormType,
} from "types/GravityForm";

import { Asterisk } from "../form/Label/Label";
import Skeleton from "../ui/Skeleton/Skeleton";
import GravityFormsField from "./GravityFormField";

import { gravityFormApi } from "app/api/wordpress/gravityFormApi";
import wordPressSubmitGravityForm from "app/gql/wordPressSubmitGravityForm";
import {
	StyledForm,
	StyledMarkdown,
	StyledSkeletonContainer,
	SuccessMessageContainer,
} from "./GravityForm.Styled";

export type GravityFormDefaultValue = {
	[FormFieldTypeEnum.NAME]?: {
		first?: string;
		last?: string;
	};
	[FormFieldTypeEnum.EMAIL]?: string;
	[FormFieldTypeEnum.PHONE]?: string;
	[FormFieldTypeEnum.ADDRESS]?: {
		street?: string;
		lineTwo?: string;
		city?: string;
		state?: string;
		zip?: string;
		country?: string;
	};
	[FormFieldTypeEnum.TEXTAREA]?: string;
	[FormFieldTypeEnum.TEXT]?: string;
	[FormFieldTypeEnum.CONSENT]?: {
		value?: string;
	};
};

interface Props {
	form?: GravityFormType;
	isLoading?: boolean;
	defaultValue?: GravityFormDefaultValue;
	showLegend?: boolean;
}

const GravityForm = ({
	form,
	isLoading,
	defaultValue,
	showLegend = true,
	...props
}: Props) => {
	const dispatch = useDispatch();
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);
	const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
	const [submitForm] = gravityFormApi.useSubmitGravityFormMutation();
	const [submitWithFile] = gravityFormApi.useSubmitFormWithFileMutation();

	const [submitResult, setSubmitResult] = useState<any>();

	const defaultConfirmation = form?.confirmations?.find(
		(confirmation) => confirmation?.isDefault,
	);
	const formFields = useMemo(() => {
		return form?.formFields?.nodes || [];
	}, [form]);

	const haveEntryId = Boolean(
		submitResult?.data?.data?.submitGfForm?.entry?.id,
	);
	const haveFieldErrors = Boolean(
		submitResult?.data?.data?.submitGfForm?.errors?.length,
	);
	const wasSuccessfullySubmitted = haveEntryId && !haveFieldErrors;

	const hasFileUpload = useMemo(() => {
		return formFields.some(
			(field) => field.type === FormFieldTypeEnum.FILEUPLOAD,
		);
	}, [formFields]);

	// Show success message for 5 seconds before showing the form again
	React.useEffect(() => {
		setShowSuccessMessage(false);

		if (wasSuccessfullySubmitted) {
			setShowSuccessMessage(true);

			const timerId = setTimeout(() => {
				setShowSuccessMessage(false);
				setSubmitResult(undefined);
			}, 5000);

			return () => clearTimeout(timerId);
		}
	}, [wasSuccessfullySubmitted]);

	// Reset field values if form is successfully submitted and no field errors
	React.useEffect(() => {
		if (submitResult?.isSuccess && !haveFieldErrors) {
			dispatch(resetFieldValues());

			// Populate name and email fields based on last submitted values
			try {
				dispatch(
					updateNameFieldValue(
						fieldValues.find((fieldValue) =>
							Object.keys(fieldValue).includes("nameValues"),
						) as NameFieldValue,
					),
				);
				dispatch(
					updateEmailFieldValue(
						fieldValues.find((fieldValue) =>
							Object.keys(fieldValue).includes("emailValues"),
						) as EmailFieldValue,
					),
				);
			} catch (err) {}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, haveFieldErrors, submitResult]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!form) return;

		const payload = { id: form.databaseId, fieldValues };

		if (!hasFileUpload) {
			const result = await submitForm(payload)
				.then((res) => {
					dispatch(resetFieldValues());
					return res;
				})
				.catch((error: any) => {
					console.error(error);
					return error;
				});

			setSubmitResult(result);
		} else {
			//
			let filesToUpload: {
				id: number;
				file: File;
			}[] = [];

			const newFields = [] as FieldValueUnion[];

			//null file
			payload.fieldValues.forEach((field: any) => {
				if (field.fileUploadValues !== undefined) {
					if (field.fileUploadValues.length) {
						//put files into separate array
						filesToUpload.push({
							id: field.id,
							file: field.fileUploadValues[0],
						});

						newFields.unshift({
							id: field.id,
							fileUploadValues: Array(field.fileUploadValues.length).fill(null),
						});
					}
				} else {
					newFields.push(field);
				}
			});

			const operation = {
				...wordPressSubmitGravityForm({
					id: payload.id,
					fieldValues: newFields,
				}),
			};

			const getFileInputIndex = (fieldId: number) => {
				const foundField = newFields.find((field: any) => field.id === fieldId);

				if (foundField) {
					return newFields.indexOf(foundField);
				}

				return undefined;
			};

			let map = "";

			filesToUpload.forEach((upload, index) => {
				const fieldIndex = getFileInputIndex(upload.id);

				if (fieldIndex !== undefined) {
					map += `"${fieldIndex}": ["variables.fieldValues.${fieldIndex}.fileUploadValues.0"]`;

					if (index !== filesToUpload.length - 1) {
						map += ", ";
					}
				}
			});

			const formData = new FormData();

			formData.append(
				"operations",
				JSON.stringify(operation).replace(/\s+/g, " ").trim(),
			);
			formData.append("map", `{${map}}`);

			filesToUpload.forEach((upload, index) => {
				const fieldIndex = getFileInputIndex(upload.id);
				if (fieldIndex !== undefined) {
					formData.append(fieldIndex.toString(), upload.file, upload.file.name);
				}
			});

			const result = await submitWithFile(formData)
				.then((res) => {
					dispatch(resetFieldValues());
					return res;
				})
				.catch((error: any) => {
					console.error(error);
					return error;
				});

			setSubmitResult(result);
		}
	};

	const getFieldErrors = (id: number): FieldError[] => {
		if (!haveFieldErrors) return [];

		const fieldErrors = submitResult?.data.data.submitGfForm.errors.filter(
			(error: FieldError) => error.id === id,
		);

		return fieldErrors;
	};

	// Clear form fields on unmount
	React.useEffect(() => {
		return () => {
			dispatch(resetFieldValues());
		};
	}, [dispatch]);

	if (isLoading || !form) {
		return (
			<StyledSkeletonContainer>
				<div className="row">
					<div className="field">
						<Skeleton height={20} width={80} />
						<Skeleton height={40} />
					</div>
					<div className="field">
						<Skeleton height={20} width={80} />
						<Skeleton height={40} />
					</div>
				</div>
				<div className="row">
					<div className="field">
						<Skeleton height={20} width={80} />
						<Skeleton height={40} />
					</div>
					<div className="field">
						<Skeleton height={20} width={80} />
						<Skeleton height={40} />
					</div>
				</div>
				<div className="field">
					<Skeleton height={20} width={120} />
					<Skeleton height={96} />
				</div>
			</StyledSkeletonContainer>
		);
	}

	return (
		<StyledForm method="post" onSubmit={handleSubmit} {...props}>
			{showLegend && (
				<div className="gravity-forms__required-legend">
					<Asterisk />
					<Typography>required</Typography>
				</div>
			)}
			<div className="gravity-forms__grid">
				{formFields?.map((field) => (
					<GravityFormsField
						field={field as FormField}
						fieldErrors={getFieldErrors(Number(field?.id))}
						defaultValue={defaultValue}
					/>
				))}
			</div>
			{submitResult?.data?.errors?.map((error: any, index: number) => (
				<Typography key={error.id || index}>{error.message}</Typography>
			))}
			<Button type="submit" variant="primary_01">
				{form.submitButton.text || "Send Message"}
			</Button>
			{showSuccessMessage && (
				<SuccessMessageContainer>
					<StyledMarkdown
						safeRender
						html={
							defaultConfirmation?.message || "Form successfully submitted."
						}
					/>
				</SuccessMessageContainer>
			)}
		</StyledForm>
	);
};

export default GravityForm;
