import styled from "styled-components";

const StyledDivider = styled.span`
    cursor: default !important;
    border-right: 1px solid #adb5bd; //1px total
    margin: 0 6px 0 12px;
    height: 16px;
    align-self: center;
`;

const Divider: React.FC = () => {
  return <StyledDivider />;
};

export default Divider;
