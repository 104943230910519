import { createGlobalStyle } from "styled-components";

import {
	accountDarkGrey,
	dark,
	white,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveSmall } from "utilities/variables/media";

const GlobalStyle = createGlobalStyle`

    body {
        font-family: ${Roboto};
        --color: ${dark};
        background-color: ${white};
        overflow: initial;
    }

    button {
        color: ${dark};
        padding: 0;
    }

    .flex-start {
        justify-content: start;
    }

    .flex-end {
        justify-content: end;
    }

    .w-100 {
        width: 100%;
    }

    .flex-carousel-fix {
		// CSS Grid/Flexbox bug size workaround
		// @see https://github.com/kenwheeler/slick/issues/982
		width: 100%;
		max-width: 100%;
		max-height: 100vh;
		min-height: 0;
		min-width: 0;

        @media ${aboveSmall} {
            max-height: 100%;
        }
	}

    .text {
        &-capitalize {
            text-transform: capitalize;
        }
        &-uppercase {
            text-transform: uppercase;
        }
        &-lowercase {
            text-transform: lowercase;
        }
        &-capitalize-first {
            text-transform: lowercase;
            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    // Google Autocomplete Styles
    .pac-container {
        z-index: 10000 !important;
        border: 1px solid #e3e3e3;

        box-shadow: 0px 5px 6px #21252940;

        .pac-item {
            padding: 4px 11px;
            font-family: ${Roboto};
            color: ${accountDarkGrey};
            letter-spacing: 0.7;
        }

        .pac-icon {
            display: none;
        }

        &::after {
            content: unset;
        }
	}
`;

export default GlobalStyle;
