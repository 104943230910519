import { gql } from 'graphql-request';
//(first: $pageSize, after: $cursor)
const wordPressGetBlogPostData = (
  blogPageTitle: string,
  pageSize: number,
  cursor?: string,
  tag?: string,
) => {
  return {
    operationName: "GetBlogPostData",
    variables: {
      pageSize,
      cursor,
      tag,
    },
    query: gql`
			query GetBlogPostData($pageSize: Int!, $cursor: String, $tag: String) {
          posts(first: $pageSize, after: $cursor, where:{tag: $tag} ) {
            nodes {
              uri
              title
              featuredImage {
                node {
                  sourceUrl
                }
              }
              categories {
                nodes {
                  categoryId
                  name
                }
              }
              postId
              slug
              tags {
                nodes {
                  slug
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
          pages(where: {title: "${blogPageTitle}"}) {
            edges {
              node {
                id
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                title
                blogTopPage {
                  contentBreak1 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                  contentBreak2 {
                    text
                    title
                    link {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
		`,
  };
};

export default wordPressGetBlogPostData;
