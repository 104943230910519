import wordPressGetMenuBySlug from "app/gql/queries/wordPressGetMenuBySlug";
import wordPressGetPageContentByUri from "app/gql/queries/wordPressGetPageContentByUri";
import { wordPressGetPageTemplates } from "app/gql/queries/wordPressGetPageTemplates";
import {
	wordPressGetFooterContent,
	wordPressGetPhoneNumber,
	wordPressGetStores,
	wordPressGetStoreSettings,
} from "app/gql/queries/wordPressGlobal";
import wordPressGetLoyaltyCardApplicationData from "app/gql/wordPressGetLoyaltyCardApplicationData";
import wordPressGetLoyaltyCardThresholds from "app/gql/wordPressGetLoyaltyCardThresholds";
import { wordPressGQL } from "app/services/wordPressGqlService";
import { convertWordPressData } from "app/utils/wordPressApiUtil";
import {
	getTemplate,
	WordpressTemplateEnum,
} from "app/wordpress/components/WordpressTemplates";
import { FieldValueUnion } from "redux/gravityForm/gravityFormSlice";
import { FooterContent, Store } from "ts/types";
import { GlobalStoreSettingsResponse } from "types/WordPress";

export const wordPressApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getPageContentByUri: builder.query({
			query: (uri, jobApplication = false) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetPageContentByUri(uri, jobApplication),
				},
			}),
			transformResponse: (res: any) => {
				if (res.data.pageBy) {
					return {
						application: res.data.pageBy.jobApplication?.introduction
							?.introductionHeaderImage
							? res.data.pageBy.jobApplication?.introduction
									?.introductionHeaderImage[0]
							: {},
						...res.data.pageBy,
						faqs: res.data.pageBy.faqs?.faqs?.map((faq: any) => ({
							...faq.faq,
						})),
					};
				} else {
					return "";
				}
			},
		}),
		getMenuItemsBySlug: builder.query({
			query: (slug) => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetMenuBySlug(slug),
				},
			}),
			transformResponse: (res: any) => {
				if (res.data.menu) {
					const nodes = res.data.menu.menuItems.nodes;
					return convertWordPressData(nodes);
				} else {
					return [];
				}
			},
		}),
		getPhoneNumber: builder.query<string, void>({
			query: () => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetPhoneNumber,
				},
			}),
			transformResponse: (res: any) => {
				if (res.data.storeGeneralSettings.globalStoreSettings) {
					return res.data.storeGeneralSettings.globalStoreSettings.phoneNumber;
				} else {
					return "";
				}
			},
		}),
		getFooterContent: builder.query<FooterContent | undefined, "">({
			query: () => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetFooterContent,
				},
			}),
			transformResponse: (res: any) => {
				if (res.data.storeGeneralSettings.footer) {
					const footerNode = res.data.storeGeneralSettings.footer;

					return {
						disclaimer: footerNode.disclaimer,
						badges: footerNode.badges.map((badge: any) => {
							return {
								...badge,
							};
						}),
					} as FooterContent;
				}

				return undefined;
			},
		}),
		getStores: builder.query<Store[], void>({
			query: () => ({
				url: "",
				method: "POST",
				body: { ...wordPressGetStores },
			}),
			transformResponse: (res: any) => {
				if (!res.data?.stores?.nodes) {
					return [];
				}

				return res.data.stores.nodes;
			},
		}),
		getLoyaltyCardThresholds: builder.query<any, void>({
			query: () => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetLoyaltyCardThresholds(),
				},
			}),
			transformResponse: (res: any) => {
				return res.data.storeGeneralSettings.globalStoreSettings.loyalty;
			},
		}),
		getLoyaltyCardApplicationData: builder.query<any, void>({
			query: () => ({
				url: "",
				method: "POST",
				body: {
					...wordPressGetLoyaltyCardApplicationData(),
				},
			}),
			transformResponse: (res: any) => {
				const {
					buttonText,
					textArea1: applyForCardText,
					textArea2: alreadyHaveACardText,
					title1: applyForCardTitle,
					title2: alreadyHaveACardTitle,
				} = res.data.storeGeneralSettings.globalStoreSettings.caLoyaltySection;

				return {
					buttonText,
					applyForCardTitle,
					applyForCardText,
					alreadyHaveACardTitle,
					alreadyHaveACardText,
				};
			},
		}),
		getGlobalStoreSettings: builder.query({
			query: (_: void) => ({
				url: "",
				method: "POST",
				body: wordPressGetStoreSettings,
			}),
			transformResponse: (res: GlobalStoreSettingsResponse) => res.data,
		}),
		getKlaviyoData: builder.query({
			query: (_: void) => ({
				url: "",
				method: "POST",
				body: wordPressGetStoreSettings,
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				return {
					emailMarketingListId:
						res.data.storeGeneralSettings.globalStoreSettings
							.accountcreationEmaillistId,
					signUpFormId:
						res.data.storeGeneralSettings.globalStoreSettings.signUpFormId,
				};
			},
		}),
		getPageTemplates: builder.query({
			query: (_: void) => ({
				url: "",
				method: "POST",
				body: wordPressGetPageTemplates,
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				const nodes = res.data.pages.nodes;

				let templates = nodes.map((node: any) => {
					const template = getTemplate(node.template.templateName);

					return {
						id: node.databaseId,
						title: node.title,
						uri:
							template === WordpressTemplateEnum.BlogHomepage
								? node.slug
								: node.uri,
						template,
					};
				}) as any[];

				const currentOpportunitiesTemplates = templates.filter(
					(t) => t.template === WordpressTemplateEnum.CurrentOpportunities,
				);

				templates = templates.filter((template) => template);

				if (currentOpportunitiesTemplates.length !== 1) {
					templates = templates.filter(
						(template) =>
							String(template.title).toLowerCase() !== "current opportunities",
					);
				}

				return templates;
			},
		}),
	}),
});
