import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "redux/hooks";
import uuid from "react-uuid";

import { useLazyGetHomepageDataQuery } from "app/api/homepage/homepageApi";
import SEO from "components/common/ui/SEO/SEO";
import useBrand from "hooks/useBrand";
import { PageType } from "ts/enums";
import getRobotsContent from "utilities/getRobotsContent";
import { LandingPageWrapper } from "./LandingPage.Styled";
import LandingPageSkeleton from "./LandingPageSkeleton";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";

const ImageBanner = lazy(() => import('components/common/ui/ImageBanner/ImageBanner'))
const HeroCarousel = lazy(() => import('../../components/common/ui/HeroCarousel/HeroCarousel'))
const Page = lazy(() => import('../../components/common/ui/Page/Page'))

const LandingPage = () => {
	const { active, param } = useBrand();

	const wordpressTemplates = useSelector(
		(state) => state.settings.wordpressTemplates,
	);

	const [trigger, { data, isLoading }] = useLazyGetHomepageDataQuery();

	useEffect(() => {
		let uri = "/";

		uri =
			wordpressTemplates.find(
				(template) => template.title.toLowerCase() === param,
			)?.uri || uri;

		trigger(uri);
	}, [active, wordpressTemplates, param, trigger]);

	return (
		<Suspense fallback={<LoadingSpinner/>}>
			<Page pageType={PageType.LANDING}>
				<SEO
					title={data?.seo?.title}
					description={data?.seo?.metaDesc}
					keywords={data?.seo?.metaKeywords}
					robots={getRobotsContent([
						data?.seo?.metaRobotsNofollow,
						data?.seo?.metaRobotsNoindex,
					])}
				/>
				<LandingPageWrapper>
					{!isLoading && data?.homeHeroCarousel && (
						<HeroCarousel carouselData={data?.homeHeroCarousel} />
					)}

					{isLoading ? (
						<LandingPageSkeleton />
					) : (
						data?.homePageLayout?.map((layoutType: any) => (
							<ImageBanner {...layoutType} key={uuid()} isLoading={false} />
						))
					)}
				</LandingPageWrapper>
			</Page>
		</Suspense>
	);
};

export default LandingPage;
