import styled from "styled-components";
import { dark, lightGrey } from "utilities/variables/colors";
import Label, { StyledLabel } from "../Label/Label";

export const RadioButtoncContainer = styled.div`
  display: flex;
  padding: 8px 0px;
`;

export const RadioButton = styled.input.attrs((props) => ({
  type: "radio",
}))`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  appearance: none;

  border: 1px solid ${lightGrey};
  border-radius: 50%;

  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: none;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${dark};
  }

  &:checked::before {
    display: block;
  }
`;