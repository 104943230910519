import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { hasQueryParams, removeTrailingSlash } from "app/utils/urlUtils";
import { baseQueryWithAccessToken } from "./integrationLayerService";

const klaviyoFetchBaseQuery = fetchBaseQuery({
	baseUrl: `${removeTrailingSlash(process.env.REACT_APP_IL_URL || "")}/klaviyo`,
	prepareHeaders: (headers, { getState }) => {
		headers.set("Content-Type", "application/json");
		headers.set("X-Request-With", "XMLHttpRequest");
		headers.set("Accept", "application/json");

		const identifier = (getState() as any).auth.identifier;

		if (identifier) {
			headers.set("X-BC-Customer", identifier.token);
		}
	},
});

const attachStoreHashBaseQuery = async (
	args: any,
	api: any,
	extraOptions: any,
) => {
	const bcStore = api.getState().bcStore.store;

	if (!bcStore) {
		return {
			error: {
				status: 400,
				statusText: "Store unknown",
			},
		};
	}

	const token = await baseQueryWithAccessToken(api, extraOptions);

	if (!token) {
		return {
			error: {
				status: 404,
				statusText: "Error getting access token",
			},
		};
	}

	args = {
		...args,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	let endpointUrl = typeof args === "string" ? args : args.url;

	const paramsFromUrl = hasQueryParams(endpointUrl);

	if (!endpointUrl.includes("?hash=")) {
		let paramsWithHash = `?hash=${bcStore.hash}`;

		if (paramsFromUrl) {
			endpointUrl = endpointUrl.split("?")[0];
			paramsWithHash = `${paramsWithHash}&${paramsFromUrl}`;
		}

		endpointUrl = `${endpointUrl}?${new URLSearchParams(
			paramsWithHash,
		).toString()}`;
	}

	const adjustedArgs =
		typeof args === "string" ? endpointUrl : { ...args, url: endpointUrl };

	// provide the amended url and other params to the raw base query
	return klaviyoFetchBaseQuery(adjustedArgs, api, extraOptions);
};

export const klaviyoService = createApi({
	reducerPath: "klaviyoService",
	baseQuery: attachStoreHashBaseQuery,
	endpoints: (builder) => ({}),
});
