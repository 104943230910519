import segmentifyGetQueryFromPrompt from "app/gql/queries/segmentifyGetQueryFromPrompt";
import segmentifyGetQueryAndFiltersFromPrompts from "app/gql/queries/segmentifyGetQueryFromPromptAndFilters";
import { segmentify } from "app/services/segmentifyService";
import {
	resetCarousels,
	setCarousels,
} from "redux/productRecs/productRecSlice";

export const segmentifyApi = segmentify.injectEndpoints({
	endpoints: (builder) => ({
		getQueryFromPrompt: builder.query({
			query: (args: { search: string; customer: any; sessionId: any, lang: string; currencyCode: string; }) => ({
				url: "",
				method: "POST",
				body: segmentifyGetQueryFromPrompt(args),
			}),
			transformResponse: (res: any) => {
				if (res.search && res.search[0] && res.search[0][0]) {
					const { products, keywords, brands } = res.search[0][0];
					return {
						products,
						keywords,
						brands,
					};
				} else {
					return {
						products: [],
						keywords: {},
						brands: [],
					};
				}
			},
		}),
		getQueryAndFiltersFromPrompts: builder.query({
			query: (args: {
				search: any;
				filters: any;
				sort: any;
				customer: any;
				sessionId: any;
				page: any;
				lang: string;
				currencyCode: string;
			}) => ({
				url: "",
				method: "POST",
				body: segmentifyGetQueryAndFiltersFromPrompts(
					args.search,
					args.filters,
					args.sort,
					args.customer,
					args.sessionId,
					args.page,
				),
			}),
			transformResponse: (res: any) => {
				if (res.search && res.search[0] && res.search[0][0]) {
					const { products, facets, meta } = res.search[0][0];
					return {
						products,
						facets,
						meta,
					};
				} else {
					return {
						products: [],
						facets: [],
						meta: { total: 0 },
					};
				}
			},
		}),
		getProductRecommendations: builder.query({
			query: ({ body }) => ({
				url: "",
				method: "POST",
				body: body,
			}),
			transformResponse: (res: any) => {
				return res.responses;
			},
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;

					let title;
					let recommendedProducts: any;
					let carousels: any = [];

					data?.map((res: any) => {
						res.map((nextRes: any) => {
							title = nextRes.params.notificationTitle;

							for (const [key, value] of Object.entries(
								nextRes.params.recommendedProducts,
							)) {
								if (key !== "RECOMMENDATION_SOURCE_STATIC_ITEMS") {
									recommendedProducts = (value as any[]).map(
										(product: any) => product.productId,
									);
								}
							}

							carousels.push({ title, recommendedProducts });
						});
					});

					if (carousels.length) {
						dispatch(setCarousels(carousels));
					} else {
						dispatch(resetCarousels());
					}
				} catch (err) {
					console.error(err);
				}
			},
		}),
	}),
});
export const {
	useGetQueryFromPromptQuery,
	useLazyGetQueryFromPromptQuery,
	useLazyGetQueryAndFiltersFromPromptsQuery,
	useLazyGetProductRecommendationsQuery,
} = segmentifyApi;
