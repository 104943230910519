import SortOptions from "components/plp/PlpSort/SortOptions";

interface filter {
	facet: string;
	values: string[];
}

const segmentifyGetQueryAndFiltersFromPrompts = (search: string, filters: filter[],sort:string, customer:any, sessionId:any, page:any) => {
	return JSON.stringify([
		{
			name: "SEARCH",
			userId: customer ? (customer as any).id : 0,
			sessionId:  sessionStorage.getItem("sessionId") || "unknown",
			lang: "EN",
			currency: "",
			query: search,
			type: "faceted",
			ordering: {
				page: page||1,
				sort: sort || SortOptions[0].segmentify_param
			},
			filters: filters,
			trigger: "filter",
        	service: "default",
        	mode: "LIVE"
		},
	]);
};

export default segmentifyGetQueryAndFiltersFromPrompts;
