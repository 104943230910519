import { bigcommerceObjectByUrl } from "app/gql/queries/bigcommerceObjectByUrl";
import { bigcommerceGetCategoryProductPage } from "app/gql/queries/bigcommerceGetCategoryProudctPage";
import { bigCommerceGQL } from "app/services/bigCommerceGqlService";
import {
	convertBigCommerceCategory,
	convertBigCommerceProduct,
} from "app/utils/bigcommerceApiUtil";

export const categoryApi = bigCommerceGQL.injectEndpoints({
	endpoints: (builder) => ({
		getCategory: builder.query({
			query: (path: string) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceObjectByUrl(path),
				},
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}

				return convertBigCommerceCategory(res.data.site.route.node);
			},
		}),
		getCategoryPage: builder.query({
			query: (args: {
				entityId: number;
				pageSize: number;
				cursor?: string;
			}) => ({
				url: "",
				method: "POST",
				body: {
					...bigcommerceGetCategoryProductPage(
						args.entityId,
						args.pageSize,
						args.cursor,
					),
				},
			}),
			transformResponse: (res: any): any => {
				if (res.data) {
					const products = res.data.site.category.products.edges.map(
						(edge: any) => convertBigCommerceProduct(edge),
					);

					const pageInfo = { ...res.data.site.category.products.pageInfo };

					return {
						pageInfo,
						products,
					};
				}

				return undefined;
			},
		}),
	}),
});

export const { useLazyGetCategoryQuery, useLazyGetCategoryPageQuery } =
	categoryApi;
