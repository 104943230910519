import { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import SingleImageWithCta from '../SingleImageWithCta/SinglemageWithCta';

import { StyledImageBanner } from './Styled';

type Props = {
	orientation: 'landscape' | 'portrait';
	images: {
		image: {
			desktopImage: string;
			mobileImage?: string;
			alt?: string;
		};
		ctaLink: string;
		ctaText: string;
	}[];
	showButton: boolean;
	showText: boolean;
	imageText?: string;
	className?: string;
	isLoading: boolean;
	isBottomBanner?: boolean;
};

const ImageBanner = ({
	orientation,
	images,
	showButton = true,
	showText,
	imageText,
	className = '',
	isLoading,
	isBottomBanner,
}: Props) => {
	const [gridGap, setGridGap] = useState<string>('2rem');
	const [gridColumns, setGridColumns] = useState<string>('');

	useEffect(() => {
		switch (images.length) {
			case 1:
				setGridColumns('1fr');

				break;
			case 2:
				setGridGap(orientation === 'landscape' ? '.25rem' : '2rem');
				setGridColumns('repeat(2, 1fr)');

				break;
			case 3:
				setGridColumns('repeat(3, 1fr)');

				break;

			default:
				break;
		}
	}, [images]);

	return (
		<StyledImageBanner
			gridGap={gridGap}
			gridColumns={gridColumns}
			className={`${className} image-banner`}
		>
			<div className="styled-image-cta-container">
				{images.map((image) => (
					<SingleImageWithCta
						imageData={image}
						key={uuid()}
						showButton={showButton}
						showText={showText}
						imageText={imageText || ""}
						isLoading={isLoading}
						isBottomBanner={isBottomBanner}
					/>
				))}
			</div>
		</StyledImageBanner>
	);
};

export default ImageBanner;
