import { MenuList } from "./Menu.Styled";

interface MenuProps {
  children?: any;
}

const Menu: React.FC<MenuProps> = ({ children }) => {
  return <MenuList>{children}</MenuList>;
};

export default Menu;
