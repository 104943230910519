import React, { useState } from "react";
import SwiperType, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { productApi } from "app/api/product/productApi";

import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";
import Typography from "components/common/ui/Typography/Typography";
import ProductCard from "components/ProductCard/ProductCard";

import useDataLayer from "datalayer/useDataLayer";
import { Product } from "ts/types";

import { StyledButton, StyledWrapper } from "./ProductRecsCarousel.Styled";

import "swiper/css";
import "swiper/css/navigation";

type Props = {
	id: string;
	title: string;
	skuList: string[];
	forceLoading?: boolean;
	hideWhilstLoading?: boolean;
};

const ProductRecsCarousel = ({
	id,
	title,
	skuList,
	forceLoading = false,
	hideWhilstLoading = false,
	...props
}: Props) => {
	const [swiper, setSwiper] = React.useState<SwiperType | undefined>();
	const [products, setProducts] = React.useState<Product[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { viewItemList } = useDataLayer();

	const [getProductsBySkus] = productApi.useLazyGetProductsBySkusQuery();

	const prevButton = React.useRef(null);
	const nextButton = React.useRef(null);

	const dataLayer = useDataLayer();

	React.useEffect(() => {
		if (swiper && skuList && products) {
			swiper.update();
		}
	}, [products, skuList, swiper]);

	React.useEffect(() => {
		setIsLoading(true);
		getProductsBySkus(skuList)
			.unwrap()
			.then((data) => {
				if (!data) {
					setIsLoading(false);
					return;
				}

				setProducts(data as Product[]);
				setIsLoading(false);

				if (products.length !== 0) {
					viewItemList(products, id, title);
				}
			});
	}, [products, skuList]);

	const handleProductClick = (product: Product) => {
		dataLayer.selectItem(product, id, title);
	};

	return (
		<StyledWrapper {...props}>
			<div className="header">
				<Typography variant="heading" size="small">
					{title}
				</Typography>
				<div className="header__nav">
					<StyledButton ref={prevButton}>
						<IconChevron direction="left" />
					</StyledButton>
					<StyledButton ref={nextButton}>
						<IconChevron direction="right" />
					</StyledButton>
				</div>
			</div>
			<div>
				<Swiper
					onSwiper={setSwiper}
					spaceBetween={4}
					breakpoints={{
						320: {
							slidesPerView: 1
						},
						375: {
							slidesPerView: 2
						},
						551: {
							slidesPerView: "auto"
						}
					}}
					modules={[Navigation]}
					navigation={{
						enabled: true,
						prevEl: prevButton.current,
						nextEl: nextButton.current,
					}}
				>
					{(isLoading || forceLoading || !products) && !hideWhilstLoading
						? skuList.map((sku) => (
								<SwiperSlide key={sku}>
									<ProductCard
										triggeredFrom="CAROUSEL" loading
									/>
								</SwiperSlide>
						  ))
						: products.map((product) => (
								<SwiperSlide key={product.id}>
									<ProductCard
										triggeredFrom="CAROUSEL"
										idPrefix={id}
										product={product}
										onClick={() => handleProductClick(product)}
									/>
								</SwiperSlide>
						  ))}
				</Swiper>
			</div>
		</StyledWrapper>
	);
};

export default ProductRecsCarousel;
