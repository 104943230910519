/* eslint-disable no-template-curly-in-string */
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { boolean, object, ref, string } from "yup";

export type RegistrationFormScheme = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;

	address1: string;
	address2: string;
	city: string;
	postal_code: string;
	state_or_province: string;
	country_code: string;

	password: string;
	passwordConfirmation: string;

	privacyPolicy: boolean;
	newsletterPreference: string;
	loyaltyProgram: boolean;
	accountType: string;
};

export const defaultValues = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",

	address1: "",
	address2: "",
	city: "",
	postal_code: "",
	state_or_province: "",
	country_code: "",

	password: "",
	passwordConfirmation: "",

	privacyPolicy: false,
	newsletterPreference: "",
	loyaltyProgram: false,
	accountType: "email",
} as RegistrationFormScheme;

const passwordRegex =
	/^(?=.*[a-zA-Z])(?=.*[\d])[a-zA-Z\d!@#$%^&*()_+={}|[\]\\';:\/?,.~`-]{7,}$/;

export const registrationSchema = object().shape({
	firstName: string()
		.label("First Name")
		.required("form.validation.first_name.required")
		.min(2),
	lastName: string()
		.label("Last Name")
		.required("form.validation.last_name.required")
		.min(2),
	email: string()
		.label("Email")
		.email("form.validation.email.email")
		.required("form.validation.email.required"),
	phone: string()
		.label("Phone")
		.test({
			message: "form.validation.phone.required",
			test: (value) => {
				if (!value) {
					return false;
				}

				return value.length > 3;
			},
		})
		.test({
			message: "form.validation.phone.invalid_country",
			test: (value = "", ctx) => {
				if (!ctx.parent.country_code) {
					return false;
				}

				try {
					return isValidPhoneNumber(value, ctx.parent.country_code);
				} catch {
					return false;
				}
			},
		})
		.required("form.validation.phone.required"),

	address1: string()
		.label("Address Line 1")
		.required("form.validation.address1.required"),
	address2: string(),
	city: string().label("Town/City").required("form.validation.city.required"),
	country_code: string()
		.label("Country")
		.required("form.validation.country.required"),
	postal_code: string()
		.label("ZIP Code")
		.required("form.validation.postal_code.required"),

	password: string()
		.label("Password")
		.required("form.validation.password.required")
		.test("isValidPassword", "form.validation.password.alpha_num", (value) => {
			if (!value) {
				return false;
			}
			return passwordRegex.test(value);
		})
		.test("isPasswordShort", "form.validation.password.min", (value) => {
			if (!value) {
				return false;
			}
			return value.length >= 7;
		}),

	passwordConfirmation: string()
		.label("Confirm Password")
		.oneOf([ref("password")], "form.validation.password_confirmation.invalid")
		.required("form.validation.password.confirmed"),
	privacyPolicy: boolean()
		.label("Privacy Policy")
		.required("form.validation.privacy_policy.required")
		.oneOf([true], "form.validation.privacy_policy.required"),
	loyaltyProgram: boolean().oneOf([true, false], ""),
});

export const registrationSchemeResolver = yupResolver(registrationSchema);
