import styled from "styled-components";

import Typography from "components/common/ui/Typography/Typography";

import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, white } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${remCalc(12)};
`;

export const StyledTypography = styled(Typography)`
  font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
  color: ${dark};
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${remCalc(8)};
`;

export const StyledButton = styled.button`
  font: normal ${remCalc(16)} / ${remCalc(20)} ${Roboto};
  color: ${accountDarkGrey};

  padding: ${remCalc(10)} ${remCalc(16)};
  border: 1px solid ${accountDarkGrey};
  background-color: ${white};

  &:hover,
  &.selected {
    padding: ${remCalc(9)} ${remCalc(15)};
    border: ${remCalc(2)} solid ${dark};
    color: ${dark};
  }
`;
