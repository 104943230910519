import { gql } from "graphql-request";

function wordPressGetRedirectLinks () {
    return {
        operationName: "GetRedirectLinks",
        query: gql`
            query GetRedirectLinks {
                redirection {
                    redirects {
                        origin
                        target
                        code
                    }
                }
            }
        `
    }
}

export default wordPressGetRedirectLinks;