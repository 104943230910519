import MonaLogoMobile from "assets/images/logos/Mona Logo Mobile.svg";
import MonaLogo from "assets/images/logos/Mona Logo.svg";
import MonaManLogoMobile from "assets/images/logos/MonaMan Logo Mobile.svg";
import MonaManLogo from "assets/images/logos/MonaMan Logo.svg";
import Link from "components/common/ui/Link/Link";
import useBrand, { Brand } from "hooks/useBrand";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { aboveMedium } from "utilities/variables/media";

const StyledPicture = styled.picture`
  width: inherit;
`;

const Logo = () => {
  const { t } = useTranslation();
  const { active } = useBrand();
  const link = active === Brand.Global ? "/" : t(active).toLowerCase();

  return (
    <div className={active === Brand.Men ? "men-logo-wrapper" : "logo-wrapper"}>
      <Link to={link} className={"w-100"}>
        <StyledPicture>
          <source
            media={aboveMedium}
            srcSet={active === Brand.Men ? MonaManLogo : MonaLogo}
          />
          <img
            src={active === Brand.Men ? MonaManLogoMobile : MonaLogoMobile}
            width="100%"
            height="100%"
            alt="Mona Fashion"
          />
        </StyledPicture>
      </Link>
    </div>
  );
};

export default Logo;
