import Link from "components/common/ui/Link/Link";
import Divider from "components/common/ui/List/Divider/Divider";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import useBrand, { Brand } from "hooks/useBrand";
import { useTranslation } from "react-i18next";
import { WrapperUl } from "../Header.Styled";

const BrandSwitcher = () => {
  const { t, i18n } = useTranslation();

  const { active } = useBrand();

  if (!i18n.isInitialized) {
    return (
      <WrapperUl>
        <li>
          <Skeleton width={35} />
        </li>
        <Divider />
        <li>
          <Skeleton width={70} />
        </li>
      </WrapperUl>
    );
  }

  return (
    <WrapperUl>
      <li className={active === Brand.Women ? "active" : ""}>
        <Link to={`/${t("women").toLowerCase()}`}>{t("women")}</Link>
      </li>
      <Divider />
      <li className={active === Brand.Men ? "active" : ""}>
        <Link to={`/${t("men").toLowerCase()}`}>{t("men")}</Link>
      </li>
    </WrapperUl>
  );
};
export default BrandSwitcher;
