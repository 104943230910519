import {
	useResetPasswordMutation
} from "app/api/authApi";
import BaseForm from "components/common/form/Form/Form";
import { InputPassword } from "components/common/form/Input/Input";
import Alert, { AlertType } from "components/common/ui/Alert/Alert";
import Button from "components/common/ui/Button/Button";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { Fragment, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "redux/hooks";
import { setPasswordResetMessage } from "redux/UI/uiSlice";
import PathTranslationKey from "utilities/paths";

import { FormStyle } from "./ResetPassword.Styled";
import {
	ResetPasswordFormResolver,
	ResetPasswordFormSchema,
} from "./ResetPasswordForm.Schema";
import { useTranslation } from "react-i18next";

interface Props {
	large: boolean;
}

const ResetPasswordForm: React.FC<Props> = ({ large }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ResetPasswordFormSchema>({
		resolver: ResetPasswordFormResolver,
	});
	const { t } = useTranslation();
	const storeHash = useSelector((state) => state.bcStore.store?.hash);

	const navigate = useLocaleNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [resetPasswordApi, resetPasswordResult] = useResetPasswordMutation();
	const [error, setError] = useState<string[]>([]);
	const [success, setSuccess] = useState("");

	const queryParams = useMemo(() => {
		const email = searchParams.get("email");
		const token = searchParams.get("token");
		const signature = searchParams.get("signature");

		if (email && token && signature && storeHash) {
			const queryParam = new URLSearchParams();
			queryParam.set("hash", storeHash);
			queryParam.set("email", email);
			queryParam.set("token", token);
			queryParam.set("signature", signature);

			return queryParam;
		}

		return undefined;
	}, [searchParams, storeHash]);

	const onSubmit: SubmitHandler<ResetPasswordFormSchema> = (formData) => {
		if (queryParams) {
			setError([]);
			resetPasswordApi({
				params: queryParams,
				data: formData,
			})
				.unwrap()
				.then(({ data }) => {
					dispatch(setPasswordResetMessage(data.message));
					reset();
					navigate(t(PathTranslationKey.SIGN_IN).toString());
				})
				.catch(({ data }) => {
					setError([t(data.error.message)]);
				});
		} else {
			setError(["Reset link invalid"]);
		}
	};

	return (
		<Fragment>
			{error && (
				<Alert type={AlertType.ERROR} title={t("error").toString()} messages={error} />
			)}
			{success && <Alert type={AlertType.SUCCESS} messages={[success]} />}
			<BaseForm styles={FormStyle} onSubmit={handleSubmit(onSubmit)}>
				<InputPassword
					label={t("form.signInPassword") as string}
					required
					{...register("password")}
					errorMsg={errors.password?.message && t(errors.password.message) as string}
					autoComplete={"off"}
				/>
				<InputPassword
					label={t("form.confirmPassword") as string}
					required
					{...register("password_confirmation")}
					errorMsg={errors.password_confirmation?.message && t(errors.password_confirmation.message) as string}
					autoComplete={"off"}
				/>
				<Button
					variant="primary_01"
					type="submit"
					fullWidth={!large}
					loading={resetPasswordResult.isLoading}
					disabled={resetPasswordResult.isLoading}
				>
					{t("common.continue_action")}
				</Button>
			</BaseForm>
		</Fragment>
	);
};

export default ResetPasswordForm;
