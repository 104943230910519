import {
    FieldError,
    FormField,
    SelectField as SelectFieldType,
    SelectInput
} from "types/GravityForm";
import { StyledFieldContainer } from "../GravityForm.Styled";
import InputSelect from "components/common/form/InputSelect/InputSelect";
import { useCallback } from "react";
import { useDispatch } from "redux/hooks";
import { updateSelectFieldValue } from "redux/gravityForm/gravityFormSlice";

interface Props {
	field: FormField & Partial<SelectFieldType>;
	fieldErrors: FieldError[];
}

const SelectField = ({ field, fieldErrors }: Props) => {
    const { id } = field;
	const dispatch = useDispatch();

	const updateValues = useCallback(
		(values: SelectInput) => {
			dispatch(updateSelectFieldValue({ id, value: values.value || '' }));
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		updateValues({ value: String(event) });
	};

    return <StyledFieldContainer
        layoutGridColumnSpan={field.layoutGridColumnSpan}
        isError={Boolean(fieldErrors.length)}
        visibility={field.visibility}
    >
        <InputSelect
            name={String(field.id)}
            label={field.label}
            required={field.isRequired}
            options={field.choices?.map(
                (choice) => {
                    return {
                        label: choice.value,
                        value: choice.value
                    }
                }
            ) || []}
            errorMsg={
                fieldErrors[0]?.message.includes("required")
                    ? "This field is required."
                    : fieldErrors[0]?.message
            }
            value=""
            onChange={handleChange}
            isSearchable
        />
    </StyledFieldContainer>
};

export default SelectField;
