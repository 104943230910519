/**
 * This file contains const valuesfor z-index 
 * css values to be used thoughtout.
 * 
 * This allows a centeral location for z-index
 * to be managed.
 */

export const overlayZIndex = 1000;
export const menuZIndex = overlayZIndex + 5;

export const productCardBadge = 10;
export const productCardVariants = productCardBadge + 1;