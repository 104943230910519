import React, {
	InputHTMLAttributes,
	ReactElement,
	useEffect,
	useState,
} from "react";
import Label from "../Label/Label";
import InputWrapper from "./InputWrapper";
import { StyledInput } from "./Input.Styled";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import ErrorMessage from "./InputError/InputError";
import { useSelector } from "redux/hooks";
import { parseNumber, parsePhoneNumber } from "libphonenumber-js";

export interface InputProps {
	className?: string;
	label?: string | ReactElement;
	disabled?: boolean;
	required?: boolean;
	filesAccepted?: string[];
	onChange?: (v: any) => void;
	onBlur?: (v: any) => void;
	name?: string;
	errorMsg?:
		| string
		| FieldError
		| Merge<FieldError, FieldErrorsImpl<any>>
		| Partial<{ type: string | number; message: string }>
		| undefined;
	isError?: boolean;
}

interface InputType {
	type: string;
}

export const Input = React.forwardRef<
	HTMLInputElement,
	InputProps & InputType & InputHTMLAttributes<{}>
>(
	(
		{
			className,
			type = "text",
			label,
			required = false,
			filesAccepted,
			...props
		},
		ref,
	) => {
		// Remove spaces & add '.' for each file type
		const acceptedFilesArray = filesAccepted?.map((fileType: string) => {
			let formatedFileType = fileType.replaceAll(" ", "");
			return `.${formatedFileType}`;
		});

		return (
			<InputWrapper className={className}>
				<Label required={required} htmlFor={props.name}>
					{label}
				</Label>
				<StyledInput
					ref={ref}
					type={type}
					accept={acceptedFilesArray?.join(",")}
					onChange={props.onChange}
					onBlur={props.onBlur}
					name={props.name}
					{...props}
				/>
				<ErrorMessage message={props.errorMsg} />
			</InputWrapper>
		);
	},
);

export const InputText = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="text" />;
});

export const InputNumber = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="number" />;
});

export const InputEmail = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="email" />;
});

export const InputPassword = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="password" />;
});

export const InputCheckbox = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="checkbox" />;
});

export const InputFileUpload = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}>
>((props, ref) => {
	return <Input {...props} ref={ref} type="file" />;
});

export const InputPhoneNumber = React.forwardRef<
	HTMLInputElement,
	InputProps & InputHTMLAttributes<{}> & { countryCode: string }
>((props, ref) => {
	const store = useSelector((state) => state.bcStore.store);
	
	const appendPhonePrefix = (value: string) => {
		if (store) {
			if (!value) {
				return store.phonePrefix;
			}

			if (value.length < store.phonePrefix.length) {
				return store.phonePrefix;
			}

			if (!value.startsWith(store.phonePrefix)) {
				return `${store.phonePrefix}${value}`;
			}
		}

		return value;
	};

	const handleChange = (e: any) => {
		let number = appendPhonePrefix(e.target.value);

		if (props.countryCode) {
			try {
				const parsedNumber = parsePhoneNumber(number, props.countryCode as any);
				if (parsedNumber) {
					number = parsedNumber.number;
				}
			} catch {}
		}

		e.target.value = number;

		props.onChange?.(e);
	};

	return (
		<Input
			{...props}
			value={props.value}
			onChange={handleChange}
			ref={ref}
			type="text"
		/>
	);
});
