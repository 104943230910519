import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import ButtonGroup from "components/common/ui/ButtonGroup/ButtonGroup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbType } from "ts/types";
import PlpSlider from "../PlpSlider/PlpSlider";
import PlpSort from "../PlpSort/PlpSort";
import { ButtonGroupStyles, FilterSort, Wrapper } from "./PlpNavSort.Styled";

interface Props {
	breadcrumbs?: BreadcrumbType[];
	layout: number;
	dispatch: (event: {}) => void;
	isLoading?: boolean;
}

const PlpNavSort: React.FC<Props> = ({
	breadcrumbs,
	layout,
	dispatch,
	isLoading,
}) => {
	const { t } = useTranslation();

	const onSliderChange = (value: number | number[]) => {
		window.localStorage.setItem("category_layout", value.toString());

		dispatch({
			type: "layout_change",
			payload: value,
		});
	};

	const labels = useMemo(() => {
		if (!breadcrumbs) {
			return undefined;
		}

		return breadcrumbs.map((breadcrumb) => breadcrumb.name);
	}, [breadcrumbs]);

	return (
		<Wrapper>
			<Breadcrumb
				loading={isLoading === undefined ? !breadcrumbs : isLoading}
				labels={labels}
			/>
			<FilterSort>
				<PlpSlider value={layout} onChange={onSliderChange} />
				<ButtonGroup styles={ButtonGroupStyles}>
					<></>
					<PlpSort />
				</ButtonGroup>
			</FilterSort>
		</Wrapper>
	);
};

export default PlpNavSort;
