import IconClose from "../Icons/IconClose/IconClose";
import Button from "./Button";

interface CloseButtonProps {
  onClick: Function;
  className?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  onClick = () => {},
  className = "",
}) => {
  return (
    <Button
      onClick={() => onClick()}
      icon={<IconClose />}
      className={`btn-close ${className}`}
    />
  );
};

export default CloseButton;
