import MoreColoursDropdown from "components/common/ui/Icons/MoreColoursDropdown";
import Modal from "components/common/ui/Modal/Modal";
import SisterColourSKUs from "components/ProductSisterSKUs/SisterColourSKUs";
import useBreakpoints from "hooks/useBreakpoints";
import { useState } from "react";
import { css } from "styled-components";
import { ModalProps } from "ts/interfaces";
import { ProductCustomField } from "ts/types";
import { PORTAL_MODAL } from "utilities/portals";
import { aboveMedium } from "utilities/variables/media";
import { StyledButton } from "./MoreColours.Styled";
import { useTranslation } from "react-i18next";

interface Props {
	id?: string;
	field: ProductCustomField;
	onClick?: () => void;
	isOpen?: boolean;
	close?: () => void;
	portalId?: string;
}

const MoreColors: React.FC<Props> = ({
	id = "",
	field,
	isOpen = false,
	close = () => {},
	onClick = () => {},
	portalId,
}) => {
	const [modalOpen, setModalOpen] = useState<boolean>(isOpen);

	const handleClose = () => {
		setModalOpen(false);
		close();
	};

	const handleButtonClick = () => {
		if (modalOpen) {
			setModalOpen(false);
			close();
		} else {
			setModalOpen(true);
			onClick();
		}
	};

	const { t } = useTranslation();

	return (
		<div className="MoreColours">
			<StyledButton
				className={modalOpen ? "active" : ""}
				onClick={handleButtonClick}
				icon={<MoreColoursDropdown />}
				position="after"
				data-target={id}
			>
				{t("more_colors")}
			</StyledButton>
			<MoreColoursModal
				id={id}
				field={field}
				isOpen={modalOpen}
				close={handleClose}
				portalId={portalId}
			/>
		</div>
	);
};

export default MoreColors;

const ModalContainerStyles = css`
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 40px 20px 24px;

	padding-bottom: calc(24px + env(safe-area-inset-bottom,0)) !important;

	.btn_modal-close {
		right: 13px;
		top: 13px;
		i {
			font-size: 20px;
		}
	}

	@media ${aboveMedium} {
		padding: 40px 16px 8px;
	}
`;

const MoreColoursModal: React.FC<Props & ModalProps> = ({
	field,
	isOpen,
	close,
	portalId = "",
	id,
}) => {
	const { large } = useBreakpoints();

	if (!isOpen) {
		return null;
	}
	const portal = large ? portalId : PORTAL_MODAL;

	return (
		<Modal
			id={id}
			onClose={() => close()}
			options={{
				portalId: portal,
				showCloseButton: true,
				closeOnOutsideClick: true,
			}}
			additionalStyles={{
				container: ModalContainerStyles,
			}}
		>
			<SisterColourSKUs field={field} />
		</Modal>
	);
};
