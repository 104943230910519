import Typography from "components/common/ui/Typography/Typography";
import ProductBadge from "components/ProductBadge/ProductBadge";
import AddToCart from "components/ProductCard/AddToCart/AddToCart";

import {
	getCustomField,
	getSisterColourSKUs,
} from "app/utils/bigcommerceProductUtil";
import Carousel from "components/common/ui/Carousel/Carousel";
import Price from "components/common/ui/Price/Price";
import MoreColors from "components/MoreColours/MoreColours";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "redux/hooks";
import { Product } from "ts/types";
import {
	BelowImage,
	ContentLeft,
	ContentRight,
	ProductCardWrapper,
	ProductImages,
	ProductTitleLink,
} from "./ProductCard.Styled";
import ProductCardSkeleton from "./ProductCardSkeleton";
import Variants from "./Variants/Variants";
import { useTranslation } from "react-i18next";
import PathTranslationKey from "utilities/paths";

interface Props {
	triggeredFrom: string;
	idPrefix?: string;
	product?: Product;
	layout?: number;
	onClick?: (event: any) => void;
	loading?: boolean;
	className?: string;
	highResImages?: boolean;
	hide?: {
		sisterSkus?: boolean;
	};
	onAddToCart?:Function;
}

const ProductCard: React.FC<Props> = ({
	triggeredFrom = 'PAGE',
	idPrefix = "",
	product,
	layout = 1,
	onClick = () => {},
	loading = false,
	className = "",
	highResImages = false,
	hide = {
		sisterSkus: false,
	},
	onAddToCart,
}) => {
	const { t, ready } = useTranslation();
	const [addToCartOpen, setAddToCartOpen] = useState(false);
	const [moreColousOpen, setMoreColoursOpen] = useState(false);

	// Get second currency for Croatia store
	const secondCurrency = useSelector((state) =>
		state.bcStore.currencies.find((currency) => currency.name === "Kuna"),
	);

	useEffect(() => {
		setAddToCartOpen(false);
		setMoreColoursOpen(false);
	}, [layout]);

	const sisterSkusCustomField = useMemo(() => {
		if (!product) {
			return;
		}

		return getSisterColourSKUs(product);
	}, [product]);

	const productBadgeData = useMemo(() => {
		if (!product) {
			return;
		}

		const textBadge = getCustomField(product, "badge-text");
		const imageBadge = getCustomField(product, "badge-image");

		return {
			text:
				product.availability === "Preorder"
					? t("text.preorder")
					: textBadge?.value || "",
			image: imageBadge?.value || "",
		};
	}, [product, ready, t]);

	// handlers
	const handleAddToCartClick = () => {
		setAddToCartOpen(!addToCartOpen);
	};

	const handleMoreColoursClick = () => {
		setMoreColoursOpen(!moreColousOpen);
	};

	if (loading) {
		return <ProductCardSkeleton layout={layout} />;
	}

	if (!product) {
		return null;
	}

	const linkData = {
		to: `${t(PathTranslationKey.PRODUCT)}${product.path}`,
	};

	const variantsModalId = `${product.entityId}_variants`;
	const sisterSkusModalId = `${product.entityId}_sister-skus`;

	const id = idPrefix ? `${idPrefix}_${product.id}` : product.id;

	return (
		<ProductCardWrapper className={`ProductCard ${className}`}>
			<ProductImages id={id}>
				{layout < 2 && <ProductBadge {...productBadgeData} />}
				<Carousel
					link={linkData}
					images={product.images}
					fixed={layout === 2}
					onSlideClick={onClick}
					highRes={highResImages}
				/>
				<Variants
					triggeredFrom={triggeredFrom}
					id={variantsModalId}
					isOpen={addToCartOpen}
					close={() => {
						setAddToCartOpen(false);
					}}
					onAddToCart={onAddToCart}
					product={product}
				/>
			</ProductImages>

			{layout < 2 && (
				<BelowImage>
					<ContentLeft>
						<ProductTitleLink {...linkData} onClick={onClick}>
							{product.name}
						</ProductTitleLink>

						<Typography className="price" variant="body" size="small">
							<Price
								price={product.price.value}
								secondCurrency={secondCurrency}
							/>
						</Typography>
						{!hide.sisterSkus && sisterSkusCustomField && (
							<MoreColors
								id={sisterSkusModalId}
								field={sisterSkusCustomField}
								isOpen={moreColousOpen}
								close={handleMoreColoursClick}
								onClick={handleMoreColoursClick}
								portalId={id}
							/>
						)}
					</ContentLeft>
					{product && (
						<ContentRight className={addToCartOpen ? "active" : ""}>
							<AddToCart
								onClick={handleAddToCartClick}
								data-target={variantsModalId}
							/>
						</ContentRight>
					)}
				</BelowImage>
			)}
		</ProductCardWrapper>
	);
};

export default ProductCard;
