import { gql } from "graphql-request";

const wordPressGetGravityFormById = (id: string | number) => {
	return {
		operationName: "GetGravityFormById",
		variables: {
			id,
		},
		query: gql`
			query GetGravityFormById($id: ID!) {
				gfForm(id: $id, idType: DATABASE_ID) {
					databaseId
					title
					description
					submitButton {
						text
					}
					confirmations {
						isDefault
						message
					}
					formFields {
						nodes {
							id
							type
							layoutGridColumnSpan
							visibility
							... on NameField {
								...NameFieldFields
							}
							... on AddressField {
								...AddressFieldFields
							}
							... on EmailField {
								...EmailFieldFields
							}
							... on PhoneField {
								...PhoneFieldFields
							}
							... on TextAreaField {
								...TextAreaFieldFields
							}
							... on TextField {
								...TextFieldFields
							}
							... on ConsentField {
								...ConsentFieldFields
							}
							... on FileUploadField {
								...FileUploadFieldFields
							}
							... on SelectField {
								...SelectFieldFields
							}
						}
					}
				}
			}
			fragment NameFieldFields on NameField {
				id
				label
				isRequired
				inputs {
					id
					... on NameInputProperty {
						isHidden
						key
						label
						customLabel
						placeholder
					}
				}
			}
			fragment EmailFieldFields on EmailField {
				id
				isRequired
				label
				placeholder
			}
			fragment AddressFieldFields on AddressField {
				id
				label
				isRequired
				inputs {
					... on AddressInputProperty {
						isHidden
						key
						label
						customLabel
						placeholder
					}
				}
			}
			fragment PhoneFieldFields on PhoneField {
				id
				isRequired
				label
				placeholder
				isRequired
			}
			fragment TextAreaFieldFields on TextAreaField {
				id
				isRequired
				label
				placeholder
				size
			}
			fragment TextFieldFields on TextField {
				id
				isRequired
				label
				placeholder
			}
			fragment ConsentFieldFields on ConsentField {
				id
				isRequired
				label
				checkboxLabel
			}
			fragment FileUploadFieldFields on FileUploadField {
				id
				errorMessage
				fileUploadValues {
					filename
					url
				}
				allowedExtensions
				isRequired
				label
				maxFileSize
				maxFiles
			}
			fragment SelectFieldFields on SelectField {
				id
				label
				isRequired
				choices {
					... on SelectFieldChoice {
					isSelected
					text
					value
					}
				}
			}
		`,
	};
};

export default wordPressGetGravityFormById;
