import React, { Fragment, useMemo } from "react";
import { useSelector } from "redux/hooks";
import { Currency } from "types/BigCommerce";

export const FormatPriceToCurrency = (price: number, currency: Currency) => {
	if (!currency) return "";

	const {
		symbol,
		symbolPlacement,
		decimalToken,
		thousandsToken,
		decimalPlaces,
	} = currency.display;

	const formattedPrice = price.toFixed(decimalPlaces);
	const [integerPart, decimalPart] = formattedPrice.split(".");
	const integerPartWithThousandsToken = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		thousandsToken,
	);
	const formattedDecimalPart = decimalPart
		? `${decimalToken}${decimalPart}`
		: "";

	const nextFormattedPrice =
		symbolPlacement === "LEFT"
			? `${symbol} ${integerPartWithThousandsToken}${formattedDecimalPart}`
			: `${integerPartWithThousandsToken}${formattedDecimalPart} ${symbol}`;

	return nextFormattedPrice;
};

const Price = ({
	price,
	secondCurrency,
}: {
	price: number;
	secondCurrency?: Currency | undefined;
}) => {
	const { currencies } = useSelector((state) => state.bcStore);

	const activeCurrency = useMemo(() => {
		return currencies.find((currency) => currency.isActive) as Currency;
	}, [currencies]);

	const formattedPrice = React.useMemo(() => {
		return FormatPriceToCurrency(price, activeCurrency);
	}, [activeCurrency, price]);

	const secondCurrencyPrice = useMemo(() => {
		// Calculate price for Croatia store

		if (!secondCurrency) {
			return undefined;
		}

		const secondPrice = secondCurrency.exchangeRate
			? parseFloat((price * secondCurrency.exchangeRate).toFixed(2))
			: null;

		if (!secondPrice) {
			return undefined;
		}

		return FormatPriceToCurrency(secondPrice, secondCurrency);
	}, [secondCurrency]);

	return (
		<>
			{formattedPrice}
			{secondCurrencyPrice && (
				<Fragment>
					{" / "}
					<span style={{fontWeight: 700}}>{secondCurrencyPrice}</span>
				</Fragment>
			)}
		</>
	);
};

export default Price;
