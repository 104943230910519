import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { accountDarkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(props) => !props.isOpen && "none"};
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledOverlay = styled.div`
  background-color: rgba(33, 37, 41, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${aboveMedium} {
    align-items: center;
  }
`;

export const StyledContainer = styled.div`
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  padding-bottom: env(safe-area-inset-bottom, 0);
  width: 100%;

  @media ${aboveMedium} {
    padding: 32px;
    max-width: 816px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${aboveMedium} {
    button {
      position: absolute;
      margin: 16px;
      top: 0;
      right: 0;
    }
  }
`;

export const StyledHeading = styled(Typography)`
  text-transform: capitalize;
  font: bold 24px/29px ${Roboto};
  text-align: center;

  @media ${aboveMedium} {
    flex: 1;
  }
`;

export const StyledContent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledText = styled(Typography)`
  margin-top: 8px;
  font: normal 16px/20px ${Roboto};
  color: ${accountDarkGrey};

  @media ${aboveMedium} {
    margin-top: 0;
  }
`;

export const StyledLinksContainer = styled.div`
  padding-bottom: calc(env(safe-area-inset-bottom, 0));
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media ${aboveMedium} {
    justify-content: flex-start;
  }

  a {
    &:hover {
      color: white;
    }
  }
`;
