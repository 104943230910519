import useBreakpoints from "hooks/useBreakpoints";
import { ReactComponent as DropdownOpen } from "assets/images/svg/DropdownOpen.svg";
import { ReactComponent as DropdownClose } from "assets/images/svg/DropdownClose.svg";
import Icon from "./Icon";
import styled, { css } from "styled-components";

interface DropdownArrowProps {
  open?: boolean;
}

const StyledIcon = styled(Icon)`
  margin-right: 0;
`;

const DropdownArrow: React.FC<DropdownArrowProps> = ({ open = false }) => {
  return <StyledIcon icon={open ? <DropdownClose /> : <DropdownOpen />} />;
};

export default DropdownArrow;
