import useDataLayer from "datalayer/useDataLayer";
import { Portal } from "react-portal";
import { useLocalStorage } from "usehooks-ts";
import CookiePolicyBanner from "./CookiePolicyBanner";

type Props = {
	children: any;
};

const CookiePolicy = ({ children }: Props) => {
	const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
		"cookies_accepted",
		false,
	);
	const { updateConsent } = useDataLayer();

	const onSubmit = (value: any) => {
		if (
			value.necessary_cookies !== undefined ||
			value.necessary_cookies !== ""
		) {
			updateConsent(
				value.marketing_cookies,
				value.statistical_cookies,
				value.permanent_cookies,
			);
			setCookiesAccepted(true);
		}
	};

	return (
		<>
			{!cookiesAccepted && (
				<Portal node={document && document.getElementById("portal__modal")}>
					<CookiePolicyBanner onSubmit={onSubmit} />
				</Portal>
			)}
			{children}
		</>
	);
};

export default CookiePolicy;
