import { useLazyGetCategoryQuery } from "app/api/category/categoryApi";
import { pathsMatch } from "app/utils/urlUtils";
import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import PlpDetails from "components/plp/PlpDetails/PlpDetails";
import PlpNavSort from "components/plp/PlpNavSort/PlpNavSort";
import PlpPagination from "components/plp/PlpPagination/PlpPagination";
import PlpProductGrid from "components/plp/PlpProductGrid/PlpProudctGrid";
import useDataLayer from "datalayer/useDataLayer";
import useCategoryPagination, { PaginationProps } from "hooks/useCategoryPagination";
import usePageContent from "hooks/usePageContent";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router";
import { PageType } from "ts/enums";
import { Category, Product } from "ts/types";
import PathTranslationKey from "utilities/paths";
import Page from "../../components/common/ui/Page/Page";
import { useSearchParams } from "react-router-dom";

interface State {
	layout: number;
	loading: boolean;
	category: Category | undefined;
	showNoProductsError: boolean;
}

export const PRODUCTS_PER_PAGE_FALLBACK = 48;

const initialState: State = {
	layout: Number(window.localStorage.getItem("category_layout")) || 0,
	loading: true,
	category: undefined,
	showNoProductsError: false,
};

const reducer = (state: State, action: any): State => {
	switch (action.type) {
		case "layout_change":
			return { ...state, layout: action.payload };
		case "set_category":
			return { ...state, category: action.payload };
		case "set_loading":
			return { ...state, loading: action.payload };
		case "show_no_products_error":
			return { ...state, showNoProductsError: action.payload };
		default:
			return state;
	}
};
const ProductLandingPage = () => {
	const { t } = useTranslation();
	const dataLayer = useDataLayer();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [prevPath, setPrevPath] = useState("");

	const location = useLocation();
	const pagination = useCategoryPagination(state.category, 48);
	const pathMatch = useMatch(`/:locale${t(PathTranslationKey.CATEGORY)}/*`);
	const [searchParams] = useSearchParams();

	const [getCategory, getCategoryResult] = useLazyGetCategoryQuery();
	const resetPageScroll = usePageContent();

	useEffect(() => {
		/**
		 * This reset state data when the location
		 * changes from one category to another
		 */
		if (pathMatch) {
			if (!pathsMatch(prevPath, location.pathname)) {
				setPrevPath(location.pathname);
				dispatch({
					type: "set_loading",
					payload: true,
				});

				resetPageScroll.scrollToTop();

				const path = `/${pathMatch.params["*"]}`;
				getCategory(path, false);
			}
		}
	}, [
		location.pathname
	]);

	useEffect(() => {
		if (getCategoryResult.isSuccess) {

			const category = getCategoryResult.data;

			if (state.category !== category) {
				dispatch({
					type: "set_category",
					payload: category,
				});
			}

			dataLayer.pageView(
				PageType.CATEGORY,
				category.breadcrumbs.map(
					(breadcrumb) => breadcrumb.name
				).join(' > ')
			);
		}
	}, [
		getCategoryResult
	]);

	useEffect(() => {
		if (!pagination.uninitialized && !pagination.loading) {
			dispatch({
				type: "set_loading",
				payload: false,
			});
		}
	}, [
		pagination.products,
	]);

	useEffect(() => {
		handlePageChange(pagination.activePage);
	}, [searchParams])

	const loading = state.loading;
	const category = state.category;

	const handlePageChange = (page: string | number) => {
		dispatch({
			type: "set_loading",
			payload: true,
		});

		pagination.getPage(page)
		resetPageScroll.scrollToTop()
	};

	const handleProductClick = (product: Product) => {
		if (category) {
			dataLayer.selectItem(product, category?.entityId, category?.name);
		}
	};

	const showNoCategoryError =
		getCategoryResult.isSuccess && !getCategoryResult.data;

	const showNoProductsError =
		!pagination.uninitialized &&
		!pagination.loading &&
		pagination?.meta?.pagination.total === 0;

	const showNoPageError =
		(pagination?.meta?.pagination.total_pages !== 0 && !pagination.products) || pagination.products.length === 0;

	let title = "";

	if (category) {
		title = category.seo.pageTitle ? category.seo.pageTitle : category.name;
	}

	if (showNoCategoryError) {
		return (
			<Page pageType={PageType.CATEGORY} title={title}>
				<PlpNavSort layout={state.layout} dispatch={dispatch} breadcrumbs={[]}/>
				<ErrorContent heading={t("categoryNotFoundHeading")}>
					{t("categoryNotFound")}
				</ErrorContent>
			</Page>
		);
	}

	if (showNoProductsError) {
		return (
			<Page pageType={PageType.CATEGORY} title={title}>
				<PlpNavSort layout={state.layout} dispatch={dispatch} breadcrumbs={[]}/>
				<ErrorContent heading={t("noProductsFoundHeading")}>
					{t("noProductsFound")}
				</ErrorContent>
			</Page>
		);
	}

	return (
		<Page pageType={PageType.CATEGORY}>
			<PlpNavSort layout={state.layout} dispatch={dispatch} breadcrumbs={category?.breadcrumbs} />
			{!loading && showNoPageError && (
				<ErrorContent heading={t("invalidPageHeading")}>
					{t("invalidPage")}
				</ErrorContent>
			)}
			<PlpProductGrid
				layout={state.layout}
				loading={loading}
				productsPerPage={48}
				category={category}
				products={loading ? [] : pagination.products}
				onProductClick={handleProductClick}
			/>
			<PlpPagination
				loading={loading}
				pagination={pagination.meta?.pagination || {} as PaginationProps}
				onChange={handlePageChange}
			/>
			<PlpDetails
				loading={loading}
				name={category?.name || ""}
				description={category?.description || ""}
			/>
		</Page>
	)
};

export default ProductLandingPage;
