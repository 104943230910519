import ProductCard from "components/ProductCard/ProductCard";
import useBreakpoints, { breakpoints } from "hooks/useBreakpoints";
import { PRODUCTS_PER_PAGE_FALLBACK } from "pages/plp/ProductLandingPage";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { Category, Product } from "ts/types";
import { ProductGridWrapper, ProductRow } from "./PlpProductGrid.Styled";
import PlpProductGridLoading from "./PlpProductGridLoading";
import { useCurrentBreakpoint } from "use-react-breakpoint";

const getRowsForStandardGrid = (
	layout: number,
	large: boolean,
	productCards: any[],
): any[] => {
	const itemsPerRow = layout !== 2 ? (large ? 4 : 2) : large ? 8 : 4;

	const rows = [] as any[];

	let completedProducts = 0;

	for (let i = 0; i < productCards.length; i += itemsPerRow) {
		const rowItems = [];

		for (let j = 0; j < itemsPerRow; j++) {
			const product = productCards[i + j];

			if (product) {
				rowItems.push(product);
			} else {
				break;
			}
		}

		rows.push(rowItems);

		completedProducts += rowItems.length;

		// no more cards to process, break out of loop
		if (completedProducts === productCards.length) {
			break;
		}
	}

	return rows;
};

/**
 * This function is for validating a given customer row break
 * value, ensure it doesn't exceed maximun values for a given
 * screen size
 *
 * @param value The value to validate
 * @param isDesktop if the current screen size is considered desktop
 * @returns the validated value
 */
const validateCustomBreak = (value: any, isDesktop: boolean) => {
	if (!value) {
		return undefined;
	}

	if (value < 0) {
		return 0;
	} else {
		if (isDesktop && value > 4) {
			return 4;
		} else if (!isDesktop && value > 2) {
			return 2;
		}

		return value;
	}
};

/**
 * Parse the value from the BigCommerce category meta keywords field
 * that is used to define the custom row breaks
 *
 * @param rowBreaks The value to parse
 * @returns {
 *  desktop: [],
 *  mobile: [],
 * }
 */
const parseRowCustomBreaks = (rowBreaks: string) => {
	const breaksSplit = rowBreaks.split("|");

	const desktopBreakToParse = breaksSplit[0];
	const mobileBreakToParse = breaksSplit.length > 1 ? breaksSplit[1] : "";

	const desktopBreaks = [] as number[];
	const mobileBreaks = [] as number[];

	desktopBreakToParse.split(",").forEach((value) => {
		try {
			if (value) {
				const itemsPerRow = validateCustomBreak(parseInt(value), true);
				desktopBreaks.push(itemsPerRow);
			}
		} catch (err) {
			console.error(err);
		}
	});

	mobileBreakToParse.split(",").forEach((value) => {
		try {
			if (value) {
				const itemsPerRow = validateCustomBreak(parseInt(value), false);
				mobileBreaks.push(itemsPerRow);
			}
		} catch (err) {
			console.error(err);
		}
	});

	return {
		desktop: desktopBreaks,
		mobile: mobileBreaks,
	};
};

const getRowsForCustomGrid = (
	parsedRowBreaks: any,
	isDesktop: boolean,
	productCards: any[],
) => {
	const rows = [] as any[];

	if (productCards.length !== 0) {
		const { desktop, mobile } = parsedRowBreaks;

		const maxPerRow = isDesktop ? 4 : 2;

		const breaksToUse = isDesktop ? desktop : mobile;

		let pageTotalItems = 0;

		// the number of items per row as default (outside of that defined by client)
		pageTotalItems = breaksToUse.reduce((a: any, v: any) => a + v, 0);

		while (pageTotalItems < productCards.length) {
			const remainingForPage = productCards.length - pageTotalItems;

			// see how many items remain for this page, and only create that
			// many or the maximum for the break if remaining is more/equal.
			let thisRow =
				remainingForPage >= maxPerRow ? maxPerRow : remainingForPage;

			breaksToUse.push(thisRow);

			pageTotalItems += thisRow;
		}

		let completedProducts = 0;

		for (let i = 0; i < breaksToUse.length; i++) {
			const itemsPerRow = breaksToUse[i];
			const rowItems = [];

			for (let j = 0; j < itemsPerRow; j++) {
				const productCard = productCards[completedProducts + j];
				if (productCard) {
					rowItems.push(productCard);
				} else {
					break;
				}
			}

			rows.push(rowItems);
			completedProducts += rowItems.length;

			if (completedProducts >= productCards.length) {
				break;
			}
		}

		for (let i = completedProducts; i < productCards.length; i += maxPerRow) {
			const rowItems = [];

			for (let j = 0; j < maxPerRow; j++) {
				const productCard = productCards[i + j];
				if (productCard) {
					rowItems.push(productCard);
				} else {
					break;
				}
			}

			rows.push(rowItems);

			if (completedProducts >= productCards.length) {
				break;
			}
		}
	}

	return rows;
};

interface PlpProductGridProps {
	layout: number;
	category: Category | undefined;
	products: Product[];
	productsPerPage?: number;
	loading?: boolean;
	onProductClick?: (product: Product) => void;
}

const PlpProductGrid: React.FC<PlpProductGridProps> = ({
	layout,
	category,
	products,
	productsPerPage = PRODUCTS_PER_PAGE_FALLBACK,
	loading = false,
	onProductClick = () => {},
}) => {
	const { large } = useBreakpoints()
	const [categoryId, setCategoryId] = useState<number>();
	const [customRowBreaks, setCustomRowBreaks] = useState("");
	const [parsedRowBreaks, setParsedRowBreaks] = useState<any>({
		desktop: [],
		mobile: [],
	});

	const [showSkeleton, setShowSkeleton] = useState(false);

	useEffect(() => {
		if (loading && !showSkeleton) {
			setShowSkeleton(true);
		} else if (!loading && showSkeleton) {
			setShowSkeleton(false);
		}
	}, [loading]);

	useEffect(() => {
		if (category) {
			if (categoryId !== category.entityId) {
				setCategoryId(category.entityId);
				setCustomRowBreaks(customRowBreaks);
				setParsedRowBreaks(parseRowCustomBreaks(category.customRowBreaks));
			}

			if (customRowBreaks !== category.customRowBreaks) {
				setCustomRowBreaks(customRowBreaks);
				setParsedRowBreaks(parseRowCustomBreaks(category.customRowBreaks));
			}
		}
	}, [category]);

	const productCards = useMemo(() => {
		if (!products?.length) {
			return [];
		}

		return products.map((product: Product) => {
			return (
				<ProductCard
					triggeredFrom="CATEGORY"
					key={product.entityId}
					layout={layout}
					product={product}
					onClick={() => onProductClick(product)}
				/>
			);
		});
	}, [products, layout]);

	const rows = useMemo(() => {
		if (!products?.length) {
			return [];
		}

		return layout === 0
			? getRowsForCustomGrid(parsedRowBreaks, large, productCards)
			: getRowsForStandardGrid(layout, large, productCards);
	}, [products, parsedRowBreaks, large, layout]);

	return (
		<ProductGridWrapper isLoading={showSkeleton}>
			{showSkeleton && (
				<PlpProductGridLoading
					large={large}
					layout={layout}
					productCount={productsPerPage}
				/>
			)}
			<div className="product-grid">
				{rows.map((row: any, index: number) => (
					<ProductRow key={`product-row-${index}`} layout={layout}>
						{row}
					</ProductRow>
				))}
			</div>
		</ProductGridWrapper>
	);
};

export default PlpProductGrid;
