import { useLazyGetProductsBySkusQuery } from "app/api/product/productApi";
import { useLazyGetQueryAndFiltersFromPromptsQuery } from "app/api/segmentify/segmentifyApi";
import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import PlpDetails from "components/plp/PlpDetails/PlpDetails";
import PlpNavSort from "components/plp/PlpNavSort/PlpNavSort";
import PlpProductGrid from "components/plp/PlpProductGrid/PlpProudctGrid";
import useDataLayer from "datalayer/useDataLayer";
import { useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageType } from "ts/enums";
import { Category, Product, SegmentifyProduct } from "ts/types";
import Page from "../../components/common/ui/Page/Page";
import { useSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import SEO from "components/common/ui/SEO/SEO";
import Pagination from "components/common/ui/Pagination/Pagination";
import SortOptions from "components/plp/PlpSort/SortOptions";

interface State {
	layout: number;
	loading: boolean;
	category: Category | undefined;
	showNoProductsError: boolean;
}

interface Filter {
	facet: string;
	values: string[];
}

export const PRODUCTS_PER_PAGE_FALLBACK = 48;

const initialState: State = {
	layout: Number(window.localStorage.getItem("category_layout")) || 0,
	loading: true,
	category: undefined,
	showNoProductsError: false,
};

const reducer = (state: State, action: any): State => {
	switch (action.type) {
		case "layout_change":
			return { ...state, layout: action.payload };
		case "set_category":
			return { ...state, category: action.payload, loading: true };
		case "set_loading":
			return { ...state, loading: action.payload };
		case "show_no_products_error":
			return { ...state, showNoProductsError: action.payload };
		default:
			return state;
	}
};

const ProductLandingPageSearch = () => {
	const {
		location: { selectedLocale },
		bcStore: { currencies },
	} = useSelector((state) => state);

	const pageSize = Number(
		process.env.REACT_APP_CATEGORY_PRODUCTS_PER_PAGE ||
			PRODUCTS_PER_PAGE_FALLBACK,
	);

	const [state, dispatch] = useReducer(reducer, initialState);

	const dataLayer = useDataLayer();

	const [page, setPage] = useState<Product[]>([]);
	const [loading, setLoading] = useState(true);

	const [pathMatchSearch] = useSearchParams();
	const [getProductSkus, getProductSkusResult] =
		useLazyGetQueryAndFiltersFromPromptsQuery();
	const [getProductData, getProductDataResult] =
		useLazyGetProductsBySkusQuery();

	const { t } = useTranslation();

	const sort = pathMatchSearch.get("sort");

	const color = pathMatchSearch.getAll("co");
	const brand = pathMatchSearch.getAll("br");
	const size = pathMatchSearch.getAll("si");
	const priceMin = pathMatchSearch.get("pmin");
	const priceMax = pathMatchSearch.get("pmax");
	const pageCurrent = pathMatchSearch.get("page");
	const gender = pathMatchSearch.getAll("ge");
	const categories = pathMatchSearch.getAll("ca");

	const getFilters = () => {
		const filterArray = [];
		if (color.length) {
			filterArray.push({ facet: "colors", values: color });
		}
		if (brand.length) {
			filterArray.push({ facet: "brand", values: brand });
		}
		if (size.length) {
			filterArray.push({ facet: "sizes", values: size });
		}
		if (priceMin || priceMax) {
			filterArray.push({
				facet: "price",
				values: [`${priceMin} - ${priceMax}`],
			});
		}
		if (gender.length) {
			filterArray.push({ facet: "gender", values: gender });
		}
		if (categories.length) {
			filterArray.push({ facet: "category", values: categories });
		}
		return filterArray as Filter[];
	};

	const customer: any = useSelector((state) => state.auth.customer);

	useEffect(() => {
		// geting ids from segmentify

		getProductSkus({
			search: pathMatchSearch.get("q") || "",
			filters: getFilters(),
			sort:
				SortOptions.find((item) => {
					if (item.id === sort) return item.segmentify_param;
				})?.segmentify_param || "",
			customer: customer,
			sessionId: sessionStorage.getItem("sessionId"),
			page: pageCurrent,
			lang: selectedLocale ?? "EN",
			currencyCode: currencies[0]?.code ?? "EUR",
		});
	}, [pathMatchSearch.toString()]);

	useEffect(() => {
		setLoading(true);
		//get results from segmentify and present us its value for BC request
		const productIDs = getProductSkusResult.data?.products.map(
			(product: SegmentifyProduct) => {
				return product.productId;
			},
		);
		if (productIDs !== undefined) {
			getProductData(productIDs);
		}
	}, [getProductSkusResult.data]);

	const pageCount = Math.ceil(
		getProductSkusResult.data?.meta.total /
			getProductSkusResult.data?.meta?.page?.rows,
	);

	const mapOrder = (array: any[], order: string[]) => {
		if (array.length == 0) {
			return [];
		}

		array.sort(function (a: any, b: any) {
			var A = a["sku"],
				B = b["sku"];
			if (order.indexOf(A) > order.indexOf(B)) {
				return 1;
			} else {
				return -1;
			}
		});
		return array;
	};

	useEffect(() => {
		const productIDs = getProductSkusResult.data?.products.map(
			(product: SegmentifyProduct) => {
				return product.productId;
			},
		);

		//get BC products and set it to current page data
		if (getProductDataResult.isFetching === false) {
			if (productIDs && getProductDataResult.data) {
				setPage(mapOrder([...getProductDataResult.data], productIDs));
			} else {
				setPage([]);
			}
			setLoading(false);
		}
	}, [getProductDataResult.isLoading, getProductDataResult.isFetching]);

	const handlePageChange = () => {};

	const handleProductClick = (product: Product) => {
		if (category) {
			dataLayer.selectItem(product, category?.entityId, category?.name);
		}
	};

	const category = state.category;

	const showNoProductsError = getProductSkusResult.data?.meta?.total === 0;

	let title = "";

	if (showNoProductsError) {
		return (
			<Page pageType={PageType.CATEGORY} title={title}>
				<PlpNavSort layout={state.layout} dispatch={dispatch} />
				<ErrorContent heading="No Products Found">
					Looks like we couldn't find any products in this category
				</ErrorContent>
			</Page>
		);
	}

	return (
		<Page pageType={PageType.SEARCH}>
			<SEO
				title={t("search.seo_title") || "MONA"}
				description={t("search.seo_meta_description") || "MONA"}
			/>
			<PlpNavSort
				layout={state.layout}
				dispatch={dispatch}
				isLoading={false}
			/>
			{!loading && showNoProductsError && (
				<ErrorContent heading="Invalid Search">
					Looks like we couldn't find any products for this page
				</ErrorContent>
			)}
			<PlpProductGrid
				layout={state.layout}
				loading={loading}
				productsPerPage={pageSize}
				category={category}
				products={page}
				onProductClick={handleProductClick}
			/>
			<Pagination
				loading={loading}
				totalPages={pageCount}
				onChange={handlePageChange}
			/>
			<PlpDetails
				loading={loading}
				name={category?.name || ""}
				description={category?.description || ""}
			/>
		</Page>
	);
};

export default ProductLandingPageSearch;
