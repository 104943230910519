import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import styled from "styled-components";
import { Breakpoint } from "ts/enums";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, lightestGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

const Container = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
    right: 0;
	margin: auto auto ${remCalc(20)};
	z-index: 5;
	background: rgba(255, 255, 255);
	padding: ${remCalc(24)};
	width: 80%;
	box-shadow: 0px 10px 18px #21252940;
	max-height: 100%;
	overflow: auto;

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		& {
			padding: ${remCalc(12)};
			padding-bottom: calc(24px + env(safe-area-inset-bottom, 0));
			margin: ${remCalc(0)};
			width: 100%;
		}
	}

	display: flex;
	flex-direction: column;
	gap: ${remCalc(16)};

	.cookie-banner-heading {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(20)};

		.details {
			color: ${accountDarkGrey};
		}
	}

	.cookie-banner-heading h3 {
		font-size: ${remCalc(18)};
	}

	form {
		display: flex;
		flex-direction: column;
		gap: ${remCalc(16)};
	}

	.cookie-form-options-control {
		display: flex;
		flex-direction: row;
		gap: ${remCalc(24)};

		label {
			color: ${accountDarkGrey};
		}

		@media screen and (max-width: ${Breakpoint.LARGE}px) {
			& {
				flex-direction: column;
			}
		}
	}

	.cookie-form-submit-control {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
`;

const InputRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-bottom: ${remCalc(16)};
	flex-wrap: wrap;

	&:first-of-type {
		margin-top: ${remCalc(16)};
	}

	@media ${aboveMedium} {
		margin-top: ${remCalc(16)} !important;
		margin-bottom: ${remCalc(16)} !important;
	}

	div.input {
		width: 100%;
	}

	@media ${aboveMedium} {
		flex-wrap: nowrap;
	}
`;

const StyledInputCheckbox = styled(InputCheckbox)`
	flex: 1;
	align-items: center;

	div {
		margin: 0 ${remCalc(8)} 0 0;
	}

	label {
		margin: 0;

	}

	span {
		margin-top: ${remCalc(8)};
	}
`;

const CookieDetailsContainer = styled.section`

	display: grid;
	grid-template-columns: 30% 70%;
	border: 1px solid ${lightestGrey};

	button {
		background: #dedede47;
		width: 100%;
		text-align: left;
		border: 1px solid ${lightestGrey};
		font-weight: normal;
	}

	.active-tab {
		background: white;
		border: white;
		color: ${dark};
	}

	.tab-control,
	.details-control {
		display: flex;
		flex-direction: column;
		justify-content: start;
	}

	.details-control {
		padding: ${remCalc(16)};

		p {
			margin: 0;
		}
	}

	@media screen and (max-width: ${Breakpoint.LARGE}px) {
		.tab-control {
			flex-direction: row;
			flex-wrap: wrap;
		}

		.active-tab {
			background: ${dark};
			border: ${dark};
			color: white;
		}

		& {
			grid-template-columns: 1fr;
		}
	}
`;

export { Container, StyledInputCheckbox, InputRow, CookieDetailsContainer };
