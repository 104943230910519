import { useSelector } from "redux/hooks";
import { VariantButton } from "./ProductVariant.Styled";

interface ProductVariantProps {
  value: string | number;
  onClick: () => void;
  active?: boolean;
  available?: boolean;
}

const ProductVariant: React.FC<ProductVariantProps> = ({
  value,
  onClick,
  active,
  available,
}) => {

  const outOfStockBehavior = useSelector((state) => state.bcStore.settings?.inventory.optionOutOfStockBehavior)

  if(outOfStockBehavior && outOfStockBehavior === "HIDE_OPTION" && !available) {
    return null;
  }

  return (
    <li>
      <VariantButton disabled={!available} className={active ? "active" : ""} onClick={onClick}>
        {value}
      </VariantButton>
    </li>
  );
};

export default ProductVariant;
