import React from "react";
import IconSocial from "../Icons/IconSocial/IconSocial";
import { SocialMediaLinksContainer } from "./ShareBlogPostViaSocialMedia.styled";

interface Props { url?: string }
const ShareBlogPostViaSocialMedia: React.FC<Props> = ({ url }) => {

	const links = [
		{
			icon: <IconSocial type="whatsapp" />,
			href: `whatsapp://send?text=${url}`,
			action: "share/whatsapp/share",
		},
		{
			icon: <IconSocial type="twitter" />,
			href: `https://twitter.com/intent/tweet?text=${url}`,
		},
		{
			icon: <IconSocial type="facebook" />,
			href: `https://facebook.com/sharer/sharer.php?u=${url}`,
		},
		{
			icon: <IconSocial type="linkedin" />,
			href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
		},
		{
			icon: <IconSocial type="email" />,
			href: `mailto:body=${url}`,
		},
		{
			icon: <IconSocial type="telephone" />,
			href: `viber://forward?text=${url}`,
		},
	];

	return (
		<SocialMediaLinksContainer
			flexDirection={"row"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={8}
		>
			{
				links.map((link, key) => (
				<a
					key={key}
					target={"_blank"}
					href={link.href}
					rel={"noreferrer"}
					className={"social-media-link"}
				>
					{link.icon}
				</a>
			))}
		</SocialMediaLinksContainer>
	)
}

export default ShareBlogPostViaSocialMedia;