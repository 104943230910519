import Button from "components/common/ui/Button/Button";
import { CookieDetailsContainer } from "./CookiePolicyBanner.Style";
import { useCallback, useRef, useState } from "react";
import Typography from "components/common/ui/Typography/Typography";
import { useTranslation } from "react-i18next";

function CookiePolicyDetails(): JSX.Element {
	const tabRef = useRef<any>();

	const { t } = useTranslation();

	const cookieDetails: object[] = [
		{
			tab: t("cookie_tab_necessary"),
			data: t("cookie_tab_necessary_details"),
		},
		{
			tab: t("cookie_tab_permanent"),
			data: t("cookie_tab_permanent_details"),
		},
		{
			tab: t("cookie_tab_statistical"),
			data: t("cookie_tab_statistical_details"),
		},
		{
			tab: t("cookie_tab_marketing"),
			data: t("cookie_tab_marketing_details"),
		},
	];

	const [selectedTabDetails, setSelectedTabDetails] = useState<object>({
		...cookieDetails[0],
		current: 0,
	});

	const handleToggleTab = useCallback(
		(key: number) => {
			tabRef.current = key;

			const currentTab: object = {
				...cookieDetails[tabRef.current],
				...{ ...tabRef },
			} as object;

			setSelectedTabDetails(currentTab as object);
		},
		[tabRef],
	);

	return (
		<CookieDetailsContainer>
			<div className="tab-control">
				{cookieDetails.map((cookieDetail: object, key: number) => (
					<Button
						key={key}
						className={
							Object(selectedTabDetails).current === key ? "active-tab" : ""
						}
						variant="primary_02"
						onClick={() => handleToggleTab(key)}
					>
						{Object(cookieDetail).tab}
					</Button>
				))}
			</div>
			<div className="details-control">
				<Typography variant="body" size="small">
					{Object(selectedTabDetails).data}
				</Typography>
			</div>
		</CookieDetailsContainer>
	);
}

export default CookiePolicyDetails;
