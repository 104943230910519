import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { getGender, getGroupId } from "app/utils/bigcommerceProductUtil";
import AddToCartButton from "components/AddToCartButton/AddToCartButton";
import { VariantsContainer } from "components/ProductCard/Variants/Variants.Styled";
import ProductVariants from "components/ProductVariants/ProductVariants";
import IconInfo from "components/common/ui/Icons/IconInfo/IconInfo";
import Typography from "components/common/ui/Typography/Typography";
import { useTranslation } from "react-i18next";
import { setSizeGuideModalIsOpen } from "redux/UI/uiSlice";
import { useDispatch, useSelector } from "redux/hooks";
import { Product, ProductVariant } from "ts/types";
import Measurement from "./Measurement/Measurement";
import {
	Content,
	FooterContent,
	ImageWrapper,
	OverlayHelperText,
	StyledAside,
	StyledTextButton,
} from "./PdpSizeGuide.Styled";
import { getDataForSizeGuide } from "./SizeGuideDataa";
import Female from "./assets/female.png";
import Male from "./assets/male.png";

interface Props {
	product: Product;
}

export enum Gender {
	MALE = "male",
	FEMALE = "female",
}

const PdpSizeGuide: React.FC<Props> = ({ product }) => {
	const { t, ready } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();

	const { sizeGuideModalIsOpen } = useSelector((state) => state.ui);

	const [gender, setGender] = useState<Gender | undefined>();
	const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
	const [sizeGuideData, setSizeGuideData] = useState();

	const [activeMeasurement, setActiveMeasurement] = useState<
		string | undefined
	>();

	const sizeGuideOptions = useMemo(() => {
		const groupId = getGroupId(product);

		if (!groupId) {
			return {
				isShirt: false,
				isBottomApparel: false,
			};
		}

		return {
			isShirt: ["420", "410"].includes(groupId.value),
			isBottomApparel: ["400", "431", "401"].includes(groupId.value),
		};
	}, [product]);

	useEffect(() => {
		if (sizeGuideModalIsOpen) {
			//clear these on open to prevent any weird UI effects
			setSelectedVariant(undefined);
			setSizeGuideData(undefined);
		}
	}, [sizeGuideModalIsOpen]);

	useEffect(() => {
		setGender(getGender(product));
	}, [product]);

	useEffect(() => {
		handleClose();
	}, [location]);

	useEffect(() => {
		if (selectedVariant && gender) {
			const data = getDataForSizeGuide(
				gender,
				Number(selectedVariant.option.label),
				sizeGuideOptions,
			);
			setSizeGuideData(data);
		}
	}, [selectedVariant]);

	const cartButtonText = useMemo(() => {
		if (!selectedVariant) {
			return t("sizeGuide.selectSize");
		}

		if (
			!selectedVariant.isPurchasable ||
			!selectedVariant.inventory.isInStock
		) {
			return t("addToCart.unavailable");
		}

		let addToCartText = t("sizeGuide.addToCart");

		// if there is translations for the add to cart text
		// add the selected variant size
		if (addToCartText !== "sizeGuide.addToCart") {
			addToCartText = addToCartText.replace(
				"{}",
				selectedVariant?.option.label || "",
			);
		}

		return addToCartText;
	}, [selectedVariant]);

	const handleClose = () => {
		dispatch(setSizeGuideModalIsOpen(false));
	};

	if (!gender || !ready) {
		return null;
	}

	const disclaimerText = t("sizeGuide.disclaimerText");

	const footer = (
		<>
			{activeMeasurement && (
				<OverlayHelperText>
					<div className="inner-content">{t(`sizeGuide.measurement.${activeMeasurement}`)}</div>
				</OverlayHelperText>
			)}
			<FooterContent>
				<VariantsContainer>
					<ProductVariants
						variants={product.variants || []}
						onChange={setSelectedVariant}
						outOfStockSelectable
					/>
				</VariantsContainer>
				<AddToCartButton
					triggeredFrom="PRODUCT_VIEW"
					product={product}
					variantId={selectedVariant?.entityId}
					onSuccess={handleClose}
					variant="primary_01"
					fullWidth
					disabled={
						!selectedVariant ||
						!selectedVariant.isPurchasable ||
						!selectedVariant.inventory.isInStock
					}
				>
					{cartButtonText}
				</AddToCartButton>
			</FooterContent>
		</>
	);

	return (
		<>
			<StyledTextButton onClick={() => dispatch(setSizeGuideModalIsOpen(true))}>
				{t("sizeGuide.title")}
			</StyledTextButton>
			<StyledAside
				isOpen={sizeGuideModalIsOpen}
				close={handleClose}
				position="right"
				heading={t("sizeGuide.title").toString()}
				footer={footer}
			>
				<Content>
					<Typography variant="heading" size="small" className="product-name">
						{product.name}
					</Typography>
					<Typography variant="body" size="small" className="helper-text">
						{t("sizeGuide.helperText")}
					</Typography>
					{disclaimerText !== "sizeGuide.disclaimerText" &&
						disclaimerText.length && (
							<div className="disclaimer">
								<IconInfo />
								<Typography
									variant="body"
									size="small"
									className="disclaimer-text"
								>
									{disclaimerText}
								</Typography>
							</div>
						)}

					<SizeGuide
						gender={gender}
						data={sizeGuideData}
						onMeasurementChange={setActiveMeasurement}
					/>
				</Content>
			</StyledAside>
		</>
	);
};

const SizeGuide = ({
	gender,
	data,
	onMeasurementChange,
}: {
	gender: Gender;
	data: any;
	onMeasurementChange: (measurement: string | undefined) => void;
}) => {
	const { t, ready } = useTranslation();

	const [activeMeasurement, setActiveMeasurement] = useState<
		string | undefined
	>();

	useEffect(() => {
		setActiveMeasurement(undefined);
		onMeasurementChange(undefined);
	}, [data])

	const measurements = useMemo(() => {
		if (!data) {
			return [];
		}

		const targets = Object.keys(data);
		const values = Object.values(data);

		return targets.map((target, index) => {
			return (
				<Measurement
					key={`${gender}_${target}`}
					gender={gender}
					target={target}
					position={index % 2 == 0 ? "left" : "right"}
					value={`${(values[index] as any).size}cm`}
					active={activeMeasurement ? target === activeMeasurement : false}
					onHover={(value: string | undefined) => {
						setActiveMeasurement(value);
						onMeasurementChange(value);
					}}
				/>
			);
		});
	}, [data, activeMeasurement]);

	return (
		<ImageWrapper>
			<img src={gender === "male" ? Male : Female} />
			{measurements}
		</ImageWrapper>
	);
};

export default PdpSizeGuide;
