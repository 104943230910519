import Page from "components/common/ui/Page/Page";
import ForgotPassword from "components/customer/PasswordReset/ForgotPassword/ForgotPassword";
import ResetPassword from "components/customer/PasswordReset/ResetPassword/ResetPassword";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "redux/hooks";
import { PageType } from "ts/enums";
import { ForgotPasswordTitle } from "utilities/variables/pageTitles";
import { ContentWrapper } from "./PasswordResetPage.Styled";
import PathTranslationKey from "utilities/paths";
import { useTranslation } from "react-i18next";

const PasswordResetPage: React.FC = () => {

	const { t } = useTranslation();

	const [searchParams] = useSearchParams();

	const navigate = useLocaleNavigate();
	const { identifier } = useSelector((state) => state.auth);

	useEffect(() => {
		if (identifier) {
			navigate(t(PathTranslationKey.ACCOUNT_OVERVIEW).toString(), {
				replace: true,
			});
		}
	}, []);

	const isResetLink = searchParams.get("email") && searchParams.get("token") && searchParams.get("signature");

	return (
		<Page pageType={PageType.PAGE} title={ForgotPasswordTitle}>
			<ContentWrapper>
				{!isResetLink ? <ForgotPassword /> : <ResetPassword />}
			</ContentWrapper>
		</Page>
	);
};

export default PasswordResetPage;
