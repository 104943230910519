import { useTranslation } from "react-i18next";
import {
	ResultHeadingWrapper,
	ResultWrapper,
	ScrollContainer,
	StyledDivider,
	Title,
} from "../../PdpFindInStore.Styled";

import { Store } from "ts/types";

import { useLazyCheckStockLevelsQuery } from "app/api/asw/aswApi";
import TextButton from "components/common/ui/Button/TextButton";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import Typography from "components/common/ui/Typography/Typography";
import useBreakpoints from "hooks/useBreakpoints";
import useStores from "hooks/useStores";
import { Fragment, useEffect, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import Location, { Cordinates } from "../../Location/Location";
import StoreDetails from "../StoreDetails/StoreDetails";

type StoreStock = {
	store_id: string;
	quantity: number;
};

type StoreAndStock = {
	store: Store;
	quantity: number;
};

interface Props {
	productSku: string | undefined;
	showStartAgain: boolean;
	startAgain?: () => void;
}

const ResultStep: React.FC<Props> = ({
	productSku,
	showStartAgain,
	startAgain = () => {},
}) => {
	const { large } = useBreakpoints();
	const { t, ready } = useTranslation();
	const { isLoading, orderByDistance } = useStores();

	const [checkStock, checkStockResult] = useLazyCheckStockLevelsQuery();
	const [stores, setStores] = useState<Store[]>([] as Store[]);
	const [storeStock, setStoreStock] = useState<StoreAndStock[] | undefined>();
	const [checkingStock, setCheckingStock] = useState(false);

	const [location, setLocation] = useState<Cordinates | undefined>();

	useEffect(() => {
		if (!isLoading) {
			if (location) {
				const stores = orderByDistance(location.lat, location.lng) as Store[];

				setStores(stores);
			} else {
				setStores([]);
			}
		}
	}, [isLoading, location]);

	useEffect(() => {
		if (stores.length && productSku && !checkingStock) {
			setCheckingStock(true);

			const storeIds = stores
				.filter((store) => store?.storeInformation.aswId)
				.map((store) => store?.storeInformation.aswId);

			if (!checkStockResult.isFetching) {
				checkStock({
					skus: [productSku],
					stores: storeIds as string[],
				})
					.unwrap()
					.then((res) => {
						const storeStocks = (res.data[productSku] as StoreStock[]) || [];

						const storesWithStock = [] as StoreAndStock[];

						stores.forEach((store) => {
							const quantity =
								storeStocks.find(
									(value) => value.store_id === store.storeInformation.aswId,
								)?.quantity || 0;
							storesWithStock.push({
								store,
								quantity,
							});
						});

						setStoreStock(storesWithStock);
						setTimeout(() => {
							setCheckingStock(false);
						}, 300);
					});
			}
		} else if (!checkingStock) {
			setStoreStock(undefined);
		}
	}, [stores, productSku]);

	const startAgainButton = useMemo(() => {
		if (!showStartAgain) {
			return false;
		}

		return !large;
	}, [showStartAgain, large]);

	if (!ready) {
		return null;
	}

	return (
		<ResultWrapper>
			{!large && (
				<Fragment>
					<Title variant="heading" size="small" className="input_title">
						{t("findInStore.title")}
					</Title>
					<StyledDivider margin={16} />
				</Fragment>
			)}
			<ResultHeadingWrapper>
				<Title variant="heading" size="small">
					{t("findInStore.availability")}
				</Title>
				{startAgainButton && (
					<TextButton onClick={startAgain} className="btn_start-again">
						{t("findInStore.startAgain")}
					</TextButton>
				)}
			</ResultHeadingWrapper>
			<StyledDivider margin={16} />
			<Location onSearch={setLocation} />
			<StyledDivider marginTop={16} />
			<ScrollContainer>
				{!productSku && (
					<Typography
						variant="body"
						size="small"
						className="select-variant-prompt"
					>
						{t("findInStore.selectVariant")}
					</Typography>
				)}

				{productSku && location && (
					<Scrollbars autoHide>
						<div>
							{checkingStock && <LoadingSpinner />}
							{!checkingStock &&
								storeStock?.map((data) => (
									<StoreDetails
										key={`${data.store.storeInformation.aswId}`}
										{...data}
									/>
								))}
						</div>
					</Scrollbars>
				)}
			</ScrollContainer>
		</ResultWrapper>
	);
};

export default ResultStep;
