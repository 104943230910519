import React from "react";

import { useParams } from "react-router";

import wishlistApi from "app/api/customer/wishlistApi";
import { productApi } from "app/api/product/productApi";

import Button from "components/common/ui/Button/Button";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";

import { useDispatch } from "redux/hooks";
import { setAccountActiveLink } from "redux/UI/uiSlice";

import { Product } from "ts/types";

import { Wishlist } from "../AccountWishlistOverview/AccountWishlistOverview";
import WishlistShareModal from "../WishlistShareModal/WishlistShareModal";
import WishlistItem from "./WishlistItem/WishlistItem";

import { AccountWishlistContainer } from "./AccountWishlist.Styled";
import useDataLayer from "datalayer/useDataLayer";
import { useTranslation } from "react-i18next";

type WishlistItemType = {
	id: number;
	product?: Product;
};

const AccountWishlist = ({ ...props }) => {
	const { id, id_hash } = useParams();
	const dispatch = useDispatch();
	const datalayer = useDataLayer();

	const { t } = useTranslation();

	React.useEffect(() => {
		dispatch(setAccountActiveLink("Wishlists"));
	}, [dispatch]);

	const [getWishlist, getWishlistResult] =
		wishlistApi.useLazyGetWishlistQuery();
	const [getSharedWishlist, getSharedWishlistResult] =
		wishlistApi.useLazyGetSharedWishlistQuery();

	React.useEffect(() => {
		if (id) getWishlist(id);
		if (id_hash) getSharedWishlist(id_hash);
	}, [getSharedWishlist, getWishlist, id, id_hash]);

	const wishlist = React.useMemo(() => {
		if (getWishlistResult.data) {
			return getWishlistResult.data.data as Wishlist;
		}
		if (getSharedWishlistResult.data) {
			return getSharedWishlistResult.data.data as Wishlist;
		}
	}, [getSharedWishlistResult.data, getWishlistResult.data]);

	const [fetchProduct, fetchProductResult] =
		productApi.useFetchProductBySkuMutation();

	const getWishlistItems = React.useCallback(
		async (wishlist: Wishlist) => {
			const productPromises = wishlist?.items.map((item: WishlistItemType) =>
				fetchProduct(item?.product?.sku || "").unwrap(),
			);

			const products = await Promise.all(productPromises);

			const wishlistItems = wishlist.items.map((wishlistItem) => ({
				id: wishlistItem.id,
				product: products.find(
					(product) => product?.entityId === Number(wishlistItem.product.id),
				),
			}));

			return wishlistItems;
		},
		[fetchProduct],
	);

	const [wishlistItems, setWishlistItems] = React.useState<WishlistItemType[]>(
		[],
	);

	React.useEffect(() => {
		if (wishlist) {
			getWishlistItems(wishlist).then((wishlistItems) => {
				setWishlistItems(wishlistItems);
			});
		}
	}, [getWishlistItems, wishlist]);

	const [deleteWishlistItem] = wishlistApi.useDeleteWishlistItemMutation();

	const onRemove = async (itemId: number) => {
		await deleteWishlistItem({ wishlistId: id, itemId: itemId }).then((res) => {
			const deletedItem = wishlist?.items.find((item) => item.id === itemId);

			if (deletedItem) {
				datalayer.removeWishListItem(
					[deletedItem.product],
					fetchProductResult?.data?.price?.currencyCode,
				);
			}

			getWishlist(id);
		});
	};

	const isLoading = React.useMemo(() => {
		let wishlistLoading = true;

		// this is a normal wishlist
		if (id) {
			wishlistLoading = getWishlistResult.isLoading;
		}

		// this is shared wishlist
		if (id_hash) {
			wishlistLoading = getSharedWishlistResult.isLoading;
		}

		if (wishlistLoading) {
			return true;
		}

		if (wishlist) {
			if (wishlist.items.length === 0) {
				return false;
			}
		}

		return fetchProductResult.isUninitialized && fetchProductResult.isLoading;
	}, [
		fetchProductResult.isLoading,
		fetchProductResult.isSuccess,
		getSharedWishlistResult.isLoading,
		getWishlistResult.isLoading,
		wishlist,
	]);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<AccountWishlistContainer {...props}>
			<h1>{wishlist?.name}</h1>
			{Boolean(id) && (
				<div className="buttons__container">
					{wishlist?.is_public && wishlist.share_url && (
						<WishlistShareModal shareURL={wishlist.share_url}>
							{t("wishlist.share")}
						</WishlistShareModal>
					)}
					<Button variant="primary_01" link={{ to: "/" }}>
						{t("wishlist.addProduct")}
					</Button>
				</div>
			)}
			<div className="items__container">
				{wishlistItems.map((wishlistItem: WishlistItemType) => (
					<WishlistItem
						key={wishlistItem.id}
						product={wishlistItem.product}
						onRemove={() => onRemove(wishlistItem.id)}
					/>
				))}
			</div>
		</AccountWishlistContainer>
	);
};

export default AccountWishlist;
