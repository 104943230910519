import Link from "components/common/ui/Link/Link";
import Divider from "components/common/ui/List/Divider/Divider";
import InlineList from "components/common/ui/List/InlineList";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import StructuredData from "components/common/ui/StructuredData/StructuredData";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { BreadcrumbPart } from "ts/types";
import PathTranslationKey from "utilities/paths";
import { BreadcrumbItem, BreadcrumbNav } from "./Breadcrumb.Styled";
import { useSelector } from "redux/hooks";

interface BreadcrumbProps {
	loading?: boolean;
	items?: BreadcrumbPart[];
	labels?: string[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
	loading,
	items = [],
	labels = [],
}) => {
	const { t } = useTranslation();

	const preferredLocation = useSelector((state) => state.location.preferredLocation) || '';

	const location = useLocation();

	const breadcrumbItems = useMemo(() => {
		if (items.length) {
			return items;
		}


		const isSearchPage = location.pathname.includes(
			`${t(PathTranslationKey.SEARCH)}`,
		);

		const pathSplit = location.pathname.split(
			`${t(PathTranslationKey.CATEGORY)}/`,
		);

		if (isSearchPage || pathSplit.length > 1) {
			let toLink = "";
			let crumbs = [] as BreadcrumbPart[];

			const path = isSearchPage ? location.pathname.split(preferredLocation)[1] : pathSplit[1];

			// URL has category
			// Filter to remove any trailing "/" that become an empty string after split
			path
				.split("/")
				.filter((item) => item !== "")
				.forEach((item, index) => {
					toLink = toLink + "/" + item;
					crumbs.push({
						name: labels.length > index ? labels[index] : item,
						path: toLink,
					});
				});

			return crumbs;
		}

		return [];
	}, [items, location, labels]);

	return (
		<>
			<StructuredData type="BreadcrumbList" data={breadcrumbItems} />
			<BreadcrumbNav className="BreadcrumbNav">
				<InlineList>
					{loading &&
						[...Array(3)].map((_, index) => {
							return (
								<Fragment key={`breadcrumb_skeleton-${index}`}>
									<BreadcrumbItem>
										<Skeleton width={100} />
									</BreadcrumbItem>
									{index !== 2 && <Divider />}
								</Fragment>
							);
						})}
					{!loading &&
						breadcrumbItems.map((item, index) => {
							const active = index === breadcrumbItems.length - 1;

							const li = (
								<BreadcrumbItem key={index} active={active}>
									<Link
										to={item.path}
										prefix={t(PathTranslationKey.CATEGORY).toString()}
									>
										{item.name}
									</Link>
								</BreadcrumbItem>
							);

							if (!active) {
								return (
									<Fragment key={`fragement-${index}`}>
										{li}
										<Divider key={`divider-${index}`} />
									</Fragment>
								);
							} else {
								return li;
							}
						})}
				</InlineList>
			</BreadcrumbNav>
		</>
	);
};

export default Breadcrumb;
