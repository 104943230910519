
import { integrationLayer } from "app/services/integrationLayerService";
import { convertBigCommerceApiProduct } from "app/utils/bigcommerceApiUtil";

export const categoryRestApi = integrationLayer.injectEndpoints({
	endpoints: (builder) => ({
		getCategoryProducts: builder.query({
			query: (
				{
					id,
					limit = 48,
					activePage = 1,
					sort = 'date_modified',
					direction  = 'desc',
				} : {
					id: number;
					limit?: number;
					activePage: string | number;
					sort: string | number;
					direction: string | number;
				}
			) => ({
				url: `/bc/products?include=custom_fields,variants,images&inventory_level:greater=1&categories:in=${id}&limit=${limit}&page=${activePage}&sort=${sort}&direction=${direction}`,
				method: "GET",
			}),
			transformResponse: (res: any) => {
				if (!res) {
					return undefined;
				}

				return {
					products: res.data.map(
						(product: any) => convertBigCommerceApiProduct(product),
					),
					pagination: res.meta
				};
			},
		}),
	}),
});

export const { useLazyGetCategoryProductsQuery } =
	categoryRestApi;
