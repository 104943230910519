import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAccessToken } from "./integrationLayerService";

const hasQueryParams = (url) => {
	return url.includes("?");
};

export const integrationLayerService = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_IL_URL,
	prepareHeaders: (headers, { getState }) => {
		headers.set("Content-Type", "application/json");
		headers.set("X-Request-With", "XMLHttpRequest");
		headers.set("Accept", "application/json");
	},
});

export const ASWBaseQuery = async (args, api, extraOptions) => {
	let endpointUrl = typeof args === "string" ? args : args.url;

	const token = await baseQueryWithAccessToken(api, extraOptions);

	if (!token) {
		return {
			error: {
				status: 404,
				statusText: "Error getting access token",
			},
		};
	}

	args = {
		...args,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const adjustedArgs =
		typeof args === "string"
			? endpointUrl
			: { ...args, url: `/asw${endpointUrl}` };

	// provide the amended url and other params to the raw base query
	return integrationLayerService(adjustedArgs, api, extraOptions);
};

export const integrationLayerASW = createApi({
	reducerPath: "integrationLayerASW",
	baseQuery: ASWBaseQuery,
	endpoints: (builder) => ({}),
});
