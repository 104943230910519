import { Portal } from "react-portal";
import Overlay from "../Overlay/Overlay";
import CloseButton from "components/common/ui/Button/CloseButton";
import { AsideBody, StyledAside, StyledAsideProps } from "./Aside.Styled";
import AsideHeader, { AsideHeaderProps } from "./AsideHeader/AsideHeader";
import AsideFooter from "./AsideFooter/AsideFooter";
import { PORTAL_MODAL } from "utilities/portals";
import { AnimatePresence, motion, MotionProps, useCycle } from "framer-motion";
import { useEffect } from "react";

interface AsideProps {
	isOpen: boolean;
	close: () => void;
	children?: any;
	footer?: any;
	header?: any;
	className?: string;
	motion?: MotionProps;
}

const Aside: React.FC<AsideProps & AsideHeaderProps & StyledAsideProps> = ({
	isOpen,
	close,
	children,
	position,
	header,
	heading,
	footer,
	className = "",
	motion = {},
}) => {
	const styles = {
		position,
	};

	const [open, cycleOpen] = useCycle(isOpen);

	useEffect(() => {
		cycleOpen();
	}, [isOpen]);

	const animation = {
		initial: {
			x: position == "left" ? "-100%" : "100%",
		},
		animate: {
			x: 0,
			transition: { duration: 0.3 },
		},
		exit: {
			x: position == "left" ? "-100%" : "100%",
			transition: { duration: 0.3 },
		},
		...motion,
	};

	return (
		<AnimatePresence>
			{open && (
				<Portal node={document && document.getElementById(PORTAL_MODAL)}>
					<Overlay show={true} onClick={close} />

					<StyledAside
						className={`Aside ${className}`}
						{...styles}
						{...animation}
					>
						<CloseButton onClick={close} />
						{header && header}
						{!header && heading && <AsideHeader heading={heading} />}
						<AsideBody className="AsideBody">{children}</AsideBody>
						{footer && <AsideFooter>{footer}</AsideFooter>}
					</StyledAside>
				</Portal>
			)}
		</AnimatePresence>
	);
};

export default Aside;
