import { useLazyGetMeQuery } from "app/api/customer/customerApi";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { logout } from "redux/auth/authSlice";
import { useDispatch, useSelector } from "redux/hooks";

const useLoginState = () => {
	const [haveChecked, setHaveChecked] = useState(false);

	const dispatch = useDispatch();

	const location = useLocation();

	const { loginRedirect, customer, identifier, state } = useSelector(
		(state) => state.auth,
	);

	const [me] = useLazyGetMeQuery();

	useEffect(() => {
		if (!haveChecked) {
			const getCustomerDetails = async () => {
				await me()
					.unwrap()
					.catch((error) => {
						dispatch(logout());
						return;
					});

				setHaveChecked(true);
			};

			if (!identifier && customer) {
				getCustomerDetails();
				return;
			}

			if (identifier && !customer) {
				getCustomerDetails();
				return;
			}

			if ((identifier || customer) && location.pathname.includes("account")) {
				getCustomerDetails();
				return;
			}

			setHaveChecked(true);
		}
	}, []);

	const isLoggedIn = useMemo(() => {
		return haveChecked && customer !== null;
	}, [haveChecked, customer]);

	return {
		isLoggedIn,
		haveChecked,
		loginRedirect,
		customer,
		state,
	};
};

export default useLoginState;
