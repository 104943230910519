import styled from "styled-components";
import Skeleton from "../Skeleton/Skeleton";

export const CarouselWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 0;
	&::before {
		content: "";
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 149.83%;
	}

	.swiper,
	.static-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.swiper-slide,
	.static-image {
		img {
			object-fit: cover;
		}
	}
`;

export const ImageSkeleton = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.skeleton {
		height: 100%;
	}
	
`;

export const CarouselLazyLoader = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	&::before {
		content: "";
		display: none;
		width: 100%;
		padding-bottom: 149.83%;
	}
`;

export const CarouselImageWrapper = styled.div`
	width: inherit;
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	&.active {
		display: block;
	}
`;

export const CarouselArrow = styled.button`
	position: absolute;
	background-color: transparent;
	z-index: 10;

	&.carousel-navigation--prev {
		left: 0;
	}

	&.carousel-navigation--next {
		right: 0;
	}
`;
