import AddToCartButton from "components/AddToCartButton/AddToCartButton";
import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	SizeRecommendation,
	StartOverButton,
	StyledHeading,
	StyledHeadingWrapper,
	StyledMeasurementsWrapper,
} from "../../PdpSizeCalculator.Styled";

import { useDispatch } from "react-redux";
import { setSizeGuideModalIsOpen } from "redux/UI/uiSlice";
import { Product, ProductVariant } from "ts/types";

interface Props {
	product: Product;
	variant?: ProductVariant;
	close: () => void;
	changeStep: () => void;
	hasMultipleRecommendations?: boolean;
}

const ResultStep: React.FC<Props> = ({
	product,
	variant,
	close,
	changeStep,
	hasMultipleRecommendations = false,
}) => {
	const { t, ready } = useTranslation();
	const dispatch = useDispatch();

	const cartButtonText = useMemo(() => {
		if (!variant) {
			return undefined;
		}

		if (!variant.isPurchasable || !variant.inventory.isInStock) {
			return t("addToCart.unavailable");
		}

		let addToCartText = t("sizeGuide.addToCart");

		// if there is translations for the add to cart text
		// add the selected variant size
		if (addToCartText !== "sizeGuide.addToCart") {
			addToCartText = addToCartText.replace("{}", variant?.option.label || "");
		}

		return addToCartText;
	}, [variant]);

	const handleSwitchToSizeGuide = () => {
		dispatch(setSizeGuideModalIsOpen(true));
		setTimeout(() => {
			close();
		}, 200); // avoids the overlay flashing when changing modals
	};

	if (!ready) {
		return null;
	}

	return (
		<Fragment>
			{variant?.option.label && (
				<StyledHeadingWrapper>
					<StyledHeading>{t("sizeCalculator.weRecommend")}</StyledHeading>
				</StyledHeadingWrapper>
			)}
			<StyledMeasurementsWrapper>
				<SizeRecommendation>
					<Typography variant="heading" size="large">
						{variant?.option.label || t("sizeCalculator.noSizeFound")}{" "}
						{hasMultipleRecommendations && <sup>*</sup>}
					</Typography>
				</SizeRecommendation>
				{hasMultipleRecommendations && (
					<p className="multi-recommendations-text">
						<sup>*</sup> {t("sizeCalculator.multiRecomendationText")}{" "}
						<span onClick={handleSwitchToSizeGuide}>
							{t("sizeGuide.title")}
						</span>
					</p>
				)}
				{!variant && (
					<p className="multi-recommendations-text">
						<sup>*</sup> {t("sizeCalculator.emptyRecomendationText")}{" "}
						<span onClick={handleSwitchToSizeGuide}>
							{t("sizeGuide.title")}
						</span>
					</p>
				)}
				{variant && (
					<AddToCartButton
						triggeredFrom="PRODUCT_VIEW"
						product={product}
						variantId={variant?.entityId}
						onSuccess={close}
						variant="primary_01"
						fullWidth
						disabled={
							!variant || !variant.isPurchasable || !variant.inventory.isInStock
						}
					>
						{cartButtonText}
					</AddToCartButton>
				)}
				<Button variant="primary_03" fullWidth textLight onClick={close}>
					{t("basket.continueShopping")}
				</Button>

				<StartOverButton onClick={changeStep}>
					{t("text.startAgain")}
				</StartOverButton>
			</StyledMeasurementsWrapper>
		</Fragment>
	);
};

export default ResultStep;
