import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CustomiseComponent } from "ts/interfaces";
import { darkGrey, red } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

interface FormProps {
  onSubmit?: () => {};
  children: any;
  showLegend?: boolean;
  autoComplete?: string;
}

const RequiredIndicator = styled.span`
  font-size: 14px;
  font-family: ${Roboto};
  color: ${darkGrey};
  text-transform: lowercase;
  position: absolute;
  right: 0;

  &::before {
    content: "*";
    color: ${red};
    margin-right: 3px;
  }
`;
const StlyedForm = styled.form<CustomiseComponent>`
  position: relative;
  margin-top: 48px;

  ${(props) => props.styles};
`;

const BaseForm: React.FC<FormProps & CustomiseComponent> = ({ onSubmit, children, showLegend = true, ...props }) => {

  const { t } = useTranslation();

  return (
    <StlyedForm className={props.className} styles={props.styles} onSubmit={onSubmit} autoComplete={props.autoComplete}>
    {showLegend && <RequiredIndicator>{t("required")}</RequiredIndicator>}
      {children}
    </StlyedForm>
  );
};

export default BaseForm;
