import React from "react";

import {
	FieldValue,
	StringFieldValue,
	updateStringFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
	FieldError,
	FormField,
	PhoneField as PhoneFieldType,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputText } from "../GravityForm.Styled";

interface Props {
	field: FormField & Partial<PhoneFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: string;
}

const PhoneField = ({ field, fieldErrors, defaultValue }: Props) => {
	const { id } = field;
	const dispatch = useDispatch();
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as StringFieldValue | undefined;

	const phoneValue = fieldValue?.value ?? defaultValue;

	const updateValue = React.useCallback(
		(value: string) => {
			dispatch(updateStringFieldValue({ id, value }));
		},
		[dispatch, id],
	);

	const [error, setError] = React.useState<string | undefined>();

	const validatePhoneNumber = (phoneNumber: string): boolean => {
		const phoneRegex = /^\+?\d{1,3}[-. (]?\d{3}[-. )]?\d{3}[-. ]?\d{4}$/;
		return phoneRegex.test(phoneNumber);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (value && !validatePhoneNumber(value)) {
			setError("Invalid phone number");
		} else {
			setError(undefined);
		}

		updateValue(value);
	};

	React.useEffect(() => {
		if (phoneValue) updateValue(phoneValue);
	}, [phoneValue, updateValue]);

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			<StyledInputText
				label={field.label}
				required={field.isRequired}
				errorMsg={fieldErrors[0]?.message || error}
				onChange={handleChange}
				value={phoneValue || ""}
			/>
		</StyledFieldContainer>
	);
};

export default PhoneField;
