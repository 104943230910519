import Button from '../Button/Button';
import Skeleton from '../Skeleton/Skeleton';

import {
	StyledCtaWrapper,
	StyledSingleImageWithCta,
	StyledTextWrapper,
} from './Styled';

type Props = {
	imageData: {
		image: {
			desktopImage: string;
			mobileImage?: string;
			alt?: string;
		};
		ctaText: string;
		ctaLink: string;
	};
	showButton: boolean;
	showText: boolean;
	imageText?: string;
	isLoading: boolean;
	isBottomBanner?: boolean;
};

function SingleImageWithCta({
	imageData,
	showButton = true,
	showText = false,
	imageText,
	isLoading = true,
	isBottomBanner = false,
}: Props) {
	return (
		<StyledSingleImageWithCta
			className={`SingleImageWithCta ${isBottomBanner && 'bottom-banner'}`}
		>
			{!isLoading ? (
				<>
					<picture>
						<source
							media='(max-width:551px)'
							srcSet={imageData.image?.mobileImage}
						/>
						<img src={imageData.image.desktopImage} alt={imageData.image.alt} />
					</picture>
					<div className='text-container'>
						{showText && imageText && (
							<StyledTextWrapper className='StyledTextWrapper'>
								<p>{imageText}</p>
							</StyledTextWrapper>
						)}

						{showButton && (
							<StyledCtaWrapper className='StyledCtaWrapper'>
								<Button variant='primary_02' link={{ to: imageData.ctaLink }}>
									{imageData.ctaText}
								</Button>
							</StyledCtaWrapper>
						)}
					</div>
				</>
			) : (
				<Skeleton width='100%' />
			)}
		</StyledSingleImageWithCta>
	);
}

export default SingleImageWithCta;
