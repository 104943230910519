import SearchLocation from "components/supportingPages/customerService/stores/SearchLocation/SearchLocation";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountLightGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const LocationWrapper = styled.div`
	margin: 0 ${remCalc(16)};

	.location {
		display: flex;
		gap: ${remCalc(8)};

		.address {
			font-size: ${remCalc(14)};
		}

		.btn_location-change {
			margin-left: auto;
		}
	}

	@media ${aboveMedium} {
		margin: 0;
	}
`;

export const StyledSearchLocation = styled(SearchLocation)`
	margin-bottom: ${remCalc(16)};

	.input__container {
		position: relative;

		input,
		input::placeholder {
			font-weight: 500;
			font-size: ${remCalc(16)};
		}

		button {
			font-size: ${remCalc(14)};
		}
	}

	@media ${aboveMedium} {
		.input__container {
			input,
			input::placeholder {
				background-color: ${accountLightGrey};
			}
			button {
				background-color: ${accountLightGrey};
			}
		}
	}
`;
