import styled from "styled-components";
import { white } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledNav = styled.nav`
  width: 100%;
  height: 56px;
  background-color: ${white};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;

  .menu-btn{
    > .icon {
      font-size: 32px;
    }
  }

  .men-logo-wrapper,
  .logo-wrapper {
    display: flex;
    justify-content: center;
  }

  .logo-wrapper {
    width: 76px;
    margin-left: 16px;
  }

  .men-logo-wrapper {
    width: 128px;
    margin-left: 8px;
  }

  @media ${aboveMedium} {
    height: 88px;
    padding: 10px 20px;

    .logo-wrapper {
      width: 152px;
    }

    .men-logo-wrapper {
      width: 258px;
      margin-left: 20px;
    }

    .logo-wrapper {
      margin-left: 24px;
    }
  }
`;