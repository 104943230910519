import { isSandbox } from "app/helpers/helper";

export const config = {
	stores: {
		en: {
			name: "Europe",
			locales: ["en"],
			hash: isSandbox() ? "aqj6dh7j08" : "y5w7jig3ib",
			phonePrefix: "",
		},
		rs: {
			name: "Srbija",
			locales: ["sr-Latn-RS"],
			hash: isSandbox() ? "e4s36vbhcy" : "kd54n9g108",
			phonePrefix: "+381",
		},
		ba: {
			name: "Bosna i Hercegovina",
			locales: ["bs-BA"],
			hash: isSandbox() ? "pcajoczyvs" : "vk7ymvk79g",
			phonePrefix: "+387",
		},
		hr: {
			name: "Hrvatska",
			locales: ["hr"],
			hash: isSandbox() ? "mbfl34uec2" : "ew3rm6cbbg",
			phonePrefix: "+385",
		},
		mk: {
			name: "Северна Македонија",
			locales: ["mk-MK"],
			hash: isSandbox() ? "ww3ax386lk" : "gdhh7k6qlp",
			phonePrefix: "+389",
		},
		me: {
			name: "Crna Gora",
			locales: ["sr-ME"],
			hash: isSandbox() ? "pcp5nhs5tx" : "53sebp5dze",
			phonePrefix: "+382",
		},
	},
};
