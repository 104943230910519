import InputSelect from "components/common/form/InputSelect/InputSelect";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "redux/hooks";

import countryList from "react-select-country-list";

import {
	AddressFieldValue,
	FieldValue,
	updateAddressFieldValue,
} from "redux/gravityForm/gravityFormSlice";

import {
	AddressField as AddressFieldType,
	AddressInput,
	FieldError,
	FormField,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputText } from "../GravityForm.Styled";

interface Props {
	field: FormField & Partial<AddressFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: AddressInput;
}

const AddressField = ({ field, fieldErrors, defaultValue }: Props) => {
	const DEFAULT_VALUE = defaultValue as AddressInput;
	const { id } = field;
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);
	const dispatch = useDispatch();

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as AddressFieldValue | undefined;

	const addressValues = fieldValue?.addressValues || DEFAULT_VALUE;

	useEffect(() => {
		if (DEFAULT_VALUE) {
			updateValues(addressValues)
		}
	}, [])

	const updateValues = React.useCallback(
		(addressValues: AddressInput) => {
			dispatch(updateAddressFieldValue({ id, addressValues }));
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		const newAddressValues = { ...addressValues, [name]: value };

		updateValues(newAddressValues);
	};

	const countryOptions = React.useMemo(
		() =>
			countryList()
				.getData()
				.map((op) => {
					return {
						label: op.label,
						value: op.value,
					};
				}),
		[],
	);

	const handleCountryChange = (countryCode: string) => {
		const newAddressValues = {
			...addressValues,
			country: countryCode,
		};

		updateValues(newAddressValues);
	};

	React.useEffect(() => {
		updateValues(addressValues);
	}, [addressValues, updateValues]);

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			{field.inputs?.map((input: any) => {
				if (!input.isHidden) {
					// Load list of countries
					if (input.key === "country") {
						return (
							<InputSelect
								key={input.key}
								name={input.key}
								label={input.customLabel ?? input.label}
								required={field.isRequired}
								options={countryOptions}
								errorMsg={
									fieldErrors[0]?.message.includes("required")
										? "This field is required."
										: fieldErrors[0]?.message
								}
								value={addressValues[input.key as keyof AddressInput] || ""}
								onChange={handleCountryChange}
								isSearchable
							/>
						);
					} else {
						return (
							<StyledInputText
								key={input.key}
								name={input.key}
								label={input.customLabel ?? input.label}
								required={field.isRequired}
								isError={Boolean(fieldErrors.length)}
								errorMsg={
									fieldErrors[0]?.message.includes("required")
										? "This field is required."
										: fieldErrors[0]?.message
								}
								onChange={handleChange}
								value={addressValues[input.key as keyof AddressInput] || ""}
							/>
						);
					}
				}
			})}
		</StyledFieldContainer>
	);
};

export default AddressField;
