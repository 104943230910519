import useBreakpoints from "hooks/useBreakpoints";
import { ReactComponent as DesktopIcon } from "assets/images/svg/AddToBasketDesktop.svg";
import { ReactComponent as MobileIcon } from "assets/images/svg/AddToBasketMobile.svg";
import Icon from "./Icon";

const IconCart: React.FC = () => {
  const { large } = useBreakpoints();

  const Svg = large ? DesktopIcon : MobileIcon;

  return <Icon icon={<Svg />} />;
};

export default IconCart;
