export type SortOption = {
  id: string;
  label: string,
  segmentify_param: string,
}

const SortOptions = [
  {
    id: 'date_modified',
    label: "Newest",
    segmentify_param:'NEWEST'
  },
  {
    id: 'total_sold',
    label: "Best Selling",
    segmentify_param:'BEST_SELLERS'
  },
  {
    id: 'price|desc',
    label: "Price High",
    segmentify_param:'PRICE_DESC'
  },
  {
    id: 'price|asc',
    label: "Price Low",
    segmentify_param:'PRICE_ASC'
  },
  {
    id: 'name|asc',
    label: "A to Z",
    segmentify_param:'ALPHABETICAL_ASC'
  },
  {
    id: 'name|desc',
    label: "Z to A",
    segmentify_param:'ALPHABETICAL_DESC'
  },
] as SortOption[];

export default SortOptions;