import { integrationLayerCart } from "app/services/integrationLayerService";
import { Cart, CartItem } from "types/CartTypes";

type CreateCart = {
	customerId: number;
	lineItems: CartItem[];
};

type AddCartItem = {
	id: string;
	lineItems: CartItem[];
};

const api = integrationLayerCart.injectEndpoints({
	endpoints: (builder) => ({
		createCart: builder.mutation({
			query: ({ customerId, lineItems }: CreateCart) => ({
				url: `/bc/cart?include=redirect_urls,line_items.physical_items.options`,
				method: "POST",
				body: {
					customer_id: customerId,
					line_items: lineItems,
				},
			}),
			transformResponse: (res: any) => {
				return res.data as Cart;
			},
		}),
		getCart: builder.query({
			query: (cartId) => ({
				url: `/bc/cart/${cartId}?include=redirect_urls,line_items.physical_items.options`,
				method: "GET",
			}),
			providesTags: ["Cart"],
			transformResponse: (res: any) => {
				return res.data as Cart;
			},
		}),
		addCartItem: builder.mutation({
			query: ({ id, lineItems }: AddCartItem) => ({
				url: `/bc/cart/${id}/items?include=redirect_urls,line_items.physical_items.options`,
				method: "POST",
				body: {
					line_items: lineItems,
				},
			}),
			transformResponse: (res: any) => {
				return res.data as Cart;
			},
		}),
		updateCartItem: builder.mutation({
			query: ({ cartId, itemId, newItem }) => ({
				url: `/bc/cart/${cartId}/item/${itemId}?include=redirect_urls,line_items.physical_items.options`,
				method: "PUT",
				body: {
					line_item: {
						...newItem,
					},
				},
			}),
			invalidatesTags: ["Cart"],
			transformResponse: (res: any) => {
				return res.data as Cart;
			},
		}),
		updateCartCustomer: builder.mutation({
			query: ({ cartId }) => ({
				url: `/bc/cart/${cartId}/customer?include=redirect_urls,line_items.physical_items.options`,
				method: "PUT",
			}),
			invalidatesTags: ["Cart"],
			transformResponse: (res: any) => {
				return res.data as Cart;
			},
		}),
		deleteCartItem: builder.mutation({
			query: ({ cartId, itemId }) => ({
				url: `/bc/cart/${cartId}/item/${itemId}?include=redirect_urls,line_items.physical_items.options`,
				method: "DELETE",
			}),
			transformResponse: (res: any) => {
				if (!res.data) {
					return undefined;
				}
				return res.data as Cart;
			},
		}),
		deleteCart: builder.mutation({
			query: ({ cartId }) => ({
				url: `/bc/cart/${cartId}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const {
	useCreateCartMutation,
	useGetCartQuery,
	useLazyGetCartQuery,
	useUpdateCartItemMutation,
	useAddCartItemMutation,
	useDeleteCartItemMutation,
	useDeleteCartMutation,
	useUpdateCartCustomerMutation,
} = api;
