import { wordPressApi } from "app/api/wordpress/wordPressApi";
import Typography from "components/common/ui/Typography/Typography";
import { CMSContent, RegistrationContainer } from "components/customer/registration/Registration.Styled";
import RegistrationForm from "components/customer/registration/RegistrationForm/RegistrationForm";
import RequireNotAuth from "components/routing/RequireNotAuth/RequireNotAuth";
import { useEffect, useState } from "react";
import { PageType } from "ts/enums";
import { RegistrationTitle } from "utilities/variables/pageTitles";
import Page from "../../components/common/ui/Page/Page";
import SEO from "components/common/ui/SEO/SEO";
import getRobotsContent from "utilities/getRobotsContent";
import { useTranslation } from "react-i18next";

const RegistrationPage: React.FC = () => {

  const [cmsContent, setCmsContent] = useState("");

  const registrationContent = wordPressApi.useGetPageContentByUriQuery("/registration") as any;

  const { t } = useTranslation();

  useEffect(() => {
    if(registrationContent.data?.content) {
      setCmsContent(registrationContent.data.content);
    }
  }, [registrationContent]);

  return (
    <RequireNotAuth>
      <Page pageType={PageType.PAGE} title={RegistrationTitle}>
      <SEO
				title={registrationContent?.data?.seo?.title}
				description={registrationContent?.data?.seo?.metaDesc}
				keywords={registrationContent?.data?.seo?.metaKeywords}
				robots={getRobotsContent([
					registrationContent?.data?.seo?.metaRobotsNofollow,
					registrationContent?.data?.seo?.metaRobotsNoindex,
				])}
			/>
        <RegistrationContainer>
          <Typography variant="heading" size="small">
            {t("form.signInRegister")}
          </Typography>
          <CMSContent html={cmsContent} />
          <RegistrationForm />
        </RegistrationContainer>
      </Page>
    </RequireNotAuth>
  );
};

export default RegistrationPage;
