import Modal from "components/common/ui/Modal/Modal";
import ProductVariants from "components/ProductVariants/ProductVariants";
import useCart from "hooks/cart/useCart";
import useBreakpoints from "hooks/useBreakpoints";
import { useMemo, useRef, useState } from "react";
import { ModalProps } from "ts/interfaces";
import { Product, ProductVariant } from "ts/types";
import { useOnClickOutside } from "usehooks-ts";
import { PORTAL_MODAL } from "utilities/portals";
import { ModalContainerStyles, VariantsContainer } from "./Variants.Styled";
import AddToCartButton from "components/AddToCartButton/AddToCartButton";

const Variants: React.FC<
	ModalProps & {
		product: Product;
		onAddToCart?: Function;
		triggeredFrom: string;
	}
> = ({ id, isOpen, close, product, triggeredFrom }) => {
	const cart = useCart();
	const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();

	const handleVariantChange = (variant: ProductVariant) => {
		setSelectedVariant(variant);
	};

	const { large } = useBreakpoints();

	const ref = useRef(null);

	useOnClickOutside(ref, close);

	const productHasVariants = useMemo(() => {
		return product.variants?.length !== 0;
	}, [product]);

	if (!isOpen) {
		return null;
	}

	const portalId = large ? "" : PORTAL_MODAL;

	return (
		<Modal
			id={id}
			onClose={close}
			options={{
				showCloseButton: false,
				portalId,
				closeOnOutsideClick: true,
			}}
			additionalStyles={{
				container: ModalContainerStyles,
			}}
		>
			{productHasVariants && (
				<VariantsContainer>
					<ProductVariants
						variants={product.variants || []}
						onChange={handleVariantChange}
					/>
				</VariantsContainer>
			)}
			<AddToCartButton
				triggeredFrom={triggeredFrom}
				variant="primary_01"
				fullWidth
				loading={cart.meta.isLoading}
				product={product}
				variantId={selectedVariant?.entityId}
			/>
		</Modal>
	);
};

export default Variants;
