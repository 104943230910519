import Link from "components/common/ui/Link/Link";
import { HeadingMediumStyles } from "components/common/ui/Typography/Typography.Styled";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { darkGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const ProductImages = styled.div`
	position: relative;
	width: 100%;

	&::before {
		content: "";
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 149.83%;
	}

	.Carousel {
		position: absolute;
		top: 0;
	}

	img {
		width: inherit;
		height: 100%;
	}
	.skeleton-container.carousel {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.react-loading-skeleton {
			height: 100%;
		}
	}
`;

export const BelowImage = styled.div`
	display: flex;
	padding: ${remCalc(10)} ${remCalc(6)};

  .price, .MoreColours {
    margin-top: ${remCalc(8)};
  }
`;

export const ContentLeft = styled.div`
	flex-grow: 1;

	.skeleton {
		&.name {
			height: ${remCalc(20)};
			margin-bottom: ${remCalc(4)};

			&:last-child {
				margin-bottom: ${remCalc(9)};
			}
		}

		&.price {
			margin-bottom: ${remCalc(8)};
			height: ${remCalc(14)};
			width: ${remCalc(60)};
		}

		&.more-colours {
			height: ${remCalc(12)};
			width: ${remCalc(100)};
		}
	}
`;

export const ContentRight = styled.div`
	position: relative;
	bottom: ${remCalc(51)};
	right: ${remCalc(2)};
	width: ${remCalc(28)};
	height: ${remCalc(28)};
	border-radius: 50%;

	button {
		height: 100%;
		align-items: center;
	}

	@media ${aboveMedium} {
		bottom: 0;
		background-color: unset;
		width: ${remCalc(32)};
		height: ${remCalc(32)};
		top: -${remCalc(-4)};
		right: 0;
	}

	&:hover,
	&:active,
	&.active {
		background-color: #e0e0e0;

		@media ${aboveMedium} {
			background-color: unset;

			button svg {
				fill: ${darkGrey};
			}
		}
	}

	.skeleton {
		&.options {
			display: none;

			@media ${aboveMedium} {
				display: block;
				position: absolute;
				top: ${remCalc(4)};
				right: 0;
				width: ${remCalc(20)};
				height: ${remCalc(20)};
			}
		}
	}
`;

export const ProductCardWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 50%;

	@media ${aboveMedium} {
		width: 25%;
	}

	@media (min-width: 1280px) {
		width: ${remCalc(307)}
	}
`;

export const ProductTitleLink = styled(Link)`
	${HeadingMediumStyles}
	font-size: ${remCalc(14)};
  line-height: ${remCalc(17)};
  letter-spacing: ${remCalc(0.7)}
  
`;
