import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { Message, MessageContainer, Position } from "./InputError.Styled";

interface ErrorMessageProps {
	message?:
		| string
		| FieldError
		| Merge<FieldError, FieldErrorsImpl<any>>
		| Partial<{ type: string | number; message: string; }>
		| undefined;
}

const ErrorMessage: React.FC<ErrorMessageProps & Position> = ({
	message,
	...props
}) => {
	if (!message) {
		return null;
	}

	return (
		<MessageContainer {...props}>
			<Message role="alert">{String(message)}</Message>
		</MessageContainer>
	);
};

export default ErrorMessage;
