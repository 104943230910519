import Button from "components/common/ui/Button/Button";
import styled from "styled-components";

import { aboveMedium } from "utilities/variables/media";
import { menuZIndex } from "utilities/variables/zIndex";

export const StyledWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(props) => !props.isOpen && "none"};
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  z-index: ${menuZIndex};
  height: fit-content;
  background-color: #fff;
  box-shadow: 0px 3px 6px #21252940;
  padding: 24px 16px;
  margin: 0 16px;
  margin-top: calc(env(safe-area-inset-top, 0));

  @media ${aboveMedium} {
    width: 328px;
    margin: 0 20px;
    left: unset;
    top: 120px;
  }
`;
