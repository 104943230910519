import React from "react";

import {
	EmailFieldValue,
	FieldValue,
	updateEmailFieldValue,
} from "redux/gravityForm/gravityFormSlice";
import { useDispatch, useSelector } from "redux/hooks";

import {
	EmailField as EmailFieldType,
	FieldError,
	FormField,
} from "types/GravityForm";

import { StyledFieldContainer, StyledInputEmail } from "../GravityForm.Styled";

interface Props {
	field: FormField & Partial<EmailFieldType>;
	fieldErrors: FieldError[];
	defaultValue?: string;
}

const EmailField = ({ field, fieldErrors, defaultValue }: Props) => {
	const { id } = field;
	const dispatch = useDispatch();
	const fieldValues = useSelector((state) => state.gravityForm.fieldValues);

	const fieldValue = fieldValues.find(
		(fieldValue: FieldValue) => fieldValue.id === id,
	) as EmailFieldValue | undefined;

	const emailValue = fieldValue?.emailValues?.value || defaultValue;

	const updateValue = React.useCallback(
		(value: string) => {
			dispatch(updateEmailFieldValue({ id, emailValues: { value } }));
		},
		[dispatch, id],
	);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		updateValue(event.target.value);
	};

	React.useEffect(() => {
		if (emailValue) updateValue(emailValue);
	}, [emailValue, updateValue]);

	return (
		<StyledFieldContainer
			layoutGridColumnSpan={field.layoutGridColumnSpan}
			isError={Boolean(fieldErrors.length)}
			visibility={field.visibility}
		>
			<StyledInputEmail
				label={field.label}
				required={field.isRequired}
				isError={Boolean(fieldErrors.length)}
				errorMsg={fieldErrors[0]?.message}
				onChange={handleChange}
				value={emailValue || ""}
			/>
		</StyledFieldContainer>
	);
};

export default EmailField;
