import { ReactComponent as ChevronLeft } from "./ChevronLeft.svg";
import { ReactComponent as ChevronRight } from "./ChevronRight.svg";
import SvgIcon from "../SvgIcon";

interface IconChervonProps {
	direction: "left" | "right";
	id?: any;
	className?: string;
}

const IconChevron: React.FC<IconChervonProps> = ({
	id,
	direction,
	className,
}) => {
	return (
		<SvgIcon
			id={id}
			svg={direction === "left" ? <ChevronLeft /> : <ChevronRight />}
			className={className}
		/>
	);
};

export default IconChevron;
