import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { dark, darkGrey, disabled, white } from "utilities/variables/colors";
import { aboveMedium, aboveSmall } from "utilities/variables/media";

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header__nav {
			display: flex;
			align-items: center;
			gap: ${remCalc(3)};

			.swiper-button-lock {
				display: none;
			}
		}
	}

	.swiper {
		.swiper-slide {
			width: ${remCalc(128)};

			@media ${aboveSmall} {
				width: ${remCalc(188)};
			}

			@media ${aboveMedium} {
				width: ${remCalc(307)};
			}

			.ProductCard {
				width: 100%;
			}
		}
	}

    svg {
        transform: scale(0.7);
    }
`;

export const StyledButton = styled.button`
	display: none;
	background-color: ${white};

	i svg {
		stroke: ${dark};
		stroke-width: ${remCalc(2)};
	}

	&:hover {
		i svg {
			stroke: ${darkGrey};
		}
	}

	&:disabled {
		i svg {
			stroke: ${disabled};
		}
	}

	@media ${aboveMedium} {
		display: block;
	}
`;
