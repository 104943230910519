import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import Label from "components/common/form/Label/Label";
import RadioButtonGroup from "components/common/form/RadioButtonGroup/RadioButtonGroup";
import { Fragment, useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

const NewsLetterSubscribe: React.FC<{
  onChange: (...event: any[]) => void;
}> = ({
  onChange,
}) => {
  const [subscribe, setSubsribe] = useState(false);
  const subscribeOptions = ["Women", "Men", "Both"];
  const { t } = useTranslation();

  const handleOnSubscribeClick = (e: Event) => {

    if(subscribe) {
      handelChange(""); // clear the value if unchecked
    }

    setSubsribe((prev) => !prev);
  };

  const handelChange = (value: any) => {
    onChange(value);
  };

  return (
    <Fragment>
      <InputCheckbox
        value={subscribe}
        label={t("form.registerSubscribeNewsletter") as string}
        onChange={handleOnSubscribeClick}
      />
      {subscribe && (
        <div>
          <Label>{t("form.registerIamInterested")}</Label>
          <RadioButtonGroup
            values={subscribeOptions}
            onChange={handelChange}
            styles={{
              direction: "row",
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default NewsLetterSubscribe;
