import { wordPressApi } from "app/api/wordpress/wordPressApi";
import { orderByDistance as geolibOrderByDistance } from "geolib";
import { useMemo } from "react";
import { Store } from "ts/types";

const useStores = () => {
	const { data, isLoading } = wordPressApi.useGetStoresQuery();

	const memoStores = useMemo(() => {
		if (isLoading || !data) {
			return [];
		}

		// remove any stores without lat & lng
		let stores = data.filter(
			(store) =>
				store.storeInformation.storeLocation.latitude &&
				store.storeInformation.storeLocation.longitude,
		);

		return stores;
	}, [data]);

	const orderByDistance = (
		latitude: string | number,
		longitude: string | number,
		stores?: Store[],
	) => {
		let storesToOrder = [] as Store[];

		// if nothing is pass to stores, use the local data
		if (!stores) {
			storesToOrder = memoStores || [];
		} else {
			storesToOrder = stores;
		}

		if (!storesToOrder) {
			return [];
		}

		const orderedByDistance = geolibOrderByDistance(
			{
				latitude: latitude,
				longitude: longitude,
			},
			storesToOrder.map((store) => {
				return {
					latitude: parseFloat(store.storeInformation.storeLocation.latitude),
					longitude: parseFloat(store.storeInformation.storeLocation.longitude),
				};
			}),
		);

		const storesOrderByDistance = [] as Store[];

		orderedByDistance.forEach((distance: any) => {
			const matchingStores = storesToOrder.filter((store) => {
				const latitude = parseFloat(
					store.storeInformation.storeLocation.latitude,
				);
				const longitude = parseFloat(
					store.storeInformation.storeLocation.longitude,
				);

				return (
					distance.latitude === latitude && distance.longitude === longitude
				);
			});

			matchingStores.forEach((store) => storesOrderByDistance.push(store));
		});
		storesOrderByDistance.filter((store) => store !== undefined);

		// removes any introduced duplicates
		return [...new Map(storesOrderByDistance.map((a) => [a.id, a])).values()];
	};

	const getForCountries = (countries: string[]) => {
		if (!memoStores) {
			return [];
		}

		if (!countries.length) {
			return memoStores;
		}

		countries = countries.map((country) => country.trim().toLowerCase());

		return memoStores.filter((store) =>
			countries.some((country) =>
				country.includes(store.storeInformation.address.country.toLowerCase()),
			),
		);
	};

	return {
		isLoading,
		data: memoStores,
		orderByDistance,
		getForCountries,
	};
};

export default useStores;
