type Props = {
	search: string;
	result: string;
	onClick?:Function;
};

const Suggestion = ({ search, result, onClick, ...props }: Props) => {
	const searchLower = search.toLowerCase();
	const resultLower = result.toLowerCase();
	const indexOfSearch = resultLower.indexOf(searchLower);
	const hasMatch = indexOfSearch !== -1;

	return (
		<span {...props} onClick={()=>onClick && onClick(result)}>
			{hasMatch ? (
				<>
					<b>{result.slice(0, indexOfSearch)}</b>
					{result.slice(indexOfSearch, indexOfSearch + search.length)}
					<b>{result.slice(indexOfSearch + search.length)}</b>
				</>
			) : (
				<b>{result}</b>
			)}
		</span>
	);
};

export default Suggestion;
