import TextButton from "components/common/ui/Button/TextButton";
import useOutsideClick from "hooks/useOutsideClick";
import React from "react";
import { Portal } from "react-portal";
import { useLocation } from "react-router";
import {
	StyledContainer,
	StyledContent,
	StyledHeader,
	StyledHeading,
	StyledLinksContainer,
	StyledOverlay,
	StyledText,
	StyledWrapper,
} from "./PdpShareModal.Styled";
import Button from "components/common/ui/Button/Button";
import IconClose from "components/common/ui/Icons/IconClose/IconClose";
import PdpCopyUrl from "./PdpCopyUrl/PdpCopyUrl";
import IconSocial from "components/common/ui/Icons/IconSocial/IconSocial";
import { useTranslation } from "react-i18next";
import ShareModal from "components/common/ui/ShareModal/ShareModal";

const PdpShareModal = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	const { t } = useTranslation();

	const location = useLocation();

	React.useEffect(() => {
		setIsOpen(false);
	}, [location]);

	return (
		<>
			<TextButton onClick={() => setIsOpen(true)}>{t("pdp.sharePage")}</TextButton>
			<Portal node={document && document.getElementById("portal__modal")}>
				<ShareModal
					isOpen={isOpen}
					handleClose={handleClose}
					url={window.location.pathname}
					labelTranslationKey="share"
					options={{
						showHelperText: true,
						showSocialLinks: true
					}}
				/>
			</Portal>
		</>
	);
};

export default PdpShareModal;
