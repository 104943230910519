import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = "cms.monaonline.com";

const baseQuery = fetchBaseQuery({
	baseUrl: `https://${BASE_URL}/graphql`,
});

export const bigCommerceStoreBaseQuery = async (args, api, extraOptions) => {
	const bcStore = api.getState().bcStore.store;

	if (!bcStore) {
		return {
			error: {
				status: 400,
				statusText: "Store unknown",
			},
		};
	}

	let endpointUrl = typeof args === "string" ? args : args.url;

	if (args.body.query) {
		args.body.query = args.body.query.replace(/\s+/g, " ").trim();
	}

	let adjustedArgs;

	if (args.body.query?.startsWith("query")) {
		const b = {
			// ...args.body,
			query: JSON.stringify(args.body.query),
			variables: args.body.variables && JSON.stringify(args.body.variables),
		};

		let queryParam = b.query.substring(1, b.query.lastIndexOf('"'));

		delete args.body;
		endpointUrl = `https://${
			bcStore.code
		}.${BASE_URL}/graphql?query=${queryParam.replace(/[\\]+/g, "")}`;

		endpointUrl = b.variables
			? `${endpointUrl}&variables=${b.variables}`
			: endpointUrl;

		adjustedArgs =
			typeof args === "string"
				? endpointUrl
				: { ...args, url: endpointUrl, method: "GET" };
	} else {
		endpointUrl = `https://${bcStore.code}.${BASE_URL}/graphql`;

		adjustedArgs =
			typeof args === "string"
				? endpointUrl
				: {
						...args,
						url: endpointUrl,
				  };
	}

	// provide the amended url and other params to the raw base query
	return baseQuery(adjustedArgs, api, extraOptions);
};

export const wordPressGQL = createApi({
	reducerPath: "wordPressGQL",
	baseQuery: bigCommerceStoreBaseQuery,
	endpoints: (builder) => ({}),
});
