import { gql } from 'graphql-request';

const wordPressGetLandingPageData = (uri: string) => ({
    operationName: 'GetLandingPageData',
    variables: {
        uri,
    },
    query: gql`
        query GetLandingPageData($uri: String!) {
            pageBy(uri: $uri) {
                id
                seo {
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    title
                }
                homepages {
                    heroCarousel {
                        fieldGroupName
                        imageOrVideo
                        images {
                            fieldGroupName
                            link {
                                target
                                title
                                url
                            }
                            desktopImage {
                                altText
                                mediaItemUrl
                                title(format: RENDERED)
                            }
                            mobileImage {
                                altText
                                mediaItemUrl
                                title(format: RENDERED)
                            }
                            ctaButtons {
                                ctaButton1 {
                                    ...AcfLinkFragment
                                }
                                ctaButton2 {
                                    ...AcfLinkFragment
                                }
                            }
                        }
                        video {
                            desktopVideo
                            link {
                                url
                                target
                                title
                            }
                            mobileVideo
                            fieldGroupName
                        }
                    }
                    content {
                        ... on Page_Homepages_Content_SingleLandscape {
                            fieldGroupName
                            images {
                                desktopImage {
                                    ...MediaItemFragment
                                }
                                mobileImage {
                                    mediaItemUrl
                                }
                            }
                            link {
                                ...AcfLinkFragment
                            }
                        }
                        ... on Page_Homepages_Content_DoubleImagesLandscape {
                            fieldGroupName
                            image1 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                            image2 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                        }
                        ... on Page_Homepages_Content_DoubleImagesPortrait {
                            fieldGroupName
                            image1 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                            image2 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                        }
                        ... on Page_Homepages_Content_TripleImagesPortrait {
                            fieldGroupName
                            image1 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                            image2 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                            image3 {
                                images {
                                    desktopImage {
                                        ...MediaItemFragment
                                    }
                                    mobileImage {
                                        mediaItemUrl
                                    }
                                }
                                link {
                                    ...AcfLinkFragment
                                }
                            }
                        }
                    }
                }
            }
        }
        fragment MediaItemFragment on MediaItem {
            altText
            mediaItemUrl
        }

        fragment AcfLinkFragment on AcfLink {
            target
            title
            url
        }
    `,
});

export default wordPressGetLandingPageData;
