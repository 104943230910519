import { useLazyGetBlogDataQuery } from 'app/api/blog/blogApi';
import { useEffect, useState } from 'react';

const useBlogPostPagination = (blogPageTitle: string, pageSize?: number) => {
	const pagesToPreload = 3;

	// this is the only bit you need to change, and update references to this
	const [getBlogPosts, getBlogPostsResult] =
	useLazyGetBlogDataQuery();

	const [pages, setPages] = useState<any[][]>([]);
	const [pageInfo, setPageInfo] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [uninitialized, setUninitialized] = useState(true);
	const [activePage, setActivePage] = useState<number>();
	const [tagFilter, setTagFilter] = useState<string>('');

	const preloadPages = async () => {
		setUninitialized(false);
		setLoading(true);
		let hasNext = true;
		let cursor = '';

		const pages = [] as any[];
		const pageInfos = [] as any[];

		const pagesToGet =
			activePage !== undefined && activePage !== 0
				? activePage + (pagesToPreload - 1)
				: pagesToPreload;

		for (let i = pages.length; i < pagesToGet; i++) {
			if (!hasNext) {
				break;
			}
			const res = await getBlogPosts({
				blogPageTitle,
				pageSize: pageSize || 2,
				cursor: cursor,
				tag:tagFilter,
			}).unwrap();

			const pageInfo = res?.pageInfo;

			if (res?.posts.length) {
				pages.push(res?.posts);
				pageInfos.push(pageInfo);
			}

			hasNext = pageInfo.hasNextPage;
			cursor = pageInfo.endCursor;
		}

		if (pages.length > 0) {
			setPages(pages);
			setPageInfo(pageInfos);
		}

		setLoading(false);
	};

	useEffect(() => {
		preloadPages();
	}, [tagFilter]);

	const prefetchPage = async (page: number) => {
		if (!getBlogPostsResult.isFetching) {
			if (pages.length >= page) {
				return;
			}

			const lastPageInfo = pageInfo[pageInfo.length - 1];

			if (lastPageInfo && lastPageInfo.hasNextPage) {
				const res = await getBlogPosts({
					blogPageTitle,
					pageSize: pageSize || 2,
					cursor: lastPageInfo.endCursor,
					tag: tagFilter,
				}).unwrap();

				setPages([...pages, res?.posts]);
				setPageInfo([...pageInfo, res?.pageInfo]);
			}
		}
	};

	useEffect(() => {
		if (activePage) {
			const pageIndex = activePage - 1;
			const remainingPreloadedPages = pages.length - pageIndex;

			if (remainingPreloadedPages < pagesToPreload) {
				prefetchPage(pages.length + 1);
			}
		}
	}, [activePage]);

	const getPage = (page?: number) => {
		if (page) {
			if (activePage !== page) {
				setActivePage(page);
			}

			return pages[page - 1] || [];
		}
		page = activePage ? activePage - 1 : 0;
		return pages[page] || [];
	};

	return {
		uninitialized,
		loading,
		totalPages: pages.length,
		activePage,
		activeTag:tagFilter,
		getPage,
		setActivePage,
		setTagFilter,
	};
};

export default useBlogPostPagination;