import React, { Fragment } from "react";

import Typography from "components/common/ui/Typography/Typography";
import useBreakpoints from "hooks/useBreakpoints";
import ForgotPasswordForm from "./ResetPasswordForm";
import { HelperText } from "../PasswordReset.Styled";
import { useSearchParams } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
	const { large } = useBreakpoints();
	const { t } = useTranslation();

	return (
		<Fragment>
			<Typography variant="heading" size="small">
				Change Password
			</Typography>
			<HelperText>
				<Typography variant="body" size="small">
					{t("form.validation.password.alpha_num")}
				</Typography>
			</HelperText>

			<ResetPasswordForm large={large} />
		</Fragment>
	);
};

export default ResetPassword;
