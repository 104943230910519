import { useGetRedirectLinksQuery } from "app/api/redirects/wordPressRedirectLinksApi";
import { validateUrlSlashes } from "app/utils/urlUtils";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useMatch } from "react-router-dom";

const WordPressRedirects = ({ children }: any) => {
	const getRedirects = useGetRedirectLinksQuery();
	const [redirectLinks, setRedirectLinks] = useState<any[]>();

	const pathMatch = useMatch(`/:locale/*`);

	const path = useMemo(() => {
		if (!pathMatch || !pathMatch.params["*"]) {
			return undefined;
		}

		return validateUrlSlashes(pathMatch.params["*"]);
	}, [pathMatch]);

	const redirectPath = useMemo(() => {
		if (redirectLinks && path) {
			let matchingResult = redirectLinks.find(
				(redirect: any) => redirect.origin === path,
			);

			return matchingResult;
		}

		return undefined;
	}, [redirectLinks, path]);

	useEffect(() => {
		if (getRedirects.data) {
			const redirects = [] as any[];

			getRedirects.data.redirects.forEach((redirect: any) =>
				redirects.push({
					origin: validateUrlSlashes(redirect.origin),
					target: validateUrlSlashes(redirect.target),
					code: redirect.code,
				}),
			);

			setRedirectLinks(redirects);
		}
	}, [getRedirects]);

	if(getRedirects.isUninitialized || getRedirects.isLoading) {
		return null;
	}

	if (redirectPath && pathMatch) {
		return (
			<Navigate
				replace
				to={`/${pathMatch.params.locale}${redirectPath.target}`}
			/>
		);
	} else {
		return <>{children}</>;
	}
};

export default WordPressRedirects;
