import ButtonRadioGroup from "components/common/form/ButtonRadioGroup/ButtonRadioGroup";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	StyledButton,
	StyledHeading,
	StyledHeadingWrapper,
	StyledInput,
	StyledMeasurementsWrapper,
	StyledRow,
	StyledSubtitle,
} from "../../PdpSizeCalculator.Styled";

interface Props {
	data: any;
	onValueChange: any;
	changeStep: () => void;
	units: any;
	selectedUnit: any;
	setSelectedUnit: any;
	inputValues: any;
}

const InputStep: React.FC<Props> = ({
	data,
	onValueChange,
	changeStep,
	units,
	selectedUnit,
	setSelectedUnit,
	inputValues,
}) => {
	const { t, ready } = useTranslation();
	const [isDisabled, setIsDisabled] = useState(true);

	const possibleInputs: {
		key: string;
		name: string;
	}[] = useMemo(() => {
		const apparelValues = Object.values(data) as any[];
		const newApparelValues: string[] | [] = apparelValues.length
			? Object.keys(apparelValues[0])
			: [];
		const transformedValues: {
			key: string;
			name: string;
		}[] = [];

		newApparelValues.forEach((apparelSize: string) => {
			transformedValues.push({
				key: apparelSize,
				name: t(`sizeCalculator.measurement.${apparelSize.toLowerCase()}`),
			});
		});

		return transformedValues;
	}, [data]);

	const handleUnitChange = (unit: string) => {
		setSelectedUnit(unit);
	};

	const handleInputChange = (name: string, value: string) => {
		onValueChange((previousState: any) => {
			if (value) {
				setIsDisabled(false);
				return {
					...previousState,
					[name]: value,
				};
			} else {
				delete previousState[name];

				if (!Object.keys(previousState).length) {
					setIsDisabled(true);
				}

				return {
					...previousState,
				};
			}
		});
	};

	useEffect(() => {
		if (Object.keys(inputValues).length) {
			setIsDisabled(false);
		}
	}, []);

	if (!ready) {
		return null;
	}

	return (
		<Fragment>
			<StyledHeadingWrapper>
				<StyledHeading>{t("sizeCalculator.title")}</StyledHeading>
				<StyledSubtitle>{t("sizeCalculator.subTitle")}</StyledSubtitle>
			</StyledHeadingWrapper>
			<StyledMeasurementsWrapper>
				<StyledRow>
					<StyledHeading>{t("text.measurements")}</StyledHeading>
					<ButtonRadioGroup
						name="unit"
						options={units}
						defaultValue={selectedUnit}
						onChange={handleUnitChange}
					/>
				</StyledRow>
				<StyledRow>
					{possibleInputs.map((input) => (
						<StyledInput
							key={input.key}
							type="number"
							min={0}
							step={1}
							label={input.name}
							value={inputValues[input.key] || ""}
							onChange={(e) => handleInputChange(input.key, e.target.value)}
						/>
					))}
				</StyledRow>
			</StyledMeasurementsWrapper>
			<StyledButton
				variant="primary_01"
				onClick={changeStep}
				disabled={isDisabled}
			>
				{t("text.findMySize")}
			</StyledButton>
		</Fragment>
	);
};

export default InputStep;
