import Button from "components/common/ui/Button/Button";
import FormattedDate from "components/common/ui/Date/FormattedDate";
import { useTranslation } from "react-i18next";
import { Shipment } from "types/Order";
import { FontBold, FontNormal, TrackOrderBtnContainer } from "./Shipment.Styled";

type Props = {
	shipmentData: Shipment;
};

const Shipments = ({ shipmentData }: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<div className="shipping-details__field">
				<FontBold className="bold">{t("date_shipped")}:</FontBold>
				<FontNormal>
					<FormattedDate date={new Date(shipmentData?.date_created || "")} />
				</FontNormal>
			</div>
			<div className="shipping-details__field">
				<FontBold className="bold">{t("shipping_method")}:</FontBold>
				<FontNormal>{shipmentData.shipping_method}</FontNormal>
			</div>
			<div className="shipping-details__field">
				<FontBold className="bold">{t("tracking_link")}:</FontBold>
				<FontNormal>{shipmentData.tracking_number}</FontNormal>
			</div>
			<TrackOrderBtnContainer>
				<Button variant="primary_03" textLight>
					{t("track_order")}
				</Button>
			</TrackOrderBtnContainer>

			<hr />
		</>
	);
};

export default Shipments;
