import MenuItem, {
  MenuItemProps,
} from "components/common/ui/Menu/MenuItem/MenuItem";
import { css } from "styled-components";
import { dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";

const additonStyles = css`
  margin-top: 16px;

  button,
  a {
    font-family: ${Roboto};
    color: ${dark};
    font-size: 14px;
    letter-spacing: 0.7px;
    font-weight: 700;
    text-transform: uppercase;

    &:hover,
    &:active,
    .active {
      color: ${darkGrey};

      .icon {
        color: ${darkGrey};
      }
    }
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    text-decoration: none;
  }

  &:first-of-type {
    margin-top: 0;
  }

  .icon {
    vertical-align: bottom;
    font-size: 20px;
  }
`;

const SidenavFooterMenuItem: React.FC<MenuItemProps> = ({ children }) => {
  return <MenuItem additionalStyles={additonStyles}>{children}</MenuItem>;
};

export default SidenavFooterMenuItem;
