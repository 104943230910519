import { css } from "styled-components";
import SvgIcon from "../SvgIcon";
import { ReactComponent as Info } from "./Information Icon.svg";

const IconInfo: React.FC = () => {
	return (
		<SvgIcon
			svg={<Info />}
			additionalStyles={css`
				svg {
					width: 16px;
				}
			`}
		/>
	);
};

export default IconInfo;
