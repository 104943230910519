import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	Container,
	InputRow,
	StyledInputCheckbox,
} from "./CookiePolicyBanner.Style";
import CookiePolicyDetails from "./CookiePolicyDetails";
import { useState } from "react";

type Props = {
	onSubmit: Function;
};

function CookiePolicyBanner({ onSubmit }: Props): JSX.Element {
	const { t } = useTranslation();
	const header: string = t("this_website_uses_cookies");
	const details: string = t("cookies_details");
	const inputCheckBoxesNames: object[][] = [
		[
			{
				name: "necessary_cookies",
				label: t("necessary_cookies"),
				disabled: true,
			},
			{
				name: "permanent_cookies",
				label: t("permanent_cookies"),
				disabled: false,
			},
		],
		[
			{
				name: "statistical_cookies",
				label: t("statistical_cookies"),
				disabled: false,
			},
			{
				name: "marketing_cookies",
				label: t("marketing_cookies"),
				disabled: false,
			},
		],
	];
	const learnMoreBtn: string = t("learn_more");
	const agreeBtn: string = t("i_agree");

	const { control, handleSubmit } = useForm();

	const [isVisible, setIsVisible] = useState<boolean>(false);

	function HandleSubmit(values: any) {
		onSubmit({ ...values, ...{ necessary_cookies: true } });
	}

	return (
		<Container>
			<div className="cookie-banner-heading">
				<Typography variant="heading" size="small">
					{header}
				</Typography>
				<Typography variant="body" size="small" className="details">
					{details}
				</Typography>
			</div>
			<form onSubmit={handleSubmit(HandleSubmit)}>
				<div className="cookie-form-options-control">
					{inputCheckBoxesNames.map((fields: object[], rowKey: number) => {
						return (
							<InputRow key={rowKey}>
								{fields.map((input: any, key: number) => {
									return (
										<Controller
											key={key}
											name={Object(input).name}
											control={control}
											render={({ field }) => {
												const value = input.disabled;

												return (
													<StyledInputCheckbox
														{...field}
														disabled={input.disabled}
														label={Object(input).label}
														value={input.disabled}
													/>
												);
											}}
										/>
									);
								})}
							</InputRow>
						);
					})}
				</div>
				<div className="cookie-form-submit-control">
					<Button
						variant="primary_03"
						textLight
						onClick={() => {
							setIsVisible(isVisible ? false : true);
						}}
					>
						{isVisible ? t("close") : learnMoreBtn}
					</Button>
					<Button variant="primary_01" type={"submit"}>
						{agreeBtn}
					</Button>
				</div>
				{isVisible && <CookiePolicyDetails />}
			</form>
		</Container>
	);
}

export default CookiePolicyBanner;
