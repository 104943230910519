import { CarouselContainer, ChevronButton } from "../BlogPost.Styled";
import { Swiper, SwiperSlide} from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import IconChevron from "components/common/ui/Icons/IconChevron/IconChevron";
import { useRef } from "react";

type Props = {
    arrayOfChildrenNodes: any[];
}

function BlogCarousel({arrayOfChildrenNodes}: Props): JSX.Element  {
    const images = arrayOfChildrenNodes;
    const swiperRef = useRef<SwiperType>();

    let imagesContainer: Element[] | object[] | any = [];

    images.forEach((image: Element) => {
        if((image as Element).children) {
            const Image = ((((image as Element).children[1] as Element).children[1] as Element).children[0] as any).attribs as any;
            const Link = ((((image as Element).children[1] as Element).children[1]) as any).attribs.href as string;
            const imageData: any = {url: Image.src, altText: Image.alt, imageLink: Link};
            imagesContainer.push(imageData);
        }
    })

    return (
        <CarouselContainer>
            <ChevronButton
                onClick={() => swiperRef.current && swiperRef.current.slidePrev()}
            >
                <IconChevron direction={"left"} />
            </ChevronButton>

            <Swiper
                slidesPerView={"auto"}
                spaceBetween={8}
                onBeforeInit={(swiper) => { swiperRef.current = swiper; }}
                loop={true}
            >
            {
                imagesContainer.map((image: any, key: any) => (
                    <SwiperSlide
                        key={key}
                    >
                        <a href={image.imageLink}>
                            <img src={image.url} alt={image.altText}/>
                        </a>
                    </SwiperSlide>
                ))
            }
            </Swiper>

            <ChevronButton
                onClick={() => swiperRef.current && swiperRef.current.slideNext()}
            >
                <IconChevron direction={"right"} />
            </ChevronButton>
        </CarouselContainer>
    )
}

export default BlogCarousel;