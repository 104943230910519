import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, lightGrey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

export const StyledProductCard = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: solid 1px ${lightGrey};
	padding-block: 1.5rem;
	position: relative;

	&:last-of-type {
		border-bottom: none;
	}
	@media ${aboveMedium} {
		/* margin-right: 2rem; */
		&:last-of-type {
			border-bottom: solid 1px ${lightGrey};
		}

		&.is-mini-basket {
			&:last-of-type {
				border-bottom: none;
			}
		}
	}

	.product-details {
		width: 100%;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			width: 100%;

			& > * + * {
				margin-top: ${remCalc(4)}
			}

			li {
				font-size: 0.875rem;
				text-transform: uppercase;
				color: ${accountDarkGrey};
			}
		}
	}

	.image-wrapper {
		display: flex;
		flex-direction: column;
		width: 25%;
		margin-right: 1rem;
		min-width: ${remCalc(112)};

		@media ${aboveMedium} {
			min-width: ${remCalc(128)};
		}
	}

	img {
		width: 100%;
	}

	h2 {
		font-size: 1rem;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 0.75rem;

		& > a {
			color: ${dark};
			font-weight: 700;
			text-decoration: none;
		}
	}

	h3 {
		margin: 0;
		margin-bottom: 0.75rem;
		font-size: 1rem;
		font-weight: 700;
		text-transform: uppercase;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		width: 100%;

		& > * + * {
			margin-top: 0.5rem;
		}

		li {
			font-size: 0.875rem;
			text-transform: uppercase;
			color: ${accountDarkGrey};
		}
	}

	.price-total {
		font-weight: 700;
		font-size: 1em;
		color: ${dark};

		@media ${aboveMedium} {
			margin-left: auto;
			display: block;
		}

		& > div {
			display: inline-block;
		}
	}

	.item-remove {
		margin-top: ${remCalc(8)};
		font-size: 0.8rem;
		text-decoration: underline;
		color: #525a62;
		cursor: pointer;

		&:hover {
			color: #212529;
		}

		/* left: 0; */
		@media ${aboveMedium} {
			position: absolute;
			/* left:auto; */
			right: 0;
			bottom: 1.5rem;
		}
	}

	.quantityChanger {
		margin-top: ${remCalc(10)};
		margin-bottom: ${remCalc(8)};

		&.is-mini-basket {
			margin-top: ${remCalc(4)};
			margin-bottom: ${remCalc(10)};
		}
	}

	.total-quantity-wrapper {
		@media ${aboveMedium} {
			display: flex;
			align-items: center;
		}
	}

	&.is-mini-basket {
		.image-wrapper {
			width: 40%;
		}

		.price-total {
			text-align: left;
		}

		.item-remove {
			position: absolute;
			right: 0;
			bottom: 1.5rem;
		}

		.total-quantity-wrapper {
			display: block;
		}
	}

	&.is-basket-confirmation {
		.image-wrapper {
			max-width: 97px;
			width: 100%;
		}
		.total-quantity-wrapper {
			align-items: flex-start;
		}

		.price-total {
			margin-left: unset;
		}
	}
`;
