import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Provider } from "react-redux";
import { createPortals } from "utilities/portals";
import store from "./redux/store";

import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { CartProvider } from "hooks/cart/useCart";

const container = document.getElementById("root");
const root = createRoot(container!);

createPortals(container);

root.render(
	// Using StrictMode breaks google-map-react library. This is a temporary workaround
	// <React.StrictMode>
	<Provider store={store}>
		<HelmetProvider>
			<CartProvider>
				<App />
			</CartProvider>
		</HelmetProvider>
	</Provider>,
	// </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
