import React, { Fragment } from "react";

import Typography from "components/common/ui/Typography/Typography";
import useBreakpoints from "hooks/useBreakpoints";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { HelperText } from "../PasswordReset.Styled";
import { useTranslation } from "react-i18next";

const ForgotPassword: React.FC = () => {
	const { large } = useBreakpoints();
	const { t } = useTranslation();

	return (
		<Fragment>
			<Typography variant="heading" size="small">
				{t("form.signInForgotPassword")}
			</Typography>
			<HelperText>
				<Typography variant="body" size="small">
					{t("forgotPassword.message")}
				</Typography>
			</HelperText>

			<ForgotPasswordForm large={large} />
		</Fragment>
	);
};

export default ForgotPassword;
