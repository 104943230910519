import ButtonWithIcon from "components/common/ui/Button/ButtonWithIcon/ButtonWithIcon";
import PaginationArrow from "components/common/ui/Icons/PaginationArrow";
import { PaginationProps } from "hooks/useCategoryPagination";
import { Fragment, FunctionComponent } from "react";
import { StyledPaginationWrapper } from "./PlpPagination.Styled";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { Link, useSearchParams } from "react-router-dom";


interface PlpPaginationProps {
    condensed?: boolean;
    loading?: boolean;
    pagination: PaginationProps,
    onChange?: (page: number) => void;
}

const PlpPagination: FunctionComponent<PlpPaginationProps> = ({
    condensed = false,
    loading,
    pagination,
    onChange
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const pages = new Array(pagination.total_pages)
        .fill(null)
        .map((_, i) => i + 1)
        .filter((pageNumber) =>
            (!condensed &&
                (
                    pageNumber >= pagination.current_page - (pagination.current_page === pagination.total_pages ? 2 : 1) &&
                    pageNumber <= pagination.current_page + (pagination.current_page === 1 ? 2 : 1)
                )
            ) ||
            (condensed &&
                (pageNumber === 1 ||
                    pageNumber === pagination.total_pages ||
                    (pageNumber >= pagination.current_page - 1 &&
                        pageNumber <= pagination.current_page + 1))));

    if (loading) {
        return <PaginiationSkeleton />;
    }

    if (pagination.total === 0) {
        return null;
    }

    const handleSearchParamsUpdate = (pageNumber: number) => {
        searchParams.set('page', `${pageNumber}`)
        setSearchParams(searchParams)

        onChange?.(pageNumber)
    }

    return <StyledPaginationWrapper aria-label="pagination">
        <ul
            className={`pagination-list${condensed ? ' pagination-list--condensed' : ''
                }`}
        >
            {pagination.current_page > 1 && (
                <li className="pagination-item pagination-item--previous">
                    <Link
                        to={pagination.current_page > 1 ? `?page=${pagination.current_page - 1}` : ''}
                        className="pagination-link"
                        aria-label="Previous page"
                        onClick={(event) => {
                            event.preventDefault();
                            handleSearchParamsUpdate(pagination.current_page - 1)
                        }}
                    >
                        <ButtonWithIcon
                            icon={<PaginationArrow variant="prev" />}
                        />
                    </Link>
                </li>
            )}

            {pages.map((pageNumber) => (
                <Fragment key={pageNumber}>
                    {condensed &&
                        pageNumber === pagination.current_page - 1 && pageNumber !== 1 ? (
                        <li className="pagination-item--spacer">...</li>
                    ) : null}
                    <li
                        className={`pagination-item${pagination.current_page === pageNumber
                            ? ' pagination-item--current active'
                            : ''
                            }`}
                    >
                        <Link
                            to={pagination.current_page !== pageNumber ? `?page=${pageNumber}` : ''}
                            onClick={(event) => {
                                event.preventDefault();
                                handleSearchParamsUpdate(pageNumber)
                            }}
                        >
                            {pageNumber}
                        </Link>
                    </li>
                    {condensed &&
                        pageNumber === pagination.current_page + 1 && pageNumber !== pagination.total_pages ? (
                        <li className="pagination-item--spacer">...</li>
                    ) : null}
                </Fragment>
            ))}

            {pagination.current_page < pagination.total_pages && (
                <li className="pagination-item pagination-item--next">
                    <Link
                        to={pagination.current_page < pagination.total_pages ? `?page=${pagination.current_page + 1}` : ''}
                        className="pagination-link"
                        aria-label="Next page"
                        onClick={(event) => {
                            event.preventDefault();
                            handleSearchParamsUpdate(pagination.current_page + 1)
                        }}
                    >
                        <ButtonWithIcon
                            icon={<PaginationArrow variant="next" />}
                        />
                    </Link>
                </li>
            )}
        </ul>
    </StyledPaginationWrapper>
}

const PaginiationSkeleton = () => {
    return (
        <StyledPaginationWrapper className="Pagination">
            <div className="Pagination__button-wrapper">
                <Skeleton />
            </div>
            <ul>
                <Skeleton width={50} />
            </ul>
            <div className="Pagination__button-wrapper">
                <Skeleton />
            </div>
        </StyledPaginationWrapper>
    );
};

export default PlpPagination;