import Skeleton from "components/common/ui/Skeleton/Skeleton";
import {
  BelowImage,
  ContentLeft,
  ContentRight,
  ProductImages,
  ProductCardWrapper,
} from "./ProductCard.Styled";

interface Props {
  layout: number;
  loading?: boolean;
}

const ProductCardSkeleton: React.FC<Props> = ({ layout }) => {
  return (
    <ProductCardWrapper className="ProductCard">
      <ProductImages>
        <Skeleton containerClassName="carousel" />
      </ProductImages>

      {layout < 2 && (
        <BelowImage>
          <ContentLeft>
            <Skeleton className="name" count={2} />
            <Skeleton className="price" />
            <Skeleton className="more-colours" />
          </ContentLeft>
          <ContentRight>
            <Skeleton className="options" />
          </ContentRight>
        </BelowImage>
      )}
    </ProductCardWrapper>
  );
};

export default ProductCardSkeleton;
