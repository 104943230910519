import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "redux/hooks";

import Link from "components/common/ui/Link/Link";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";

import useBreakpoints from "hooks/useBreakpoints";

import {
  StyledInputSelect,
  StyledLi,
  StyledSkeleton,
  StyledUl,
  StyledWrapper,
} from "./SpNav.Styled";

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  className?: string;
  isLoading?: boolean;
  title: string;
  options: Option[];
};

const SpNav = ({ isLoading, title, options, ...props }: Props) => {
  const { pathname } = useLocation();

  const url = pathname
    .split("/")
    .filter((i) => Boolean(i))
    .slice(1)
    .join("/");

  const value = React.useMemo(() => {
    return options.find((option) => String(option.value).includes(url))?.value;
  }, [options, url]);

  const storeCode = useSelector((state) => state.bcStore.store?.code);

  const navigate = useNavigate();

  const handleChange = (option: Option) => {
    navigate(`/${storeCode}${option}`);
  };

  const { large } = useBreakpoints();

  if (isLoading || !value) {
    if (large) {
      return (
        <StyledWrapper {...props}>
          <StyledSkeleton height={24} />
          <StyledUl>
            <StyledSkeleton count={3} height={20} />
          </StyledUl>
        </StyledWrapper>
      );
    } else {
      return (
        <StyledWrapper {...props}>
          <Skeleton height={42} />
        </StyledWrapper>
      );
    }
  }

  if (large) {
    return (
      <StyledWrapper {...props}>
        <Typography variant="heading" size="small">
          {title}
        </Typography>
        <StyledUl>
          {options.map((link, index) => (
            <StyledLi
              key={index}
              className={link.value === value ? "active" : ""}
            >
              <Link to={String(link.value)}>{link.label}</Link>
            </StyledLi>
          ))}
        </StyledUl>
      </StyledWrapper>
    );
  } else {
    return (
      <StyledWrapper {...props}>
        <StyledInputSelect
          label=""
          options={options}
          onChange={handleChange}
          value={value}
        />
      </StyledWrapper>
    );
  }
};

export default SpNav;
