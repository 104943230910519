import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(Backend).use(initReactI18next);

export const init = (lng: string, fallbackLng = "en") => {
	const projectToken = process.env.REACT_APP_SIMPLELOCALIZE_PROJECT_TOKEN || "";

	const loadPath = `https://cdn.simplelocalize.io/${projectToken}/_latest/{{lng}}`;

	i18n.init({
		react: {
			useSuspense: false,
		},
		debug: false,
		lng: lng,
		fallbackLng: fallbackLng,
		backend: {
			loadPath: loadPath,
		},
	});
};

export default i18n;
