import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import styled from "styled-components";
import { dark, darkGrey, grey } from "utilities/variables/colors";
import { aboveMedium } from "utilities/variables/media";

const Wrapper = styled.div`
	margin: auto 0;
	padding: 0 5px;
	width: 100%;
	order: 2;

	margin-top: 16px;

	@media ${aboveMedium} {
		width: 130px;
		margin: auto 0;
		margin-right: 32px;
		order: 0;
	}
`;

const StyledSlider = styled(Slider)`
  .rc-slider-rail {
    background-color: ${grey};
  }

  .rc-slider-track {
    display: none;
  }

  .rc-slider-dot {
    cursor: pointer;

    &-active {
      border-color: #e9e9e9;
    }
  }

  .rc-slider {
    &-handle {
      border: none;
      background-color: ${dark};
      opacity: 1;

      &:hover {
        background-color: ${darkGrey};
      }

      &-dragging {
        box-shadow: unset !important;
        background-color: ${darkGrey};
      }
    }
  }
`;

interface Props {
	value: number;
	onChange: (value: number | number[]) => void;
}

const PlpSlider: React.FC<Props> = ({ value, onChange }) => {
	return (
			<Wrapper>
				<StyledSlider
					min={0}
					max={2}
					step={1}
					dots
					value={value}
					onChange={(value) => onChange(value)}
				/>
			</Wrapper>
	);
};

export default PlpSlider;
