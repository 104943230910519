import { gql } from "graphql-request";
import { ProductOnProduct } from "../fragments/product";

const bigcommerceGetProductsBySkus = (skus: string[]) => {
	return {
		operationName: "GetProductBySku",
		variables: {
			skus: skus,
		},

		query: gql`
			query productsBySku($skus: [Int!]!) {
				site {
					products(entityIds: $skus) {
						pageInfo {
							startCursor
							endCursor
						}
						edges {
							cursor
							node {
                ...Product

							}
						}
					}
				}
			}
      ${ProductOnProduct}

		`,
	};
};

export default bigcommerceGetProductsBySkus;
