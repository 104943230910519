import ErrorContent from "components/common/ui/ErrorContent/ErrorContent";
import Page from "components/common/ui/Page/Page";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageType } from "ts/enums";
import { StyledPage } from "./GenericErrorPage.Styled";

const GenericErrorPage: React.FC = () => {
	const { t, ready } = useTranslation();

	if (!ready) {
		return <Page pageType={PageType.ERROR}></Page>;
	}

	return (
		<StyledPage pageType={PageType.ERROR} title="Error Page">
			<ErrorContent heading={t("pageErrorTitle")}>
				{t("genericErrorPageNotice")}
			</ErrorContent>
		</StyledPage>
	);
};

export default GenericErrorPage;
