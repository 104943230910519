import styled from 'styled-components';
import { remCalc } from 'utilities/styles';
import { dark, darkGrey } from 'utilities/variables/colors';
import { Roboto } from 'utilities/variables/fonts';
import {
	aboveLarge,
	aboveMedium,
	aboveSmall,
	aboveXSmall,
} from 'utilities/variables/media';

export const StyledStaffStoryPageWrapperDesktop = styled.div`
    * {
        font-family: ${Roboto};
    }

    .staff-story--container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: ${remCalc(64)};

        .images-container {

            @media ${aboveMedium} {
                display: grid;
                grid-template-rows: 1fr 1fr 1fr;
                gap: ${remCalc(32)};
            }

            img {
                display: flex;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .text-container {
            color: ${dark};
            padding: 0 ${remCalc(64)};
            margin: auto;

            @media ${aboveLarge} {
                margin-top: 20px;
            }

            h2 {
                text-transform: uppercase;
                font-size: ${remCalc(24)};
                font-weight: 700;
                margin-bottom: 0;
            }

            h3 {
                font-size: ${remCalc(20)};
                font-weight: 700;
            }

            // desktop only
            .paragraphs {
                margin-top: ${remCalc(32)};

                p {
                    font-size: ${remCalc(16)};
                    color: ${darkGrey};
                    margin-bottom: ${remCalc(20)};
                }
            }
        }
    }
`;

export const StyledStaffStoryPageWrapperMobile = styled.div`
    * {
        font-family: ${Roboto};
    }

    .staff-story--container {
        color: ${dark};
			overflow: hidden;

        picture {
			width: 100%;
            min-height: ${remCalc(209)};
            display: block;
        }

        img {
            display: flex;
            object-fit: cover;
            width: 100%;
            height: ${remCalc(209)};
        }

        h2 {
            text-transform: uppercase;
            font-size: ${remCalc(24)};
            font-weight: 700;
            margin-bottom: 0;
            margin-top: ${remCalc(48)};
        }

        h3 {
            font-size: ${remCalc(20)};
            font-weight: 700;
            margin-bottom: 0;
        }

        p {
            font-size: ${remCalc(16)};
            color: ${darkGrey};
        }

        .paragraph-mobile {
            margin-bottom: ${remCalc(20)};

            p:first-of-type {
                margin-top: ${remCalc(32)};
            }

            p:last-of-type {
                margin-bottom: ${remCalc(32)};
            }
        }

        h2, h3, p {
            padding: 0 ${remCalc(24)};
        }

        .skeleton-mobile-image {
            height: ${remCalc(130)};

            @media ${aboveXSmall} {
                height: ${remCalc(200)};
            }

            @media ${aboveSmall} {
                height: ${remCalc(320)};
            }

        }
    }
`;

export const SkeletonMobileWrapper = styled.div`
	margin-left: ${remCalc(24)};
	margin-right: ${remCalc(24)};
`;

export const SkeletonDesktopWrapper = styled.div`
	&.images {
		display: grid;
		gap: ${remCalc(32)};
		height: 100%;
	}

	&.images .react-loading-skeleton {
		height: 100%;
	}

	&.text {
		padding: 0 ${remCalc(64)};
		margin: auto 0;
	}
`;

export const StyledStaffStoryPageWrapper = styled.div`
	.bottom-banner {
		.react-loading-skeleton {
			height: 100%;

			@media ${aboveMedium} {
				height: ${remCalc(435)};
			}
		}
	}
`;
