import Button from "components/common/ui/Button/Button";
import Typography from "components/common/ui/Typography/Typography";
import useOutsideClick from "hooks/useOutsideClick";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "react-portal";
import { Product, ProductVariant } from "ts/types";
import {
	ButtonContainer,
	StyledContainer,
	StyledContent,
	StyledOverlay,
	StyledWrapper,
} from "./AddToCartAlert.Styled";

interface Props {
	product?: Product;
	isOpen: boolean;
	handleClose: () => void;
}

const AddToCartAlert: React.FC<Props> = ({ product, isOpen, handleClose }) => {
	const ref = useOutsideClick(handleClose);
	const { t } = useTranslation();

	if (!isOpen) {
		return null;
	}

	return (
		<Portal node={document && document.getElementById("portal__modal")}>
			<StyledWrapper className="modal__wrapper">
				<StyledOverlay className="modal__overlay">
					<StyledContainer className="modal__container" ref={ref}>
						<StyledContent className="modal__content">
							<Typography variant="body">{t("addToCartAlert")}</Typography>

							<ButtonContainer>
								<Button variant="primary_03" onClick={handleClose}>
									{t("close")}
								</Button>
							</ButtonContainer>
						</StyledContent>
					</StyledContainer>
				</StyledOverlay>
			</StyledWrapper>
		</Portal>
	);
};

export default AddToCartAlert;
