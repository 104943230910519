import Divider from "components/common/ui/Divider/Divider";
import ProductVariants from "components/ProductVariants/ProductVariants";
import styled, { css } from "styled-components";
import { aboveMedium } from "utilities/variables/media";

export const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${aboveMedium} {
    flex-direction: row;
    margin: 0 20px;
  }

  .Carousel {
    flex: 0.55;
    &::before {
       padding-bottom: 138.5%;
    }
  }
`;

const MoreColoursOverride = css`
  @media ${aboveMedium} {
    .MoreColours {
      position: relative;
      margin-top: 16px;
      .Modal {
        top: unset;
        bottom: unset;
        min-width: 118px;
        width: unset;
        max-width: 100%;
      }
    }
  }
`;

export const StyledProductDetailsWrapper = styled.div`
  flex: 0.45;
  padding-top: 24px;

  @media ${aboveMedium} {
    padding-top: 0;
    padding-left: 32px;
  }

  ${MoreColoursOverride};
`;

export const StyledProductDetailsDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const BelowDetailsDivider = styled(Divider)`
  margin-top: 0px;
  margin-bottom: 16px;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledProductVariants = styled(ProductVariants)`
  margin-bottom: 6px;

  .VariantsList {

    margin: 0;
    .ScrollableView {
       justify-content: flex-start;
    }

  }

`;
