import wordPressGetLandingPageData from "app/gql/wordPressGetLandingPageData";
import { wordPressGQL } from "app/services/wordPressGqlService";

export const homepageApi = wordPressGQL.injectEndpoints({
	endpoints: (builder) => ({
		getHomepageData: builder.query({
			query: (url) => ({
				method: "POST",
				body: {
					...wordPressGetLandingPageData(url),
				},
			}),
			transformResponse: ({ data }) => {
				if (!data.pageBy) return;

				const {
					pageBy: {
						homepages: { heroCarousel: heroCarouselData, content },
					},
				} = data;
				const {
					pageBy: { seo },
				} = data;
				const carouselImages = (heroCarouselData ?? []).map((slide) => {
					const slideData = {
						type: slide.imageOrVideo,
						imageAlt: slide.images.desktopImage?.altText,
						slideImage: {
							desktop: {
								imageSrc: slide.images.desktopImage?.mediaItemUrl,
							},
							mobile: {
								imageSrc: slide.images.mobileImage?.mediaItemUrl,
							},
						},
						slideVideo: {
							desktopUrl: slide.video.desktopVideo,
							mobileUrl: slide.video.mobileVideo,
						},
						slideLink: {
							title: slide.images.link?.title || slide.video.link?.title,
							url: slide.images.link?.url || slide.images.link?.url,
						},
						ctaButtons: Object.values(slide.images?.ctaButtons)
							.filter(
								(value) => value && typeof value === "object" && value.title,
							)
							.map((value) => {
								return {
									buttonText: value?.title,
									buttonLinkLocation: value?.url,
								};
							}),
					};

					return slideData;
				});

				const pageContent = content.map((section) => {
					if (
						section.fieldGroupName !== "Page_Homepages_Content_SingleLandscape"
					) {
						const imageArray = Object.values(section)
							.filter((value) => typeof value === "object")
							.map((value) => {
								return {
									image: {
										desktopImage: value.images?.desktopImage?.mediaItemUrl,
										alt: value.images?.desktopImage?.altText,
									},
									ctaText: value.link?.title,
									ctaLink: value.link?.url,
								};
							});
						return {
							orientation: section.fieldGroupName.includes("Portrait")
								? "portrait"
								: "landscape",
							images: imageArray,
						};
					} else {
						return {
							orientation: section.fieldGroupName.includes("Portrait")
								? "portrait"
								: "landscape",
							images: [
								{
									image: {
										desktopImage: section.images?.desktopImage?.mediaItemUrl,
										alt: section.images?.desktopImage?.altText,
									},
									ctaText: section.link?.title,
									ctaLink: section.link?.url,
								},
							],
						};
					}
				});

				return {
					loading: false,
					seo: seo,
					homeHeroCarousel: {
						carouselSetting: {
							showPagination: true,
							showNavigation: true,
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 0,
						},
						carouselSlides: [...carouselImages],
					},
					homePageLayout: pageContent,
				};
			},
		}),
	}),
});

export const { useLazyGetHomepageDataQuery } = homepageApi;
