import {
	Body,
	HeadingLarge,
	HeadingMedium,
	HeadingSmall,
	RichBody,
	ExtraSmall,
} from "./Typography.Styled";

export interface TypographyProps {
	className?: string;
	variant?: "heading" | "body";
	size?: "large" | "medium" | "small" | "extra-small";
	narrow?: boolean;
	as?: any;
	children?: any;
	onClick?: (event: Event) => void;
}

const Typography: React.FC<TypographyProps> = ({
	className,
	variant = "body",
	size = "medium",
	narrow = false,
	as = "",
	children,
	onClick = () => {},
}) => {
	if (variant === "heading") {
		switch (size) {
			case "large":
				return (
					<HeadingLarge onClick={onClick} as={as} className={className}>
						{children}
					</HeadingLarge>
				);
			case "medium":
				return (
					<HeadingMedium onClick={onClick} as={as} className={className}>
						{children}
					</HeadingMedium>
				);
			case "extra-small":
				return (
					<ExtraSmall onClick={onClick} as={as} className={className}>
						{children}
					</ExtraSmall>
				);
			case "small":
			default:
				return (
					<HeadingSmall
						onClick={onClick}
						as={as}
						className={className}
						narrow={narrow}
					>
						{children}
					</HeadingSmall>
				);
		}
	}

	return (
		<Body
			onClick={onClick}
			as={as}
			className={className}
			small={size === "small"}
		>
			{children}
		</Body>
	);
};

export default Typography;
