import Page from "components/common/ui/Page/Page";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import Typography from "components/common/ui/Typography/Typography";
import React from "react";
import { PageType } from "ts/enums";
import { Section } from "../BlogPost.Styled";
import {
	Container,
	ContainerMedium,
	FeaturedImageSkeleton,
	LineBreak,
	UtilityContainer,
} from "./BlogPostSkeleton.Styled";

const BlogPostSkeleton: React.FC = () => {
	return (
		<Page pageType={PageType.PAGE} title="Blog Post">
			<Section gap={64} gapMobile={32} className={"PageContainer"}>
				<FeaturedImageSkeleton />
				<ContainerMedium>
					<Section gap={32}>
						<Container padding={16} gap={24} gapMobile={20}>
							<UtilityContainer
								flexDirection={"column"}
								alignItems={"flex-start"}
								justifyContent={"flex-start"}
								gap={12}
							>
								<Typography variant="heading" size="small">
									<Skeleton width={90} />
								</Typography>
								<Typography variant="heading" size="medium">
									<Skeleton width={500} />
								</Typography>
								<Typography variant="body" size="medium">
									<Skeleton width={150} />
								</Typography>
							</UtilityContainer>
							<LineBreak />
							{/* <PostContent>{parse(blogContent.content, options)}</PostContent>
              <LineBreak />
              {
                (blogContent.categories.nodes[0].name.toLowerCase() !== "uncategorized") &&
                <>
                  <RelatedCategories>
                      <Typography variant="body" size="medium">{"Related categories:"}</Typography>
                      <RelatedCategories>
                        {
                          relatedCategories.map( relatedCategory => ( <Typography key={relatedCategory.toString()}>{relatedCategory}</Typography> ))
                        }
                      </RelatedCategories>
                  </RelatedCategories>
                  <LineBreak />
                </>
              }
              <ShareBlogPostViaSocialMedia url={window.location.href}/>
              <LineBreak />
              <UtilityContainer
                flexDirection={"row"}
                alignItems={"start"}
                justifyContent={"center"}
              >
                <Link to={"../news"} className={"link"}>
                  <Typography variant="body" size="medium">{"Back To News"}</Typography>
                </Link>
              </UtilityContainer> */}
						</Container>
						{/* <Container padding={16} gap={12}>
              {
                blogContent.categories.nodes[0].posts.nodes.filter((node) => node.slug !== post_slug).length !== 0 &&
                <UtilityContainer
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  alignItemsMobile={"center"}
                  gap={23.96}
                >
                  <Typography variant="heading" size="medium">{"Related Articles"}</Typography>
                  <SwipeContainerUponOverFlow>
                    <UtilityContainer
                      flexDirection={"row"}
                      gap={14}
                    >
                      {
                        blogContent.categories.nodes[0].name.trim().length !== 0 && <RelatedArticles articles={
                          (blogContent.categories.nodes[0].posts.nodes.filter((node) => node.slug !== post_slug)).slice(0, 4)
                        } />
                      }
                    </UtilityContainer>
                  </SwipeContainerUponOverFlow>
                </UtilityContainer>
              }
            </Container> */}
					</Section>
				</ContainerMedium>
				{/* <SignUpContainer padding={40}>
          <UtilityContainer
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={32}
          >
            <UtilityContainer
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={10}
              padding={24}
            >
              <Typography variant="heading" size="medium">{signUPData.header}</Typography>
              <Typography variant="body" size="medium" className={"sign-up-content"}>{signUPData.content}</Typography>
            </UtilityContainer>
            <UtilityContainer
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={16}
            >
              <Button variant="primary_01" className={"signup-btn"}>
                <Link to={"/"}>
                  Sign up
                </Link>
              </Button>
            </UtilityContainer>
          </UtilityContainer>
        </SignUpContainer> */}
			</Section>
		</Page>
	);
};

export default BlogPostSkeleton;
