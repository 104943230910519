import { addLocaleCode, removeTrailingSlash } from "app/utils/urlUtils";
import useOutsideClick from "hooks/useOutsideClick";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import IconClose from "../Icons/IconClose/IconClose";
import IconSocial from "../Icons/IconSocial/IconSocial";
import {
	StyledContainer,
	StyledContent,
	StyledHeader,
	StyledHeading,
	StyledLinksContainer,
	StyledOverlay,
	StyledText,
	StyledWrapper,
} from "./SharedModal.Styled";
import UrlCopyInput from "./UrlCopyInput/UrlCopyInput";

interface Props {
	isOpen: boolean;
	handleClose: () => void;

	url: string;
	labelTranslationKey: string;
	options?: {
		showSocialLinks?: boolean;
		showHelperText?: boolean;
	};
}

const ShareModal: React.FC<Props> = ({
	isOpen,
	handleClose,
	url,
	labelTranslationKey,
	options = {
		showHelperText: false,
		showSocialLinks: false,
	},
}) => {
	const { t } = useTranslation();
	const ref = useOutsideClick(handleClose);

	const origin = removeTrailingSlash(process.env.REACT_APP_PUBLIC_DOMAIN || "");

	url = addLocaleCode(url);

	if (!url.startsWith(origin)) {
		url = `${origin}${url}`;
	}

	const links = [
		{
			icon: <IconSocial type="whatsapp" />,
			href: `whatsapp://send?text=${url}`,
			action: "share/whatsapp/share",
		},
		{
			icon: <IconSocial type="twitter" />,
			href: `https://twitter.com/intent/tweet?text=${url}`,
		},
		{
			icon: <IconSocial type="facebook" />,
			href: `https://facebook.com/sharer/sharer.php?u=${url}`,
		},
		{
			icon: <IconSocial type="linkedin" />,
			href: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
		},
		{
			icon: <IconSocial type="email" />,
			href: `mailto:body=${url}`,
		},
		{
			icon: <IconSocial type="telephone" />,
			href: `viber://forward?text=${url}`,
		},
	];

	return (
		<StyledWrapper isOpen={isOpen}>
			<StyledOverlay>
				<StyledContainer ref={ref}>
					<StyledHeader>
						<StyledHeading>{t(labelTranslationKey)}</StyledHeading>
						<Button icon={<IconClose />} onClick={handleClose} />
					</StyledHeader>
					<StyledContent>
						<UrlCopyInput url={url} />
						{options.showHelperText && (
							<StyledText>{t("share_helper_text")}</StyledText>
						)}
						{options.showSocialLinks && (
							<StyledLinksContainer>
								{links.map((link, index) => (
									<a
										key={index}
										href={link.href}
										{...(link.action && { "data-action": link.action })}
										target="_blank"
									>
										<Button icon={link.icon} />
									</a>
								))}
							</StyledLinksContainer>
						)}
					</StyledContent>
				</StyledContainer>
			</StyledOverlay>
		</StyledWrapper>
	);
};

export default ShareModal;
