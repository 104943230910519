import styled from "styled-components";
import { remCalc } from "utilities/styles";
import { accountDarkGrey, dark, darkGrey } from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";
import { pageContentContainer } from "theme/StyledMixins";

export const StyledJobDescriptionWrapper = styled.div`
	* {
		font-family: ${Roboto};
	}

	.image-banner {
		margin-bottom: 0;
	}

	h2 {
		font-size: ${remCalc(24)};
		font-weight: 700;
		color: ${dark};
		text-transform: uppercase;
		margin-top: ${remCalc(48)};
		margin-bottom: 0;
		letter-spacing: 1.2px;
	}

	.custom-fields {
		h6 {
			margin-top: ${remCalc(16)};

			@media ${aboveMedium} {
				margin-top: ${remCalc(32)};
			}
		}
	}

	h6 {
		color: ${accountDarkGrey};
		font-size: ${remCalc(16)};

		@media ${aboveMedium} {
			margin-top: ${remCalc(32)};
		}
	}

	h3 {
		color: ${dark};
		font-size: ${remCalc(20)};
		text-transform: uppercase;
		font-weight: 700;
		margin: 0;
		letter-spacing: 1px;
	}

	.container {
		margin: ${remCalc(48)} auto;
		${pageContentContainer};

		@media ${aboveMedium} {
			margin: ${remCalc(28)} auto;
		}

		&--text {
			display: flex;
			flex-direction: column;
			margin-top: ${remCalc(6)};

			@media ${aboveMedium} {
				flex-direction: row;

				div:nth-of-type(2) {
					padding-left: ${remCalc(32)};
				}
			}
		}
	}

	.content {
		color: ${dark};

		ul {
			margin-bottom: ${remCalc(48)};
		}
	}

	.content p:first-child {
		margin-top: ${remCalc(32)};
		margin-bottom: ${remCalc(48)};
	}
`;

export const PreFooterWrapper = styled.div`
	margin-top: 3rem;
	@media ${aboveMedium} {
		margin-top: 4rem;
	}
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.link {
		margin: 2rem 0 2rem;

		p {
			font-weight: bold;
			text-transform: uppercase;
		}
		p:hover {
			color: ${darkGrey};
			text-decoration: underline;
			text-decoration-color: ${dark};
		}
	}
	.shareContainer {
		height: ${remCalc(72)};
		width: 83%;
		min-width: ${remCalc(302)};
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		@media ${aboveMedium} {
			height: ${remCalc(88)};
			width: ${remCalc(816)};
		}
	}
`;

export const SkeletonWrapper = styled.div`
	.title {
		height: ${remCalc(58)};

		@media ${aboveMedium} {
			height: ${remCalc(116)};
			margin-top: ${remCalc(20)};
		}
	}

	.custom-fields {
		height: ${remCalc(104)};

		@media ${aboveMedium} {
			height: ${remCalc(50)};
		}
	}
`;
