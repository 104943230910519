import { PureComponent, ReactNode } from 'react';

import { GoogleAutocompleteOptionTypes, GoogleMapsSdk } from './GoogleAutocompleteTypes';
import { noop } from 'lodash';
import Autocomplete from './Autocomplete';
import { GoogleAutocompleteWrapper } from './GoogleAutocomplete.Styled';
import GoogleApiService from 'app/services/Google/GoogleApiService';

interface GoogleAutocompleteProps {
	googleApi: {
		map: any;
		api: GoogleMapsSdk
	}
    initialValue?: string;
    componentRestrictions?: google.maps.places.ComponentRestrictions;
    language?: string | null;
    fields?: string[];
    apiKey: string;
    nextElement?: HTMLElement;
    inputProps?: any;
    isAutocompleteEnabled?: boolean;
    types?: GoogleAutocompleteOptionTypes[];
    onSelect?(place: google.maps.places.PlaceResult): void;
    onToggleOpen?(state: { inputValue: string; isOpen: boolean }): void;
    onChange?(value: string, isOpen: boolean): void;
}

interface GoogleAutocompleteState {
    items: {
        label: string;
        highlightedSlices?: Array<{
            offset: number;
            length: number;
        }>;
        value?: string;
        id: string;
    }[];
    autoComplete: string;
}

class GoogleAutocomplete extends PureComponent<GoogleAutocompleteProps, GoogleAutocompleteState> {
    googleAutocompleteService: GoogleMapsSdk | undefined;

    constructor(props: GoogleAutocompleteProps) {
        super(props);

        this.googleAutocompleteService = props.googleApi.api

        this.state = {
            items: [],
            autoComplete: 'off',
        };
    }

    render(): ReactNode {
        const { initialValue, inputProps} = this.props;

        const { autoComplete, items } = this.state;

        return <GoogleAutocompleteWrapper>
            <Autocomplete
                initialHighlightedIndex={0}
                initialValue={initialValue}
                inputProps={{
                    ...inputProps,
                    autoComplete,
                }}
                items={items}
                listTestId="address-autocomplete-suggestions"
                onChange={this.onChange}
                onSelect={this.onSelect}
            />
        </GoogleAutocompleteWrapper>
    }

    private onSelect: (item: {
        label: string;
        highlightedSlices?: Array<{
            offset: number;
            length: number;
        }>;
        value?: string;
        id: string;
    }) => Promise<void> | void = (item) => {
        const { apiKey, fields, googleApi: { map } = {}, onSelect = noop, nextElement } = this.props;
        const placeDetailsRequest = {
            placeId: item.id,
            fields: fields || ['address_components', 'formatted_address', 'geometry', 'name'],
        };

        if (typeof this.googleAutocompleteService === 'undefined') {
            return new GoogleApiService(apiKey).getPlacesServices().then((service) => {
                service.getDetails(
                    placeDetailsRequest,
                    (result) => {
                        if (nextElement) {
                            nextElement.focus();
                        }

                        onSelect(result);
                    },
                );
            });
        }

        new this.googleAutocompleteService.places.PlacesService(map).getDetails(
            placeDetailsRequest,
            (result) => {
                if (nextElement) {
                    nextElement.focus();
                }

                onSelect(result);
            },
        );
    };

    private onChange: (input: string) => void = (input) => {
        const { isAutocompleteEnabled } = this.props;

        if (!isAutocompleteEnabled) {
            return this.resetAutocomplete();
        }

        this.setAutocomplete(input);
        this.setItems(input);
    };

    private setItems(input: string): Promise<void> | void {
        if (!input) {
            this.setState({ items: [] });

            return;
        }

        const { apiKey, componentRestrictions, language, types } = this.props;
        const autocompletionRequest = {
            input,
            types: types || ['geocode'],
            componentRestrictions,
            language,
        }

        if (typeof this.googleAutocompleteService === 'undefined') {
            return new GoogleApiService(apiKey).getAutocompleteService().then((service) => {
                service.getPlacePredictions(
                    autocompletionRequest,
                    (results) => this.setState({ items: this.toAutocompleteItems(results) }),
                );
            });
        }

        new this.googleAutocompleteService.places.AutocompleteService().getPlacePredictions(
            autocompletionRequest,
            (results) => this.setState({ items: this.toAutocompleteItems(results) }),
        );
    }

    private resetAutocomplete(): void {
        this.setState({
            items: [],
            autoComplete: 'off',
        });
    }

    private setAutocomplete(input: string): void {
        this.setState({
            ...this.state,
            autoComplete: input && input.length ? 'nope' : 'off',
        });
    }

    private toAutocompleteItems(
        results?: google.maps.places.AutocompletePrediction[] | null,
    ): {
        label: string;
        highlightedSlices?: Array<{
            offset: number;
            length: number;
        }>;
        value?: string;
        id: string;
    }[] {
        return (results || []).map((result) => ({
            label: result.description,
            value: result.structured_formatting.main_text,
            highlightedSlices: result.matched_substrings,
            id: result.place_id,
        }));
    }
}

export default GoogleAutocomplete;
