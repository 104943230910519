import styled from "styled-components";
import { remCalc } from "utilities/styles";
import {
	dark,
	darkGrey,
	grey,
	lightGrey,
	white,
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";
import { menuZIndex } from "utilities/variables/zIndex";
import Link from "../Link/Link";
import Suggestion from "./Suggestion";
import Skeleton from "components/common/ui/Skeleton/Skeleton";

interface SearchModalContainerInterface {
	isOpen: boolean;
	hasResults: boolean;
}

export const SearchModalContainer = styled.div<SearchModalContainerInterface>`
	position: absolute;
	top: ${remCalc(88)};
	left: 0;
	right: 0;
	z-index: ${menuZIndex};

	max-height: calc(100vh - ${remCalc(88 + 16)});
	height: ${({ hasResults }) => hasResults && "100%"};

	display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
	flex-direction: column;

	margin: 0 ${remCalc(16)} ${remCalc(16)};
	padding: ${remCalc(24)} ${remCalc(16)} 0;

	background-color: ${white};
	box-shadow: none;

	@media ${aboveMedium} {
		left: unset;
		top: ${remCalc(120)};

		max-height: ${remCalc(575)};
		width: ${remCalc(604)};

		margin: 0 ${remCalc(20)};

		box-shadow: 0px 3px 6px #21252940;

		overflow-y: unset;
	}

	.search__header {
		display: flex;
		align-items: center;
		gap: ${remCalc(8)};

		margin-bottom: ${remCalc(24)};

		.search__input {
			position: relative;

			flex: 1;

			input {
				padding: ${remCalc(4)} 0;
				padding-right: ${remCalc(60)};

				border: none;
				border-bottom: 1px solid ${grey};

				font: bold ${remCalc(20)} / ${remCalc(24)} ${Roboto};
				letter-spacing: ${remCalc(1)};
				color: ${dark};
				text-transform: uppercase;
			}

			button {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				font: bold ${remCalc(16)} / ${remCalc(24)} ${Roboto};
				letter-spacing: ${remCalc(0.8)};

				&.hidden {
					display: none;
				}
			}
		}
	}

	.search__body {
		max-height: calc(100% - ${remCalc(40 + 24)});
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: ${remCalc(48)};
		overflow: hidden;

		p {
			margin: 0;

			color: ${dark};

			&.heading {
				font: bold ${remCalc(14)} / ${remCalc(24)} ${Roboto};
			}
		}

		@media ${aboveMedium} {
			max-height: calc(100% - ${remCalc(40 + 24)});

			flex-direction: row;
			gap: 0;
		}

		.search__suggestions {
			position: relative;

			display: flex;
			flex-direction: column;
			gap: ${remCalc(8)};

			@media ${aboveMedium} {
				width: ${remCalc(165)};
				margin-right: ${remCalc(32)};
			}

			&::after {
				content: "";
				position: absolute;

				right: 0;
				bottom: ${remCalc(-24)};
				left: 0;

				border-bottom: 1px solid ${lightGrey};

				@media ${aboveMedium} {
					top: 0;
					right: 0;
					bottom: unset;
					left: unset;

					max-height: ${remCalc(441)};
					height: 100%;

					border-right: 1px solid ${lightGrey};
				}
			}
		}
		.empty-search {
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 26rem;
			margin-bottom: 3rem;
			text-align: center;
			justify-content: center;

			h4 {
				color: #212529;
				font-weight: 700;
				text-transform: uppercase;
				padding: 0;
				margin: 0;
				font-size: 1.25rem;
			}

			p {
				padding: 0;
				margin: 0;
				font-family: Proxima Nova;
				color: #212529;
				font-size: 0.8rem;
			}
		}

		.search__products {
			max-height: calc(100% - ${remCalc(36)});
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: ${remCalc(12)};

			padding-left: 0;

			@media ${aboveMedium} {
				max-height: 100%;
			}

			.products__header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.products {
				max-height: calc(100% - ${remCalc(24 + 12)});
				flex: 1;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: ${remCalc(8)};
				grid-row-gap: ${remCalc(32)};
				overflow-y: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}

				padding-bottom: 10rem;

				@media ${aboveMedium} {
					max-height: calc(100% - ${remCalc(24 + 12)});
					padding-bottom: 0;
					grid-row-gap: ${remCalc(24)};
				}

				.ProductCard {
					width: 100%;
				}
			}
		}
	}
`;

export const StyledSkeletonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${remCalc(16)};
`;

export const StyledSkeleton = styled(Skeleton)`
	width: 80px;
	height: 100px;
`;

export const StyledSuggestion = styled(Suggestion)`
	cursor: pointer;
	font: normal ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	color: ${dark};
	text-transform: uppercase;
	letter-spacing: ${remCalc(0.8)};

	b {
		font: bold ${remCalc(16)} / ${remCalc(24)} ${Roboto};
	}

	&:first-of-type {
		margin-top: ${remCalc(4)};
	}
`;

export const StyledLink = styled(Link)`
	color: ${dark};
	font: normal ${remCalc(14)} / ${remCalc(24)} ${Roboto};
	&:hover,
	&:active {
		color: ${darkGrey};
		text-decoration: underline;
	}
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
	display: ${({ isOpen }) => !isOpen && "none"};
	position: absolute;
	top: ${remCalc(88)};
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(33, 37, 41, 0.9);

	@media ${aboveMedium} {
		top: ${remCalc(120)};
	}
`;
