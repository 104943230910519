import wordPressGetRedirectLinks from "../../gql/quries/wordPressRedirectLinks";
import  { wordPressGQL } from "../../../app/services/wordPressGqlService";

export const wordPressRedirectLinksApi = wordPressGQL.injectEndpoints({
    endpoints: (builder) => ({
        getRedirectLinks: builder.query<any, void>({
            query: () => ({
                url: "",
                method: "POST",
                body: {
                    ...wordPressGetRedirectLinks()
                }
            }),
            transformResponse: (res: any) => {
                const { redirection } = res.data
                return res.data ? { ...redirection } : undefined;
            }
        })
    })
})

export const { useGetRedirectLinksQuery } = wordPressRedirectLinksApi;